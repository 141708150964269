import { createSelector } from "reselect"
import { REQUEST_USERS, REQUEST_POST_USER, REQUEST_DELETE_USER, REQUEST_CHANGE_PASSWORD_AS_ADMIN } from "store/users/actionTypes";

export const selectUsers = state => state.Users;
export const selectRegions = state => state.Users.regions;
export const selectAnswersTypes = state => state.Users.answerTypes;
export const selectLoadingUsers = state => { return state.Requesting[REQUEST_USERS] };
export const selectLoadingPostUser = state => { return state.Requesting[REQUEST_POST_USER] };
export const selectLoadingDeleteUser = state => { return state.Requesting[REQUEST_DELETE_USER] };
export const selectLoadingChangeUserPassword = state => { return state.Requesting[REQUEST_CHANGE_PASSWORD_AS_ADMIN] };

export const selectUsersState = state => state.Users;
export const selectUsersPostResult = createSelector(selectUsersState, (users) => users.postResult);
export const selectChangePasswordPostResult = createSelector(selectUsersState, (users) => users.changePasswordResult);
export const selectUsersDeleteResult = createSelector(selectUsersState, (users) => users.deleteResult)

const selectPageState = createSelector(
  selectUsers, selectLoadingUsers, selectLoadingPostUser,
  (users, isLoadingUsers, isLoadingPostUser) => ({
    users, isLoadingUsers, isLoadingPostUser
  })
)

export default selectPageState;