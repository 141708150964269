import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../../common/ChartsDynamicColor";

const Doughnut = ({ data, dataColors}) => {
  const doughnutEChartColors = getChartColorsArray(dataColors);

  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} ({d}%)",
      position: "right",
      labelLine: {
        fontFamily: ['Poppins', 'sans-serif'],
        fontSize: "13"
      },
    },
    legend: {
      orient: "vertical",
      x: "left",
      y: "bottom",
      data: data.Labels,
      labelLine: {
        fontFamily: ['Poppins', 'sans-serif'],
        color: ["#8791af"],
        fontSize: "13"

      },
    },
    color: doughnutEChartColors,
    series: [
      {
        name: data.ChartHeader,
        type: "pie",
        radius: ["50%", "70%"],
        avoidLabelOverlap: true,
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
        },
        labelLine: {
          show: false,
          position: "center",
          fontSize: "20",
          fontWeight: "normal",
        },
        data: data.Items
      },
    ],
  }

  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "450px" }} option={options} />
    </React.Fragment>
  );
};
export default Doughnut;
