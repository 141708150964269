import { useFormik } from "formik"
import React, { useState } from "react"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"
import { Col, Form, FormFeedback, Input, InputGroup, Label, Row, Button } from "reactstrap"
import { selectLoadingChangeUserPassword, selectLoadingPostUser } from "selectors/UsersSelectors";
import { requestChangePasswordAsAdmin } from "store/actions";
import * as Yup from "yup"

export default function PasswordForm({ userId = 0, handleChangePassword }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const isLoadingChangePassword = useSelector(selectLoadingChangeUserPassword);
  const dispatch = useDispatch();


  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      userId: userId,
      newPassword: "",
      reNewPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string().required("Şifrə doldurulmayıb").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, "Şifrə az 8 karakterə, ən az bir böyük və bir kiçik hərfə , ən az bir rəqəmə sahib olmalıdır və yalnız ingiliz əlifbası istifadə olunmalıdır"),
      reNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Təsdiq şifrəsi doğru deyil').required("Təsdiq şifrəsi doldurulmayıb"),
    }),
    onSubmit: (values) => {
      dispatch(handleChangePassword(values))
    }
  })

  return (
    <Form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault()
        form.handleSubmit()
        return false
      }}
    >
      <Row>
        <Col xs={12} className="mb-3">
          <Label className="form-label">Yeni şifrə</Label>
          <InputGroup>
            <Input
              name="newPassword"
              type={`${showPassword ? 'text' : 'password'}`}
              placeholder="Yeni şifrəni daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.newPassword || ""}
              autoComplete="off"
              invalid={
                form.touched.newPassword && form.errors.newPassword
                  ? true
                  : false
              }
            />
            <a className="p-2" onClick={() => setShowPassword(!showPassword)}>
              <i className={`${showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'} font-size-18`}></i>
            </a>
            {form.touched.newPassword && form.errors.newPassword ? (
              <FormFeedback type="invalid">
                {form.errors.newPassword}
              </FormFeedback>
            ) : null}
          </InputGroup>
        </Col>

        <Col xs={12} className="mb-3">
          <Label className="form-label">Yeni şifrənin təsdiqi</Label>
          <InputGroup>
            <Input
              name="reNewPassword"
              type={`${showNewPassword ? 'text' : 'password'}`}
              placeholder="Yeni şifrəni təsdiq edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.reNewPassword || ""}
              autoComplete="off"
              invalid={
                form.touched.reNewPassword && form.errors.reNewPassword
                  ? true
                  : false
              }
            />
            <a className="p-2" onClick={() => setShowNewPassword(!showNewPassword)}>
              <i className={`${showNewPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'} font-size-18`}></i>
            </a>
            {form.touched.reNewPassword && form.errors.reNewPassword ? (
              <FormFeedback type="invalid">
                {form.errors.reNewPassword}
              </FormFeedback>
            ) : null}
          </InputGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="text-end">
            <Button
              type="submit"
              className="btn btn-success save-user"
              disabled={isLoadingChangePassword}
            >
              {isLoadingChangePassword && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>} {" "}
              Yaddaşa yaz
            </Button>
          </div>
        </Col>
      </Row>
    </Form >
  )
}