import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";

import {
  selectAttachedBeneficiaries,
  selectLoadingAttachedBeneficiaries,
} from "selectors/FollowersSelectors";

import { selectBeneficiarExportResult } from "selectors/BeneficiarisSelectors";
import {
  requestAttachedBeneficiaries,
  requestExportAttachedBeneficiaries
} from "store/followers/actions";

import { requestBeneficiarImages } from "store/beneficiar-images/actions";

import { resultColumns } from "./resultsTable/columns";
import EnhancedTable from "components/Common/EnhancedTable";

import ObservationQuestions from "./observationQuestions";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Filter from "./Filter";
import BeneficiarGalleryModal from "pages/Dashboard/BeneficiarGallery";


const Results = props => {
  document.title = "Nəticələr | Self Employment Project"
  const [GalleryMode, setGalleryMode] = useState("");
  const [selectedBeneficiar, setSelectedBeneficiar] = useState(null);
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [filters, setFilters] = useState({});
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const isLoading = useSelector(selectLoadingAttachedBeneficiaries);
  const selectedAttachedBeneficiaries = useSelector(selectAttachedBeneficiaries);
  const selectedBeneficiarImages = useSelector(state => state.BeneficiarImages.list);
  const beneficiarExportResult = useSelector(selectBeneficiarExportResult);

  function handleSelectBeneficiar(Data) {
    setModal(true)
    setSelectedData(Data)
  };

  function handleToggleUserFormModal() {
    setModal(!modal)
  };

  function handleViewGallery() {
    setGalleryMode(prevMode => prevMode === "view" ? "" : "view");
    setSelectedBeneficiar(null)
  };

  function handleExport() {
    if (!isExportLoading) {
      dispatch(requestExportAttachedBeneficiaries({
        ...filters,
        pageIndex: 1,
        pageSize: 10000,
      }))
    }
  };

  useEffect(() => {
    dispatch(
      requestAttachedBeneficiaries({
        ...filters,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      })
    )
  }, [dispatch, filters, pagination]);

  useEffect(() => {
    if (selectedBeneficiar) {
      dispatch(requestBeneficiarImages(selectedBeneficiar))
      setGalleryMode("view")
    }
  }, [selectedBeneficiar, dispatch]);

  useEffect(() => {
    if (beneficiarExportResult && beneficiarExportResult?.success) {
      setTimeout(() => {
        setIsExportLoading(false);
      }, 350);
    }
  }, [beneficiarExportResult]);


  const tableColumns = useMemo(
    () => [
      {
        id: "gallery",
        header: "Şəkillər",
        accessorKey: "images",
        cell: ({ row }) => {
          if (row.original.isPhotoValid) {
            return (
              <i
                onClick={() => setSelectedBeneficiar(row.original.beneficiarId)}
                className="mdi mdi-folder-image text-primary"
                style={{ fontSize: "24px", cursor: "pointer", padding: "15px" }}
              ></i>
            )
          } else {
            return (
              <i
                className="mdi mdi-folder text-secondary"
                style={{ fontSize: "24px", padding: "15px" }}
              ></i>
            )
          }
        },
      },
      ...resultColumns,
    ],
    [selectedAttachedBeneficiaries]
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Results")}
            breadcrumbItem={props.t("Nəticələr")}
          />
          <Row>
            <Col>
              <Filter setFilters={setFilters} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="my-2">
                    <a
                      className={`btn me-3 ${isExportLoading ? "btn-secondary" : "btn-success"}`}
                      onClick={handleExport}
                    >{isExportLoading ? (
                      <span
                        className="spinner-border spinner-border-sm me-3  p-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : <i className="mdi mdi-download me-2 font-size-18"></i>}
                      {" "}
                      Export
                    </a>
                  </div>
                  <EnhancedTable
                    onPaginationChange={setPagination}
                    pagination={pagination}
                    manualPagination
                    DataCount={selectedAttachedBeneficiaries.count}
                    data={selectedAttachedBeneficiaries.AttachedBeneficiaries}
                    columns={tableColumns}
                    isLoading={isLoading}
                    SelectBeneficiarObservationHandler={handleSelectBeneficiar}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal size="xl" isOpen={modal} toggle={handleToggleUserFormModal}>
        <ModalHeader toggle={handleToggleUserFormModal} tag="h4">
          <p>{selectedData?.FullName}</p>
          {selectedData?.ObservationId}{" "}
          {selectedData?.ObservationId < 3 ? "ci" : "cü"} Müşahidə səfərlərinin detalları
        </ModalHeader>
        <ModalBody>
          {selectedData && <ObservationQuestions data={selectedData} />}
        </ModalBody>
      </Modal>

      <BeneficiarGalleryModal
        toggle={handleViewGallery}
        isOpen={GalleryMode === "view"}
        images={selectedBeneficiarImages}
        selectedBeneficiar={selectedBeneficiar}
        isGeneral={true}
      />
    </React.Fragment>
  )
}

export default withTranslation()(Results)
