
import {
  REQUEST_BENEFICIAR_IMAGES_IMAGES,
  REQUEST_BENEFICIAR_IMAGES_IMAGES_FINISHED,
  REQUEST_DELETE_BENEFICIAR_IMAGE,
  REQUEST_DELETE_BENEFICIAR_IMAGE_FINISHED,
  REQUEST_POST_BENEFICIAR_IMAGES,
  REQUEST_POST_BENEFICIAR_IMAGES_FINISHED
} from "./actionTypes"



export const requestBeneficiarImages = (payload) => {
  return {
    type: REQUEST_BENEFICIAR_IMAGES_IMAGES,
    payload
  }
}

export const requestBeneficiarImagesFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_BENEFICIAR_IMAGES_IMAGES_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestDeleteBeneficiarImage = (payload) => {
  return {
    type: REQUEST_DELETE_BENEFICIAR_IMAGE,
    payload
  }
}

export const requestDeleteBeneficiarImageFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_DELETE_BENEFICIAR_IMAGE_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestPostBeneficiarImages = (payload) => {
  return {
    type: REQUEST_POST_BENEFICIAR_IMAGES,
    payload
  }
}

export const requestPostBeneficiarImagesFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_BENEFICIAR_IMAGES_FINISHED,
    payload,
    error,
    meta
  }
}
