import {
  SAVE_BENEFICIAR_FILTER,
  REQUEST_BENEFICIARS_FINISHED,
  REQUEST_FOLLOWERS_FINISHED,
  REQUEST_POST_ATTACH_FOLLOWERS_FINISHED,
  REQUST_BUSINESS_OPTIONS_FINISHED,
  REQUEST_POST_BENEFICIAR_FINISHED,
  REQUEST_POST_EXPORT_BENEFICIARIES_FINISHED,
  REQUEST_POST_IMPORT_BENEFICIARIES_FINISHED,
  REQUEST_DELETE_BENEFICIARS_FINISHED,
  REQUEST_GET_EXPORT_BENEFICIARIES_DATA_FINISHED,
  REQUEST_PUT_CHANGE_OBSERVATION_DATE_FINISHED,
  REQUEST_DELETE_OBSERVATION_FINISHED,
  REQUEST_EXPORT_MISSING_EQUIPMENTS_FINSHED
} from "./actionTypes"

const initialState = {
  list: [],
  filters: { companyTraining: "ALL" },
  followers: [],
  businesses: []
}

const Beneficiars = (state = initialState, { type, payload, error, meta }) => {
  if (error) return state;

  switch (type) {
    case REQUEST_BENEFICIARS_FINISHED:
      return { ...state, list: payload }
    case SAVE_BENEFICIAR_FILTER:
      if (payload !== initialState.filters) {
        return { ...state, filters: payload }
      }

    case REQUEST_POST_BENEFICIAR_FINISHED:
      return { ...state, postResult: payload }
    case REQUEST_POST_EXPORT_BENEFICIARIES_FINISHED:
      return { ...state, exportResult: payload }
    case REQUEST_POST_IMPORT_BENEFICIARIES_FINISHED:
      return { ...state, postImportResult: payload }
    case REQUEST_FOLLOWERS_FINISHED:
      return { ...state, followers: payload }
    case REQUEST_POST_ATTACH_FOLLOWERS_FINISHED:
      return { ...state, postResult: payload }
    case REQUST_BUSINESS_OPTIONS_FINISHED:
      return { ...state, businesses: payload }
    case REQUEST_DELETE_BENEFICIARS_FINISHED:
      return { ...state, deleteResult: payload }
    case REQUEST_GET_EXPORT_BENEFICIARIES_DATA_FINISHED:
      return { ...state, exportResult: payload }
    case REQUEST_PUT_CHANGE_OBSERVATION_DATE_FINISHED:
      return { ...state, putObservationNewDateResult: payload }
    case REQUEST_DELETE_OBSERVATION_FINISHED:
      return { ...state, deleteObservationResult: payload }
    case REQUEST_EXPORT_MISSING_EQUIPMENTS_FINSHED:
      return { ...state, exportMissingEquipmentResult: payload }
    default:
      return state
  }
}

export default Beneficiars
