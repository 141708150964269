import React, { useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { withTranslation } from "react-i18next"
import * as XLSX from "xlsx"

import {
  selectBeneficiarisImportResult,
  selectBeneficiarExportResult,
  selectBeneficiarFilter
} from "selectors/BeneficiarisSelectors"
import { selectRegions } from "selectors/UsersSelectors"

import { requestBeneficiarImages } from "store/beneficiar-images/actions";

import {
  requestExportBeneficiariesData,
  requestImportBeneficiaries,
  requestRegions,
  saveBeneficiarsFilter
} from "store/actions";


import Breadcrumbs from "../../components/Common/Breadcrumb";
import BeneficiarFilter from "pages/Dashboard/BeneficiarFilter";
import BeneficiarTable from "./BeneficiarTable/BeneficiarTable";
import BeneficiarGalleryModal from "pages/Dashboard/BeneficiarGallery";


const Beneficiaris = props => {
  document.title = "Benefisiarlar | Self Employment Project";
  const [GalleryMode, setGalleryMode] = useState("");
  const [importModal, setImportModal] = useState(false);
  const [importedFile, setImportedFile] = useState(null);
  const [excelError, setExcelError] = useState("");
  const [companyTraining, setCompanyTraining] = useState('ALL');
  const [importLoading, setImportLoading] = useState(false);
  const [isExportLoading, setIsExportLoading] = useState(false);

  const selectedRegions = useSelector(selectRegions);
  const beneficiarExportResult = useSelector(selectBeneficiarExportResult);
  const postImportBeneficiaries = useSelector(selectBeneficiarisImportResult);
  const selectedBeneficiarImages = useSelector(state => state.BeneficiarImages.list);
  const filter = useSelector(selectBeneficiarFilter);
  const dispatch = useDispatch();

  //handlers
  const beneficiarActionHandler = ({ beneficiar = {}, mode = "" }) => {
    if (mode === "import") setImportModal(true);
    else if (mode === "export") {
      if (!isExportLoading) {
        dispatch(requestExportBeneficiariesData(filter, { type: "beneficiar" }))
        setIsExportLoading(true);
      }
    }
    else if (mode === "viewGallery") {
      dispatch(requestBeneficiarImages(beneficiar.beneficiarId))
      setGalleryMode("view");
    }
  };

  const importFileHandler = () => {
    setImportLoading(true)
    if (importedFile && Object.keys(importedFile).length > 0) {
      let FileStatus = true;
      let reader = new FileReader();
      reader.readAsArrayBuffer(importedFile[0]);
      reader.onload = (e) => {
        const excelData = e.target.result;
        const workbook = XLSX.read(excelData, { type: 'buffer' })
        const workSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[workSheetName];
        const data = XLSX.utils.sheet_to_json(worksheet)

        data.map((item) => {
          if (Object.keys(item).length < 16) {
            setExcelError("Cədvəldə boş buraxılan xanalar mövcuddur.")
            FileStatus = false;
          } else if (Object.keys(item).length > 16) {
            setExcelError("Cədvəldə nəzərdə tutulandan artıq sütün mövcuddur.")
            FileStatus = false;
          }
        });

        if (FileStatus) {
          const formData = new FormData();
          formData.append("File", importedFile[0]);
          dispatch(requestImportBeneficiaries(formData));
        }
      }
    } else setExcelError("Fayl seçilməyib")
    setImportLoading(false)
  };

  const importInputHandler = (e) => {
    setExcelError("");
    setImportedFile(null);
    if (e.target.files) {
      if (e.target.files[0]?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || e.target.files[0]?.type === "application/vnd.ms-excel") {
        setImportedFile(e.target.files)
        setExcelError(null);
      } else setExcelError("Yalnız Excell file import edilə bilər.");
    } else setExcelError("Fayl seçilməyib");
  };

  const importModalHandler = () => {
    setImportModal(!importModal);
    setImportedFile(null)
    setExcelError(null)
  };

  const onFilterSubmit = (filter) => {
    dispatch(saveBeneficiarsFilter({ ...filter, companyTraining }));
  };

  //useEffects
  useEffect(() => {
    dispatch(saveBeneficiarsFilter({ companyTraining }));
  }, [companyTraining]);

  useEffect(() => {
    if (postImportBeneficiaries && postImportBeneficiaries?.success) {
      setImportModal(false);
      setExcelError(null);
      setImportedFile(null);
    }
  }, [postImportBeneficiaries]);

  useEffect(() => {
    if (beneficiarExportResult && beneficiarExportResult?.success) {
      setTimeout(() => {
        setIsExportLoading(false);
      }, 350);
    }
  }, [beneficiarExportResult]);

  useEffect(() => {
    if (!Object.keys(selectedRegions).length > 0) dispatch(requestRegions());
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("Benefisiarlar")}
            breadcrumbItem={props.t("BENEFİSİARLAR")}
          />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                    <b className="m-1 w-100">Axtarış bazasını seç: </b>
                    <div className="m-1">
                      <Button
                        name="All"
                        className="btn"
                        color="primary"
                        outline={companyTraining !== "ALL" ? true : false}
                        onClick={(e) => {
                          e.preventDefault()
                          setCompanyTraining(companyTraining !== "ALL" ? "ALL" : "")
                        }}
                        active={companyTraining === "ALL" ? true : false}
                      >
                        HAMISI
                      </Button>
                    </div>
                    <div className="m-1">
                      <Button
                        name="LİQ"
                        className="btn"
                        color="primary"
                        outline={companyTraining !== "LİQ" ? true : false}
                        onClick={(e) => {
                          e.preventDefault()
                          setCompanyTraining(companyTraining !== "LİQ" ? "LİQ" : "")
                        }}
                        active={companyTraining === "LİQ" ? true : false}
                      >
                        LİQ təlimləri üzrə
                      </Button>
                    </div>
                    <div className="m-1">
                      <Button
                        name="Konsultant"
                        className="btn"
                        color="primary"
                        outline={companyTraining !== "Konsultant" ? true : false}
                        onClick={(e) => {
                          e.preventDefault()
                          setCompanyTraining(companyTraining !== "Konsultant" ? "Konsultant" : '',)
                        }}
                        active={companyTraining === "Konsultant" ? true : false}
                      >
                        KONSORSİUM təlimləri üzrə
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <BeneficiarFilter isMinimal={true} onFilterSubmit={onFilterSubmit} />
            </Col>
          </Row>

          <Row>
            <Col>
              <BeneficiarTable beneficiarActionHandler={beneficiarActionHandler} isExportLoading={isExportLoading} />
            </Col>
          </Row>
        </Container>

        <BeneficiarGalleryModal
          toggle={() => setGalleryMode(GalleryMode === "view" ? "" : "view")}
          isOpen={GalleryMode === "view"}
          images={selectedBeneficiarImages}
          isGeneral={false}
        />



        <Modal
          style={{ marginTop: '30vh' }}
          size="lg"
          isOpen={importModal}
          toggle={importModalHandler}
        >
          <ModalHeader toggle={importModalHandler} tag="h4">
            Import Excel
          </ModalHeader>
          <ModalBody>
            <Row className="jusify-conent-center">
              <Col className="col-12 p-3">
                <Label>Benefisiarların siyahısı</Label>
                <InputGroup>
                  <Input
                    type="file"
                    name="importExcel"
                    onChange={importInputHandler}
                  />
                  <div className="text-center p-2">
                    <b>
                      {importedFile && Object.keys(importedFile).length > 0 ? `${Math.ceil(Number(importedFile[0].size) / 1000)} kb` : '0 kb'}
                    </b>
                  </div>
                </InputGroup>
                <p className="text-danger m-3">{excelError ? excelError : ''}</p>

                <Col className="d-flex  justify-content-end">
                  <Button
                    className="btn btn-success my-3"
                    onClick={importFileHandler}
                    disabled={Boolean(excelError) || importLoading}
                  >
                    {importLoading && (
                      <span
                        className="spinner-border spinner-border-sm me-3"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}{" "}
                    Import
                  </Button>
                </Col>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Beneficiaris)
