import EnhancedTable from "components/Common/EnhancedTable"
import React, { useState, useEffect, useMemo, useRef } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux";

import { beneficiarTableColumns } from "./beneficiar-columns";
import defaultBeneficiarColumn from "./defaultBeneficiarColumn";

import {
  requestBeneficiars,
  requestDeleteObservation,
  requestExportBeneficiariesAnserData,
  requestExportBeneficiariesData,
  requestPostAttachFollowers,
  requestPutObservationDate,
  saveBeneficiarsFilter
} from "store/beneficiars/actions";

import {
  selectBeneficiarExportResult,
  selectBeneficiarFilter,
  selectBeneficiarNewDateResult,
  selectBeneficiaris,
  selectFollowersPostResult,
  selectLoadingBeneficiaris,
  selectLoadingDeleteObservation,
  selectLoadingPostAttachFollower,
  selectLoadingPostObservationDate,
  selectObservationDeleteResult,
} from "selectors/BeneficiarisSelectors";

import ObservationQuestions from "pages/Results/observationQuestions";
import FollowerSelect from "./beneficiar-cells/FollowerSelect";
import formatDate from "helpers/format-date";
import DeleteModal from "components/Common/DeleteModal";

export default function BeneficiarTable({ beneficiarSetter, followers }) {
  const dispatch = useDispatch();

  //state
  const [changedFollowers, setChangedFollowers] = useState([]);
  const [collectiveAttachedFollowers, setCollectiveAttachedFollowers] = useState([]);
  const [collectiveAttachmentObservationId, setcollectiveAttachmentObservationId,] = useState(0);
  const [attachmentError, setAttachmentError] = useState("");
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [observationModal, setObservationModal] = useState(false);
  const [selectedObservtaion, setSelectedObservation] = useState(null)
  const [selectedObservationNewDate, setSelectedObservationNewDate] = useState(null);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isExportAnswersLoading, setIsExportAnswersLoading] = useState(false);
  const [changeObservationDateModal, setChangeObservationDateModal] = useState(false);
  const [deleteObservation, setDeleteObservation] = useState(false);

  //columns
  const columns = beneficiarTableColumns;
  const defaultColumn = defaultBeneficiarColumn;

  //selectors
  const postAttachFollowerResult = useSelector(selectFollowersPostResult);
  const data = useSelector(selectBeneficiaris);
  const filter = useSelector(selectBeneficiarFilter);
  const isLoading = useSelector(selectLoadingBeneficiaris);
  const isLoadingAttachFollower = useSelector(selectLoadingPostAttachFollower);
  const beneficiarExportResult = useSelector(selectBeneficiarExportResult);
  const user = useSelector(state => state.Auth);
  const newDateResult = useSelector(selectBeneficiarNewDateResult);
  const postDateLoading = useSelector(selectLoadingPostObservationDate);
  const deleteObservationResult = useSelector(selectObservationDeleteResult);
  const deleteObservationLoading = useSelector(selectLoadingDeleteObservation);

  const changedFollowerRef = useRef();


  //handlers
  const followerChangingHandler = (data) => {
    if (Object.keys(changedFollowerRef.current).length > 0) {
      for (let i = 0; i < changedFollowerRef.current.length; i++) {
        if (changedFollowerRef.current[i].beneficiarId === data.beneficiarId) {
          let changedRow = changedFollowerRef.current[i]

          for (let j = 0; j < changedRow.attachments.length; j++) {
            if (
              changedRow.attachments[j].observationId ===
              data.attachments[0].observationId
            ) {
              let CorrectedAttachments = changedRow.attachments.filter(
                attachment =>
                  attachment.observationId !== data.attachments[0].observationId
              )
              CorrectedAttachments.push(data.attachments[0])
              changedRow.attachments = CorrectedAttachments

              let correctedRows = changedFollowerRef.current.filter(
                changedFollower =>
                  changedFollower.beneficiarId !== data.beneficiarId
              )
              correctedRows.push(changedRow)
              setChangedFollowers(correctedRows)
            } else changedRow.attachments.push(data.attachments[0])
          }
        } else setChangedFollowers([...changedFollowerRef.current, data])
      }
    } else setChangedFollowers([...changedFollowerRef.current, data])
  };

  const collectiveAttachmentHandler = () => {
    let newAttachList = []
    if (Object.keys(data.beneficiaries).length > 0) {
      const beneficiaries = data.beneficiaries

      for (let i = 0; i < beneficiaries.length; i++) {
        let followers = beneficiaries[i].followers
        // if (followers.length > 0) { //if any cell in column is empty do not attach followers to the next column's cells // if you will use it don't forget uncomment else case below too
        if (followers.length <= collectiveAttachmentObservationId) {
          for (let j = 0; j < followers.length; j++) {
            if (followers[j].observationId === collectiveAttachmentObservationId && followers[j].isCompleted === true) {
              let newAttachFollower = {
                beneficiarId: beneficiaries[i].beneficiarId,
                attachments: [
                  {
                    observationId: collectiveAttachmentObservationId + 1,
                    followerId: followers[j].followerId,
                  },
                ],
              }
              newAttachList = [...newAttachList, newAttachFollower]

            }
          }
        } else {

          return setAttachmentError(
            `Ümumi təhkimetmə zamanı təhkim ediləcək müşahidə səfərinin sütununda olan bütün səfərlər boş olmalıdır. ${collectiveAttachmentObservationId + 1
            } ${collectiveAttachmentObservationId + 1 < 3 ? "ci" : "cü"
            } Müşahidə səfəri sütununda isə müşahidəçi təhkim edilmiş səfərlər mövcuddur. `
          )
        }
        // } else {
        //   return setAttachmentError(
        //     `${collectiveAttachmentObservationId} ${collectiveAttachmentObservationId < 3 ? "ci" : "cü"
        //     } Müşahidə səfəri sütunu tam doldurulmayıb.`
        //   )
        // }
      }
      if (newAttachList.length > 0)
        return setCollectiveAttachedFollowers(newAttachList)
      else {
        return setAttachmentError(
          `${collectiveAttachmentObservationId} ${collectiveAttachmentObservationId < 3 ? "ci" : "cü"
          } Müşahidə səfəri sütunu doldurulmayıb vəya müşahidə səfərləri tamamlanmayıb.`
        )
      }
    } else return setAttachmentError("Benefisiarlar tapılmadı")
  };

  const collectiveAttachmentModalHandler = observationId => {
    setcollectiveAttachmentObservationId(observationId)
  };

  const handleToggleCollectiveAttachmentModal = () => {
    setcollectiveAttachmentObservationId(0)
    setAttachmentError("")
  };

  const postAttachFollowersHandler = () => {
    const Payload = {
      followers: changedFollowers,
      beneficiarFilter: {
        filter,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      }
    }
    dispatch(requestPostAttachFollowers(Payload))
  };

  const postCollectiveAttachFollowerHandler = () => {
    const Payload = {
      followers: collectiveAttachedFollowers,
      beneficiarFilter: {
        filter,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      }
    }
    dispatch(requestPostAttachFollowers(Payload))
  };

  const handleObservationDetails = (observation) => {
    setObservationModal(true);
    setSelectedObservation(observation)
  };

  const handleToggleObservationModal = () => {
    setObservationModal(false)
    setSelectedObservation(null)
    setSelectedObservationNewDate(null)
  };

  const handleExportBeneficiarData = () => {
    if (!isExportLoading) {
      dispatch(requestExportBeneficiariesData(filter, { type: "dashboard" }))
      setIsExportLoading(true);
    }
  };

  const handleExportAnswersData = () => {
    if (!isExportAnswersLoading) {
      dispatch(requestExportBeneficiariesAnserData(filter));
      setIsExportAnswersLoading(true);
    }
  };

  const handleChangeObservationDateModal = (date) => {
    if (date) {
      setSelectedObservationNewDate(date);
      setChangeObservationDateModal(true);
    }
  };

  const handleChangeObservationDate = () => {
    const payload = {
      beneficiarId: selectedObservtaion.beneficiarId,
      observationId: selectedObservtaion.observationId,
      observationDate: selectedObservationNewDate
    }

    dispatch(requestPutObservationDate(
      {
        payload: payload
      },
      {
        pagination: {
          pageIndex: pagination.pageIndex + 1,
          pageSize: pagination.pageSize,
        }
      }
    ))
  };

  const handleDeleteObservation = () => {
    dispatch(requestDeleteObservation(
      {
        payload: deleteObservation
      },
      {
        pagination: {
          pageIndex: pagination.pageIndex + 1,
          pageSize: pagination.pageSize,
        }
      }
    ))
  };


  //useeffects
  useEffect(() => {
    if (postAttachFollowerResult && postAttachFollowerResult?.success) {
      setcollectiveAttachmentObservationId(0)
      setChangedFollowers([])
      setAttachmentError("")
    }
  }, [postAttachFollowerResult]);

  useEffect(() => {
    if (!isFirstLoad) {
      dispatch(
        requestBeneficiars({
          filter,
          pageIndex: pagination.pageIndex + 1,
          pageSize: pagination.pageSize,
        })
      )

    } else {
      setIsFirstLoad(false), () => { dispatch(saveBeneficiarsFilter({})) }
    }
  }, [filter, isFirstLoad, pagination]);

  useEffect(() => {
    if (collectiveAttachedFollowers.length > 0) {
      postCollectiveAttachFollowerHandler()
    }
  }, [collectiveAttachedFollowers]);

  useEffect(() => {
    changedFollowerRef.current = changedFollowers;
  }, [changedFollowers]);

  useEffect(() => {
    if (beneficiarExportResult && beneficiarExportResult?.success) {
      setTimeout(() => {
        setIsExportLoading(false);
        setIsExportAnswersLoading(false);
      }, 550);
    }
  }, [beneficiarExportResult]);

  useEffect(() => {
    if (newDateResult && newDateResult.success) setChangeObservationDateModal(false);
  }, [newDateResult]);

  useEffect(() => {
    if (deleteObservationResult && deleteObservationResult.success) {
      setDeleteObservation(null);
      handleToggleObservationModal()
    }
  }, [deleteObservationResult]);

  useEffect(() => {
    setSelectedObservationNewDate(formatDate(selectedObservtaion?.observationDate))
  }, [selectedObservtaion])

  const tableColumns = useMemo(
    () => {
      return [
        {
          id: "gallery",
          header: "Şəkillər",
          accessorKey: "images",
          cell: ({ row }) => {
            const isPhotoValid = row.original.isPhotoValid
            return (
              <i
                onClick={() => beneficiarSetter(row.original)}
                className={`mdi mdi-folder-image ${isPhotoValid ? "text-primary" : "text-secondary"}`}
                style={{ fontSize: "24px", cursor: "pointer", padding: "15px" }}
              ></i>
            )
          },
        },
        ...columns,
        {
          header: () => {
            return <div className="m-3">  Müşahidə səfərləri </div>
          },
          id: "observations",
          meta: {
            align: "center",
            border: {
              bottom: "1px solid #c9c9c9",
            }
          },
          columns: [
            {
              header: ({ table }) => {
                return <div className="headerWithImage">
                  <p>
                    Müşahidə səfəri 1
                  </p>
                  <a title="Ümumi təhkimetmə" onClick={() => collectiveAttachmentModalHandler(1)} >
                    <div className="tableIconWrapper">
                      <img alt="Ümumi təhkimetmə" src={require("../../../assets/images/Column.png")} />
                      <img alt="Ümumi təhkimetmə" src={require("../../../assets/images/CopyColumn.png")} />
                    </div>
                  </a>
                </div>
              },
              id: 'obervation-1',
              accessorKey: "followers",
              cell: ({ row, table, getValue }) => {
                const Observation = 1;
                const data = row.original
                const follower = getValue()?.filter(value => value.observationId === Observation)[0]
                const followers = table.getState().followers
                const observationDate = getValue()?.filter(value => value.observationId === Observation)[0]?.observationDate
                const isDisabled = observationDate ? true : follower?.ignored ? true : false
                return (
                  <FollowerSelect
                    data={data}
                    observationId={Observation}
                    follower={follower}
                    followers={followers}
                    followerChangingHandler={followerChangingHandler}
                    isDisabled={isDisabled}
                    handleObservationDetails={handleObservationDetails}
                    setDeleteObservation={setDeleteObservation}
                  />
                );
              }
            },

            {
              header: ({ table }) => {
                return <div className="headerWithImage">
                  <p>
                    Müşahidə səfəri 2
                  </p>
                  <a title="Ümumi təhkimetmə" onClick={() => collectiveAttachmentModalHandler(2)}>
                    <div className="tableIconWrapper">
                      <img alt="Ümumi təhkimetmə" src={require("../../../assets/images/Column.png")} />
                      <img alt="Ümumi təhkimetmə" src={require("../../../assets/images/CopyColumn.png")} />
                    </div>
                  </a>
                </div>
              },
              id: 'obervation-2',
              accessorKey: "followers",
              cell: ({ row, table, getValue }) => {
                const Observation = 2;
                const data = row.original
                const follower = getValue()?.filter(value => value.observationId === Observation)[0]
                const prewFollower = getValue()?.filter(value => value.observationId === Observation - 1)[0]
                const followers = table.getState().followers
                const observationDate = getValue()?.filter(value => value.observationId === Observation)[0]?.observationDate
                const isDisabled = follower?.ignored ? true : prewFollower?.ignored === true ? true : !prewFollower?.isCompleted ? true : observationDate ? true : false
                // old way // const isDisabled = prewFollower?.ignored === true ? true : !prewFollower?.isCompleted ? true : observationDate ? true : false
                return (
                  <FollowerSelect
                    data={data}
                    observationId={Observation}
                    follower={follower}
                    followers={followers}
                    followerChangingHandler={followerChangingHandler}
                    isDisabled={isDisabled}
                    handleObservationDetails={handleObservationDetails}
                    setDeleteObservation={setDeleteObservation}
                  />
                );
              }
            },

            {
              header: ({ table }) => {
                return <div className="headerWithImage">
                  <p > Müşahidə səfəri 3 </p>
                  <a title="Ümumi təhkimetmə" className="attachWrapper" onClick={() => collectiveAttachmentModalHandler(3)}>
                    <div className="tableIconWrapper">
                      <img alt="Ümumi təhkimetmə" src={require("../../../assets/images/Column.png")} />
                      <img alt="Ümumi təhkimetmə" src={require("../../../assets/images/CopyColumn.png")} />
                    </div>
                  </a>
                </div>
              },
              id: 'obervation-3',
              accessorKey: "followers",
              cell: ({ row, table, getValue }) => {
                const Observation = 3;
                const data = row.original
                const follower = getValue()?.filter(value => value.observationId === Observation)[0]
                const prewFollower = getValue()?.filter(value => value.observationId === Observation - 1)[0]
                const followers = table.getState().followers
                const observationDate = getValue()?.filter(value => value.observationId === Observation)[0]?.observationDate
                const isDisabled = follower?.ignored ? true : prewFollower?.ignored === true ? true : !prewFollower?.isCompleted ? true : observationDate ? true : false
                // old way // const isDisabled = prewFollower?.ignored === true ? true : !prewFollower?.isCompleted ? true : observationDate ? true : false
                return (
                  <FollowerSelect
                    data={data}
                    observationId={Observation}
                    follower={follower}
                    followers={followers}
                    followerChangingHandler={followerChangingHandler}
                    isDisabled={isDisabled}
                    handleObservationDetails={handleObservationDetails}
                    setDeleteObservation={setDeleteObservation}
                  />
                );
              }
            },

            {
              header: ({ table }) => {
                return <div className="headerWithImage">
                  <p> Müşahidə səfəri 4 </p>
                  <div className="pseudo" />
                </div>
              },
              id: 'obervation-4',
              accessorKey: "followers",
              cell: ({ row, table, getValue }) => {
                const Observation = 4;
                const data = row.original
                const follower = getValue()?.filter(value => value.observationId === Observation)[0]
                const prewFollower = getValue()?.filter(value => value.observationId === Observation - 1)[0]
                const followers = table.getState().followers
                const observationDate = getValue()?.filter(value => value.observationId === Observation)[0]?.observationDate
                const isDisabled = follower?.ignored ? true : prewFollower?.ignored === true ? true : !prewFollower?.isCompleted ? true : observationDate ? true : false

                // old way // const isDisabled = prewFollower?.ignored === true ? true : !prewFollower?.isCompleted ? true : observationDate ? true : false
                return (
                  <FollowerSelect
                    data={data}
                    observationId={Observation}
                    follower={follower}
                    followers={followers}
                    followerChangingHandler={followerChangingHandler}
                    isDisabled={isDisabled}
                    handleObservationDetails={handleObservationDetails}
                    setDeleteObservation={setDeleteObservation}
                  />
                );
              }
            },
          ]
        },
      ]

    },
    [columns]
  )

  return (
    <Card>
      <CardBody>
        <Row>
          <Col className="col-6 d-flex justify-content-start my-2">
            <Col className="d-flex justify-content-start">
              <a className={`btn me-3 ${isExportLoading ? "btn-secondary" : "btn-success"}`} title="export to excel file" onClick={handleExportBeneficiarData}>
                {isExportLoading ? (
                  <span
                    className="spinner-border spinner-border-sm me-3 font-size-18 p-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : <i className="mdi mdi-download me-2 font-size-18"></i>}
                {" "}
                Export
              </a>

              {/* <a className={`btn me-3 ${isExportAnswersLoading ? "btn-secondary" : "btn-success"}`} title="export to excel file" onClick={handleExportAnswersData}>
                {isExportAnswersLoading ? (
                  <span
                    className="spinner-border spinner-border-sm me-3 font-size-18 p-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : <i className="mdi mdi-download me-2 font-size-18"></i>}
                {" "}
                Cavablar
              </a> */}
            </Col>
          </Col>
          <Col className="col-6 text-end my-2">
            <a className="btn btn-outline-primary" disabled={changedFollowers.length > 0 ? false : true} onClick={() => postAttachFollowersHandler()}>
              {isLoadingAttachFollower && (
                <span
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}{" "}
              Yaddaşa yaz
            </a>
          </Col>
        </Row>
        {/* table */}
        <Row>
          <Col>
            <EnhancedTable
              onPaginationChange={setPagination}
              pagination={pagination}
              manualPagination
              defaultColumn={defaultColumn}
              data={data.beneficiaries}
              columns={tableColumns}
              isLoading={isLoading}
              DataCount={data.count}
              followers={followers}
            />
          </Col>

        </Row>

        {/* collective attachment modal */}
        <Modal
          size="lg"
          style={{ marginTop: '30vh' }}
          isOpen={Boolean(collectiveAttachmentObservationId > 0)}
          toggle={handleToggleCollectiveAttachmentModal}
        >
          <ModalHeader toggle={handleToggleCollectiveAttachmentModal} tag="h4">
            Ümumi təhkimetmə
          </ModalHeader>
          <ModalBody>
            <Row>
              {!attachmentError ? <p className="font-size-18">
                {collectiveAttachmentObservationId}{collectiveAttachmentObservationId < 3 ? 'ci' : 'cü'} Müşahidə səfərində olan müşahidəçiləri {collectiveAttachmentObservationId + 1}{collectiveAttachmentObservationId + 1 < 3 ? 'ci' : 'cü'} müşahidə səfərinə təhkim etmək istədiyinizə əminsinizmi?
              </p> : <p className="font-size-18 text-danger">
                {attachmentError}
              </p>}
            </Row>
            <Row className="justify-content-right">
              <div className="d-flex flex-row-reverse">
                {!attachmentError && <a className="btn btn-success m-2" onClick={collectiveAttachmentHandler}>
                  {isLoadingAttachFollower && (
                    <span
                      className="spinner-border spinner-border-sm me-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}{" "}
                  Bəli
                </a>}
                <a className="btn btn-secondary m-2" onClick={handleToggleCollectiveAttachmentModal}>{!attachmentError ? 'İmtina' : 'Bağla'}</a>
              </div>
            </Row>
          </ModalBody>
        </Modal>

        {/* observation details modal */}
        <Modal size="xl" isOpen={observationModal} toggle={handleToggleObservationModal}>
          <ModalBody>
            <div className="d-flex justify-content-between pb-4 border-bottom">
              <div>
                <h4>{selectedObservtaion?.fullName}</h4>
                <h5>
                  {selectedObservtaion?.observationId}{" "}
                  {selectedObservtaion?.observationId < 3 ? "ci" : "cü"} Müşahidə səfərlərinin detalları
                </h5>
              </div>

              {user.role === "ROLE_ADMIN" || user.role === "ROLE_MANAGER" ? <div>
                <h6>Müşahidə səfərinin tarixi</h6>
                <Input
                  type="date"
                  name="newObservationDate"
                  value={selectedObservationNewDate || ""}
                  onChange={(e) => handleChangeObservationDateModal(e.target.value)}
                />
              </div> : <></>}
            </div>
            {selectedObservtaion && <ObservationQuestions data={selectedObservtaion} />}
          </ModalBody>
        </Modal>

        {/* observation date confirm change modal*/}
        <Modal size="md" isOpen={changeObservationDateModal} centered toggle={() => setChangeObservationDateModal(false)}>
          <ModalBody>
            <div className="p-2 m-2 ">
              <div className="w-100 text-center mb-5">
                <h4>Müşahidə səfərinin tarixini dəyişmək istədiyinizə əminsiniz?</h4>
              </div>
              <div className="d-flex justify-content-end p-1">
                <button
                  className="btn btn-success me-2"
                  onClick={() => {
                    handleChangeObservationDate()
                  }}
                  disabled={postDateLoading}
                >
                  {postDateLoading && (
                    <span
                      className="spinner-border spinner-border-sm me-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}{" "}
                  Dəyiş
                </button>

                <button
                  className="btn btn-secondary"
                  onClick={() => {
                    setChangeObservationDateModal(false)
                  }}
                >
                  Bağla
                </button>
              </div>
            </div>

          </ModalBody>
        </Modal>

        <DeleteModal
          show={Boolean(deleteObservation)}
          text={{
            title: `Müşahidə səfərini silmək istədiyinizə əminsiniz ?`,
          }}
          onDeleteClick={handleDeleteObservation}
          onCloseClick={() => setDeleteObservation(null)}
          isLoading={deleteObservationLoading}
        />
      </CardBody>
    </Card >
  )
}
