export const REQUEST_OBSERVATIONS_COUNT = "REQUEST_OBSERVATIONS_COUNT"
export const REQUEST_OBSERVATIONS_COUNT_FINISHED = "REQUEST_OBSERVATIONS_COUNT_FINISHED"

export const REQUEST_BENEFICIARS_COUNT = "REQUEST_BENEFICIARS_COUNT"
export const REQUEST_BENEFICIARS_COUNT_FINISHED = "REQUEST_BENEFICIARS_COUNT_FINISHED"

export const REQUEST_VOEN_STATISTICS = "REQUEST_VOEN_STATISTICS" 
export const REQUEST_VOEN_STATISTICS_FINISH = "REQUEST_VOEN_STATISTICS_FINISH" 

export const  REQUEST_EQUIPMENT_STATISTICS= "REQUEST_EQUIPMENT_STATISTICS"
export const  REQUEST_EQUIPMENT_STATISTICS_FINISH= "REQUEST_EQUIPMENT_STATISTICS_FINISH"