import React from 'react'
import { useState } from 'react'

export const ImageLoader = ({ image,deleteImageSetter }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  return (
    <div style={{ position: 'relative' }}>
      <div style={{
        position: 'absolute',
        right: 0, top: 0
      }}>
        {!isImageLoaded && (<span
          className="spinner-border spinner-border-sm me-3 font-size-24 text-primary"
          role="status"
          aria-hidden="true"
        ></span>)}
      </div>
      <img src={image?.url} 
      style={{ width: `${isImageLoaded ? '100%' : 0}`, height: '10vh', objectFit: 'cover' }} 
      onLoad={() => setIsImageLoaded(true)} />
    </div>
  )
}
