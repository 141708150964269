// AUTH
export const LOGIN = "/api/account/login";
export const FORGET_PASSWORD_PATH = "/api/account/forgot-password";
export const RESET_PASSWORD_PATH = "/api/account/reset-password"
export const REFRESH_TOKEN = "/api/account/refresh-token";
export const CHANGE_PASSWORD = "/api/account/change-password";

// ANSWERS
export const GET_ANSWERS = "/api/answers/all";
export const PUT_ANSWERS_BY_ID = "/api/answers"
export const PUT_ANSWER = "/api/answers/answer";
export const POST_ANSWER = "/api/answers/answer";

// Beneficiary
export const GET_BENEFICIARS = "/api/beneficiary/all"
export const GET_OBSERVATION_COUNTS = "/api/beneficiary/observation-counts"
export const GET_VOEN_STATISTICS = "/api/beneficiary/voen-statistics"
export const GET_EQUIPMENT_STATISTICS = "/api/beneficiary/equipment-statistics"
export const POST_BENEFICIAR = "api/beneficiary/add-or-update-beneficiary"
export const POST_BENEFICIAR_IMAGES = "/api/beneficiary/admin/upload-images"
export const PUT_DELETE_BENEFICIAR = "/api/beneficiary/beneficiaries"

export const GET_ALL_DETAILED = "/api/beneficiary/all-detailed"
export const GET_QUESTION_AND_ANSWERS = "/api/beneficiary/question-and-answers"
export const POST_ATTACH_FOLLOWER = "/api/beneficiary/attach-follower"
export const POST_IMPORT = "/api/beneficiary/import"
export const GET_EXPORT = "/api/beneficiary/export"
export const GET_EXPORT_DATA = "/api/beneficiary/export/beneficiaries"
export const GET_EXPORT_ANSWER_DATA = "/api/beneficiary/export/questions-and-answers"
export const GET_MISSING_EQUIPMENTS = "/api/businesses/missing-equipments"

// Users
export const GET_USERS = "/api/users/all"
export const POST_USER = "/api/users/user"
export const PUT_USER = "/api/users/user"
export const DELETE_USER = "/api/users"
export const RESET_PASSWORD_AS_ADMIN_PATH = "/api/users/reset-password"
export const PUT_USER_BY_ID = "/api/users"


// Business
export const GET_BUSINESSES = "/api/businesses/all"
export const POST_BUSINESS = "/api/businesses/business"
export const POST_BUSINESS_EQUIPMENT = "/api/businesses/business/equipment"
export const PUT_BUSINESS = "/api/businesses/business"
export const PUT_BUSINESS_BY_ID = "/api/businesses"

//Followers
export const GET_ATTACHED_BENEFICIARIES = "api/followers/attached-beneficiaries"
export const GET_OBSERVATION_QUESTIONS = "api/followers/questions-by-filter"
export const POST_QUESTION_ANSWER = "api/followers/answer-question"
export const POST_EQUIPMENT = "api/followers/answer-equipment-question"
export const PUT_UPDATE_ANSWERED_QUESTION = "api/followers/update-answered-question"

// Follower details
export const GET_FOLLOWER_DETAILS = "/api/followerdetails/all"
export const GET_ALL_FOLLOWERS = "/api/followers/all"
export const POST_FOLLOWER_DETAIL = "/api/followerdetails/follower-detail"
export const PUT_FOLLOWER_DETAIL = "/api/followerdetails/follower-detail"
export const PUT_FOLLOWER_DETAIL_BY_ID = "/api/followerdetails"
export const POST_COMPLETE_OBSERVATION = "/api/followers/complete-observation"

// Questions
export const GET_QUESTIONS = "/api/questions/all"
export const POST_QUESTION = "/api/questions/question"
export const DELETE_QUESTION = "/api/questions"
export const PUT_QUESTION = "/api/questions/question"
export const PUT_QUESTION_BY_ID = "/api/questions"
export const GET_ANSWERTYPES = "/api/dropdown/answer-types"


// Report
export const GET_BENEFICIAR_REPORTS = "/api/report/beneficiaries"
export const POST_BENEFICIAR_REPORT = "/api/report/export-report"

// Select options
export const GET_REGION_OPTIONS = "/api/dropdown/regions"
export const GET_BUSINESS_OPTIONS = "/api/dropdown/businesses"

//Beneficiars

export const GET_ALL_BENEFICIARS = "/api/beneficiary/all-detailed"
export const GET_BENEFICIAR_IMAGES = "/api/beneficiary"
export const DELETE_BENEFICIAR_IMAGE = "/api/beneficiary/images/delete"

//Stats
export const GET_COMMON_STATS = "/api/beneficiary/all-statistics"

export const GET_COMMON_STATS_LIGHT = "/api/beneficiary/beneficiary-statistics"

export const PUT_OBSERVATION_DATE = "/api/beneficiary/observation/date"

export const DELETE_OBSERVATION = "/api/beneficiary/observation"

