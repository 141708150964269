import formatDate from "helpers/format-date";
import React from "react"
import { UncontrolledTooltip } from "reactstrap";
export const resultColumns = [
  {
    header: "Şəxsi məlumatlar",
    id: "personal",
    meta: {
      align: 'center',
      border: {
        bottom: "1px solid #c9c9c9",
      },
    },
    columns: [
      {
        header: () => {
          return <div className="text-center"> Ad Soyad </div>
        },
        accessorKey: "fullName",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '150px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center"> FIN kod </div>
        },
        accessorKey: "fin",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '100px' }}>{getValue()}</p>

        }
      },
      {
        header: () => {
          return <div className="text-center"> Qrup kodu </div>
        },
        accessorKey: "groupCode",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '100px' }}>{getValue()}</p>
        }
      },
      {
        header: () => {
          return <div className="text-center">  Ünvan </div>
        },
        accessorKey: "address",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '150px' }}> {getValue()} </p>
        },
      },
      {
        header: () => {
          return <div className="text-center"> Rayon </div>
        },
        accessorKey: "region",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '100px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center">
            Biznes
          </div>
        },
        accessorKey: "business",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '100px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center"> Xüsusi xidmətlər </div>
        },
        accessorKey: "specialServices",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '150px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center"> Əlaqə nömrələri </div>
        },
        accessorKey: "contactNumbers",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '150px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center"> Əlillik </div>
        },
        accessorKey: "disabled",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '90px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center"> ÜDSY </div>
        },
        accessorKey: "udys",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '90px' }}>{getValue() ? "Bəli" : "Xeyr"}</p>
        }
      },
      {
        header: () => {
          return <div className="text-center"> Məcburi köçkündür </div>
        },
        accessorKey: "idp",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '70px' }}>{getValue() ? "Bəli" : "Xeyr"}</p>
        }
      },
      {
        header: () => {
          return <div className="text-center"> Cins</div>
        },
        accessorKey: "gender",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '50px' }}>{getValue()?.toLowerCase() === "f" ? "Qadın" : "Kişi"}</p>

        }
      },
      {
        header: () => {
          return <div className="text-center"> Qiymətləndirmə </div>
        },
        accessorKey: "assestmentDate",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '150px' }}>{formatDate(getValue())}</p>

        }
      },
      {
        header: () => {
          return <div className="text-center"> Aktivlərin verilməsi </div>
        },
        accessorKey: "equipmentDeliveryDate",
        meta: {
          border: {
            right: "1px solid #d9d9dc",
          }
        },
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '150px' }}>{formatDate(getValue())}</p>

        }
      },
    ],
  },
  {
    header: () => {
      return <div className="m-3">  Müşahidə səfərləri </div>
    },
    id: "observations",
    meta: {
      align: "center",
      border: {
        bottom: "1px solid #c9c9c9",
      }
    },
    columns: [
      {
        header: ({ table }) => {
          return <div>  Müşahidə səfəri 1 </div>
        },
        id: 'obervation-1',
        accessorKey: "observationDetails",
        cell: ({ row, table, getValue }) => {
          const Observation = getValue()?.find(observation => observation.observationId === 1)
          const Data = {
            ObservationId: 1,
            BeneficiaryId: row.original.beneficiarId,
            FullName: row.original.fullName,
            IsCompleted: Observation?.isCompleted,
            IsObserved: Observation?.observedAt !== null ? true : false
          }
          if (Observation) {
            if (Observation.ignored === true) {
              return <div className="d-flex justify-content-center" alt="Şərait yaradılmayıb">
                <i className="text-danger mdi mdi-folder-lock" style={{fontSize:'30px'}} id={`ignored1${row.id}`}></i>
                <UncontrolledTooltip placement="top" target={`ignored1${row.id}`}>
                  Şərait yaradılmayıb
                </UncontrolledTooltip>
              </div>
            } else if (Observation.isCompleted) {
              return <div className="d-flex justify-content-center">
                <a className="text-success" href="#"
                  onClick={() => {
                    table.getState().SelectBeneficiarObservationHandler(Data)
                  }}
                >
                  <i className="text-success mdi mdi-folder-account" style={{fontSize:'30px'}} id={`ObservationCompleted1${row.id}`}></i>
                  <UncontrolledTooltip placement="top" target={`ObservationCompleted1${row.id}`}>
                    Müşahidə səfəri tamamlanmışdır
                  </UncontrolledTooltip>
                </a>
              </div>
            } else {
              return <div className="d-flex justify-content-center" >
                <a className="text-success" href="#"
                  onClick={() => {
                    table.getState().SelectBeneficiarObservationHandler(Data)
                  }}
                >
                  <i className="text-primary mdi mdi-folder-account" style={{fontSize:'30px'}} id={`observation1${row.id}`}></i>
                  <UncontrolledTooltip placement="top" target={`observation1${row.id}`}>
                    Müşahidə səfərlərinin detalları
                  </UncontrolledTooltip>
                </a>
              </div>
            }
          } else {
            return <div className="d-flex justify-content-center">
              <i className="text-secondary mdi mdi-folder" style={{fontSize:'30px'}} id={`NonObservation1${row.id}`}></i>
              <UncontrolledTooltip placement="top" target={`NonObservation1${row.id}`}>
                Təhkim edilməyib
              </UncontrolledTooltip>
            </div>
          }

        }
      },

      {
        header: ({ table }) => {
          return <div> Müşahidə səfəri 2  </div>
        },
        id: 'obervation-2',
        accessorKey: "observationDetails",
        cell: ({ row, table, getValue }) => {
          const Observation = getValue()?.find(observation => observation.observationId === 2)
          const Data = {
            ObservationId: 2,
            BeneficiaryId: row.original.beneficiarId,
            FullName: row.original.fullName,
            IsCompleted: Observation?.isCompleted,
            IsObserved: Observation?.observedAt !== null ? true : false
          }

          if (Observation) {
            if (Observation.ignored === true) {
              return <div className="d-flex justify-content-center">
                <i className="text-danger mdi mdi-folder-lock" style={{fontSize:'30px'}} id={`ignored2${row.id}`}></i>
                <UncontrolledTooltip placement="top" target={`ignored2${row.id}`}>
                  Şərait yaradılmayıb
                </UncontrolledTooltip>
              </div>
            } else if (Observation.isCompleted) {
              return <div className="d-flex justify-content-center">
                <a className="text-success" href="#"
                  onClick={() => {
                    table.getState().SelectBeneficiarObservationHandler(Data)
                  }}
                >
                  <i className="text-success mdi mdi-folder-account" style={{fontSize:'30px'}} id={`ObservationCompleted2${row.id}`}></i>
                  <UncontrolledTooltip placement="top" target={`ObservationCompleted2${row.id}`}>
                    Müşahidə səfəri tamamlanmışdır
                  </UncontrolledTooltip>
                </a>
              </div>
            } else {
              return <div className="d-flex justify-content-center">
                <a className="text-success" href="#"
                  onClick={() => {
                    table.getState().SelectBeneficiarObservationHandler(Data)
                  }}
                >
                  <i className="text-primary mdi mdi-folder-account" style={{fontSize:'30px'}} id={`observation2${row.id}`}></i>
                  <UncontrolledTooltip placement="top" target={`observation2${row.id}`}>
                    Müşahidə səfərlərinin detalları
                  </UncontrolledTooltip>
                </a>
              </div>
            }
          } else {
            return <div className="d-flex justify-content-center">
              <i className="text-secondary mdi mdi-folder" style={{fontSize:'30px'}} id={`NonObservation2${row.id}`}></i>
              <UncontrolledTooltip placement="top" target={`NonObservation2${row.id}`}>
                Təhkim edilməyib
              </UncontrolledTooltip>
            </div>
          }

        }
      },

      {
        header: ({ table }) => {
          return <div> Müşahidə səfəri 3 </div>
        },
        id: 'obervation-3',
        accessorKey: "observationDetails",
        cell: ({ row, table, getValue }) => {
          const Observation = getValue()?.find(observation => observation.observationId === 3)
          const Data = {
            ObservationId: 3,
            BeneficiaryId: row.original.beneficiarId,
            FullName: row.original.fullName,
            IsCompleted: Observation?.isCompleted,
            IsObserved: Observation?.observedAt !== null ? true : false
          }
          if (Observation) {
            if (Observation.ignored === true) {
              return <div className="d-flex justify-content-center">
                <i className="text-danger mdi mdi-folder-lock" style={{fontSize:'30px'}} id={`ignored3${row.id}`}></i>
                <UncontrolledTooltip placement="top" target={`ignored3${row.id}`}>
                  Şərait yaradılmayıb
                </UncontrolledTooltip>
              </div>
            } else if (Observation.isCompleted) {
              return <div className="d-flex justify-content-center">
                <a className="text-success" href="#"
                  onClick={() => {
                    table.getState().SelectBeneficiarObservationHandler(Data)
                  }}
                >
                  <i className="text-success mdi mdi-folder-account" style={{fontSize:'30px'}} id={`ObservationCompleted3${row.id}`}></i>
                  <UncontrolledTooltip placement="top" target={`ObservationCompleted3${row.id}`}>
                    Müşahidə səfəri tamamlanmışdır
                  </UncontrolledTooltip>
                </a>
              </div>
            } else {
              return <div className="d-flex justify-content-center">
                <a className="text-success" href="#"
                  onClick={() => {
                    table.getState().SelectBeneficiarObservationHandler(Data)
                  }}
                >
                  <i className="text-primary mdi mdi-folder-account" style={{fontSize:'30px'}} id={`observation3${row.id}`}></i>
                  <UncontrolledTooltip placement="top" target={`observation3${row.id}`}>
                    Müşahidə səfərlərinin detalları
                  </UncontrolledTooltip>
                </a>
              </div>
            }
          } else {
            return <div className="d-flex justify-content-center">
              <i className="text-secondary mdi mdi-folder" style={{fontSize:'30px'}} id={`NonObservation3${row.id}`}></i>
              <UncontrolledTooltip placement="top" target={`NonObservation3${row.id}`}>
                Təhkim edilməyib
              </UncontrolledTooltip>
            </div>
          }
        }
      },

      {
        header: ({ table }) => {
          return <div> Müşahidə səfəri 4 </div>
        },
        id: 'obervation-4',
        accessorKey: "observationDetails",
        cell: ({ row, table, getValue }) => {
          const Observation = getValue()?.find(observation => observation.observationId === 4)
          const Data = {
            ObservationId: 4,
            BeneficiaryId: row.original.beneficiarId,
            FullName: row.original.fullName,
            IsCompleted: Observation?.isCompleted,
            IsObserved: Observation?.observedAt !== null ? true : false
          }

          if (Observation) {
            if (Observation.ignored === true) {
              return <div className="d-flex justify-content-center">
                <i className="text-danger mdi mdi-folder-lock" style={{fontSize:'30px'}} id={`ignored4${row.id}`}></i>
                <UncontrolledTooltip placement="top" target={`ignored4${row.id}`}>
                  Şərait yaradılmayıb
                </UncontrolledTooltip>
              </div>
            } else if (Observation.isCompleted) {
              return <div className="d-flex justify-content-center">
                <a className="text-success" href="#"
                  onClick={() => {
                    table.getState().SelectBeneficiarObservationHandler(Data)
                  }}
                >
                  <i className="text-success mdi mdi-folder-account" style={{fontSize:'30px'}} id={`ObservationCompleted4${row.id}`}></i>
                  <UncontrolledTooltip placement="top" target={`ObservationCompleted4${row.id}`}>
                    Müşahidə səfəri tamamlanmışdır
                  </UncontrolledTooltip>
                </a>
              </div>
            } else {
              return <div className="d-flex justify-content-center">
                <a className="text-success" href="#"
                  onClick={() => {
                    table.getState().SelectBeneficiarObservationHandler(Data)
                  }}
                >
                  <i className="text-primary mdi mdi-folder-account" style={{fontSize:'30px'}} id={`observation4${row.id}`}></i>
                  <UncontrolledTooltip placement="top" target={`observation4${row.id}`}>
                    Müşahidə səfərlərinin detalları
                  </UncontrolledTooltip>
                </a>
              </div>
            }
          } else {
            return <div className="d-flex justify-content-center">
              <i className="text-secondary mdi mdi-folder" style={{fontSize:'30px'}} id={`NonObservation4${row.id}`}></i>
              <UncontrolledTooltip placement="top" target={`NonObservation4${row.id}`}>
                Təhkim edilməyib
              </UncontrolledTooltip>
            </div>
          }

        }
      },
    ]
  },
]