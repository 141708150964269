import {

  REQUEST_OBSERVATIONS_COUNT_FINISHED,
  REQUEST_BENEFICIARS_COUNT_FINISHED,
  REQUEST_VOEN_STATISTICS_FINISH,
  REQUEST_EQUIPMENT_STATISTICS_FINISH
} from "./actionTypes"

const initialState = {
  observationStatistics: {},
  beneficiarStatistics:{},
  voenStatistics:{},
  equipmentStatistics:{},
}

const ObservationsCount = (state = initialState, { type, payload, error, meta }) => {
  if (error) return state;

  switch (type) {
    case REQUEST_OBSERVATIONS_COUNT_FINISHED:
      return { ...state, observationStatistics: payload }
    case REQUEST_BENEFICIARS_COUNT_FINISHED:
      return { ...state, beneficiarStatistics: payload }
    case REQUEST_VOEN_STATISTICS_FINISH:
      return { ...state, voenStatistics: payload }
    case REQUEST_EQUIPMENT_STATISTICS_FINISH:
      return { ...state, equipmentStatistics: payload }
    default:
      return state
  }
}

export default ObservationsCount
