const filterInitialValues = {
  fullName: "",
  fin: "",
  pageNumber: "",
  Regions: "",
  followerId: "",
  observationId: "",
  nonObservationId: "",
  isDisabled: false,
  isMemberOfMartyrFamily: false,
  isVeteran: false,
  isEquipmentConfigured: false,
  equipmentDeliveredDate: "",
  hasVoen: false,
  FromDate: "",
  ToDate: "",
  BusinessId: "",
  Gender: ""
}

export default filterInitialValues