import { all, fork } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import BeneficiarySaga from "./beneficiars/saga";
import QuestionAnswersSaga from "./qa/saga";
import changePasswordSaga from "./auth/changePassword/saga";
import UsersSaga from "./users/saga";
import ObservationsCountSaga from "./observationCount/saga";
import CommonStats from "./commonStats/saga";
import BeneficiarImagesSaga from "./beneficiar-images/saga";
import QuestionsSaga from "./questions/saga";
import FollowersSaga from "./followers/saga";
import BusinessesSaga from "./businesses/saga";
export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(BeneficiarySaga),
    fork(QuestionAnswersSaga),
    fork(changePasswordSaga),
    fork(UsersSaga),
    fork(ObservationsCountSaga),
    fork(CommonStats),
    fork(BeneficiarImagesSaga),
    fork(QuestionsSaga),
    fork(FollowersSaga),
    fork(BusinessesSaga)
  ]);
}
