import "./BeneficiarGallery.scss"
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Container,
  Row,
  Col,
  Input,
  Form,
  UncontrolledTooltip,
  InputGroup
} from "reactstrap"
import { ImageLoader } from "./ImageLoader"
import DeleteModal from "components/Common/DeleteModal"
import { useDispatch } from "react-redux"
import { requestDeleteBeneficiarImage, requestPostBeneficiarImages } from "store/beneficiar-images/actions"
import { useSelector } from "react-redux"
import { useFormik } from "formik"
import { REQUEST_DELETE_BENEFICIAR_IMAGE, REQUEST_POST_BENEFICIAR_IMAGES } from "store/beneficiar-images/actionTypes"
import formatDate from "helpers/format-date"

const Monitoring = [
  {
    id: 1,
    title: "Müşahidə səfəri 1",
  },
  {
    id: 2,
    title: "Müşahidə səfəri 2",
  },
  {
    id: 3,
    title: "Müşahidə səfəri 3",
  },
  {
    id: 4,
    title: "Müşahidə səfəri 4",
  },

]

const BeneficiarGalleryModal = props => {
  const { isOpen, toggle, images, selectedBeneficiar, isGeneral } = props
  const [selectedImage, setSelectedImage] = useState(null);
  const [deleteData, setDeleteData] = useState(null);
  const [addImageModal, setAddImageModal] = useState(false);
  const [importedFile, setImportedFile] = useState([]);
  const [ImagesError, setImagesError] = useState("");
  const [canChangeDate, setCanChangeDate] = useState(true);
  const user = useSelector(state => state.Auth);
  const postImageLoading = useSelector(state => { return state.Requesting[REQUEST_POST_BENEFICIAR_IMAGES] });
  const deleteImageLoading = useSelector(state => { return state.Requesting[REQUEST_DELETE_BENEFICIAR_IMAGE] });
  const postImageResult = useSelector(state => state.BeneficiarImages.postBeneficiarImagesResult);

  const dispatch = useDispatch();


  function handleImportImageModal() {
    setAddImageModal(false);
    setImportedFile([]);
    setImagesError(null);
    form.resetForm();
  }
  const handleDeleteImage = () => {
    dispatch(requestDeleteBeneficiarImage({ ...deleteData, beneficiarId: selectedBeneficiar.beneficiarId }))
    setDeleteData(null)
  }

  const importInputHandler = (e) => {
    setImagesError("");
    setImportedFile(null);
    if (e.target.files) {
      const files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileType = file['type'];
        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

        if (!validImageTypes.includes(fileType)) {
          return setImagesError("Yalnız Imagesl file import edilə bilər.");
        }

        setImportedFile(files)
        setImagesError(null);
      }
    } else setImagesError("Fayl seçilməyib");
  }

  const MonitoringDropdownItems = () => {
    return (
      <>
        <option value="">Müşahidə səfəri seçin</option>

        {Monitoring.map(Monitoring => {
          return (
            <option key={Monitoring.id} value={Monitoring.id}>
              {Monitoring.title}
            </option>
          )
        })}
      </>
    )
  }



  useEffect(() => {
    if (postImageResult && postImageResult.success) {
      handleImportImageModal();
    }
  }, [postImageResult]);


  const form = useFormik({
    initialValues: {
      BeneficiarId: selectedBeneficiar,
      ObservationId: "",
      ObservationDate: "",
    },
    onSubmit: values => {
      const formattedPayload = new FormData();

      Array.from(importedFile).forEach(image => {
        formattedPayload.append("Images", image);
      });

      formattedPayload.append("BeneficiarId", Number(selectedBeneficiar.beneficiarId));
      formattedPayload.append("ObservationId", Number(values.ObservationId));
      formattedPayload.append("ObservationDate", values.ObservationDate);

      dispatch(requestPostBeneficiarImages(formattedPayload));
    },
  });

  useEffect(() => {
    if (form.values.ObservationId) {
      const observation = selectedBeneficiar?.followers.filter((follower) => follower.observationId === Number(form.values.ObservationId))[0];
      if (observation?.observationDate) {
        setCanChangeDate(false);
        form.setFieldValue("ObservationDate", formatDate(observation?.observationDate));
      } else {
        setCanChangeDate(true);
        form.setFieldValue("ObservationDate", null);
      }
    }
  }, [form.values.ObservationId])



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Modal
            size="xl"
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered={true}
            unmountOnClose
            className="exampleModal"
            tabIndex="-1"
            toggle={toggle}
          >
            <div className="modal-content">
              <ModalBody>
                <div className="overflow-auto">
                  <div className="d-flex justify-content-between w-100" >
                    <div><h4> Müşahidə şəkilləri</h4></div>
                    {isGeneral && user.role === "ROLE_ADMIN" ? <div>
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          setAddImageModal(true);
                        }}
                      >
                        Şəkil əlavə et
                      </button>
                    </div> : isGeneral && user.role === "ROLE_MANAGER" ? <div>
                      <button
                        className="btn btn-success"
                        onClick={() => {
                          setAddImageModal(true);
                        }}
                      >
                        Şəkil əlavə et
                      </button>
                    </div> : <></>}
                  </div>
                  <Row className="w-100 m-0 justify-content-between" >
                    {images && images.map(imageGroup => {
                      return (
                        <Col key={imageGroup.observationId} className="col-12 col-md-6 col GalleryBox">
                          <Label>{imageGroup.observationId}. Müşahidə səfəri</Label>
                          <Row>
                            {imageGroup.images.map(image => {
                              return (
                                <Col
                                  className="col-12 col-sm-6 col-xl-3 mb-3"
                                  key={image?.imageId}
                                >
                                  {isGeneral && user.role === "ROLE_ADMIN" && <div
                                    onClick={() => {
                                        setDeleteData({ imageId: image.imageId, observationId: imageGroup.observationId })
                                      }}
                                  >
                                    <i
                                      id={`deletetooltip${image.imageId}`}
                                      className="mdi mdi-close font-size-18 text-danger"
                                      style={{
                                        fontSize: "24px",
                                        cursor: "pointer",
                                        padding: "15px",
                                        paddingLeft: 0

                                      }}
                                    />
                                    <UncontrolledTooltip target={`deletetooltip${image.imageId}`}>
                                      Şəkli sil
                                    </UncontrolledTooltip>
                                  </div>}
                                  <div
                                    onClick={() => setSelectedImage(image)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <ImageLoader image={image} />
                                  </div>

                                </Col>
                              )
                            })}

                          </Row>
                        </Col>
                      )
                    })}

                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={toggle}> Bağla </Button>
              </ModalFooter>
            </div>
          </Modal>

          <Modal
            size="xl"
            isOpen={selectedImage ? true : false}
            role="dialog"
            autoFocus={true}
            centered={true}
            unmountOnClose
            className="exampleModal"
            tabIndex="1"
            toggle={() => setSelectedImage(null)}
          >
            <div className="modal-content">
              <ModalHeader toggle={() => setSelectedImage(null)}>Müşahidə şəkilləri</ModalHeader>
              <ModalBody>
                <div className="overflow-auto d-flex justify-content-center">
                  <img src={selectedImage?.url} style={{ width: '90%', height: 'auto', maxHeight: '70vh', objectFit: 'contain' }} />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button type="button" color="secondary" onClick={() => setSelectedImage(null)}> Bağla </Button>
              </ModalFooter>
            </div>
          </Modal>


          <Modal
            size="md"
            isOpen={addImageModal}
            role="dialog"
            autoFocus={true}
            centered={true}
            unmountOnClose
            className="exampleModal"
            tabIndex="1"
            toggle={handleImportImageModal}
          >
            <div className="modal-content">
              <ModalHeader toggle={handleImportImageModal}>Yeni Şəkillər</ModalHeader>
              <ModalBody>
                <Form
                  className="filter form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    form.handleSubmit()
                    return false
                  }}
                >
                  <Row className="jusify-conent-center">
                    <Col className="col-12 col-xl-6 p-2">
                      <Label className="form-label"> Müşahidə səfəri </Label>
                      <Input
                        name="ObservationId"
                        type="select"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.ObservationId || ""}
                        invalid={
                          form.touched.ObservationId &&
                            form.errors.ObservationId
                            ? true
                            : false
                        }
                      >
                        {MonitoringDropdownItems()}
                      </Input>
                    </Col>

                    <Col className="col-12 col-xl-6 p-2">
                      <Label className="form-label"> Müşahidə səfəri tarixi </Label>
                      <Input
                        name="ObservationDate"
                        type="date"
                        className="form-control"
                        placeholder=""
                        disabled={!canChangeDate}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.ObservationDate || ""}
                        invalid={
                          form.touched.ObservationDate &&
                            form.errors.ObservationDate
                            ? true
                            : false
                        }
                      />
                    </Col>

                    <Col className="col-12 p-2">
                      <Label className="form-label"> Şəkillər </Label>
                      <Input
                        type="file"
                        name="Images"
                        onChange={importInputHandler}
                        multiple={true}
                      />
                      <p className="text-danger my-2">{ImagesError ? ImagesError : ''}</p>
                    </Col>
                  </Row>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button
                  className="btn btn-success my-3"
                  onClick={form.handleSubmit}
                  disabled={postImageLoading}
                >
                  {postImageLoading && (
                    <span
                      className="spinner-border spinner-border-sm me-3"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}{" "}
                  Yadda saxla
                </Button>
                <Button type="button" color="secondary" onClick={handleImportImageModal}> Bağla </Button>
              </ModalFooter>
            </div>
          </Modal>

          <DeleteModal
            show={Boolean(deleteData)}
            text={{
              title: `Silmək istədiyinizə əminsiniz ?`,
            }}
            onDeleteClick={handleDeleteImage}
            onCloseClick={() => setDeleteData(null)}
            isLoading={deleteImageLoading}
          />

        </Container>
      </div>
    </React.Fragment>
  )
}

BeneficiarGalleryModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default BeneficiarGalleryModal
