import http from "./http"
import {
  CHANGE_PASSWORD,
  LOGIN,
  REFRESH_TOKEN,
  GET_ALL_BENEFICIARS,
  POST_BENEFICIAR,
  GET_EXPORT,
  GET_OBSERVATION_COUNTS,
  GET_QUESTION_AND_ANSWERS,
  GET_COMMON_STATS,
  GET_BENEFICIAR_IMAGES,
  GET_QUESTIONS,
  POST_QUESTION,
  DELETE_QUESTION,
  GET_ANSWERTYPES,
  GET_USERS,
  PUT_USER,
  DELETE_USER,
  POST_USER,
  GET_REGION_OPTIONS,
  GET_EXPORT_DATA,
  POST_ATTACH_FOLLOWER,
  GET_BUSINESS_OPTIONS,
  GET_ALL_FOLLOWERS,
  POST_IMPORT,
  GET_ATTACHED_BENEFICIARIES,
  GET_OBSERVATION_QUESTIONS,
  POST_QUESTION_ANSWER,
  PUT_UPDATE_ANSWERED_QUESTION,
  GET_BUSINESSES,
  POST_COMPLETE_OBSERVATION,
  POST_BUSINESS_EQUIPMENT,
  GET_FOLLOWER_DETAILS,
  POST_FOLLOWER_DETAIL,
  PUT_FOLLOWER_DETAIL,
  DELETE_BENEFICIAR_IMAGE,
  PUT_DELETE_BENEFICIAR,
  GET_EXPORT_ANSWER_DATA,
  FORGET_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  RESET_PASSWORD_AS_ADMIN_PATH,
  POST_EQUIPMENT,
  GET_COMMON_STATS_LIGHT,
  POST_BENEFICIAR_IMAGES,
  PUT_OBSERVATION_DATE,
  DELETE_OBSERVATION,
  GET_MISSING_EQUIPMENTS,
  PUT_BUSINESS_BY_ID
} from "./service-paths"

import { Storage } from "../helpers/storage-helper"
import { createParamString } from "helpers/urlParams"

////////////////////LOGIN & Authentiction/////////////////////////////////

export const login = async (credentials, deviceToken) => {
  const Data = {
    Password: credentials.password,
    UserName: credentials.username,
    DeviceToken: deviceToken,
  }
  return await http.post(LOGIN, Data)
}

export const postForgotPassword = async (payload) => {
  return await http.post(FORGET_PASSWORD_PATH, {
    email: payload
  })
}

export const postResetPassword = async (payload) => {
  return await http.post(RESET_PASSWORD_PATH, {
    token: payload.token,
    password: payload.password
  })
}

export const refreshingToken = async (accessToken, refreshToken) => {
  return await http.post(REFRESH_TOKEN, { accessToken, refreshToken })
}

export const changePassword = async (user) => {
  return await http.put(CHANGE_PASSWORD, user);
}

// Gets the logged in user data from local session
export function getLoggedInUser() {
  const user = Storage.get(["authToken", "refreshToken", "userId"]);
  if (user && Object.keys(user).length > 0) return user;
  return null;
};

//is user is logged in
export function isUserAuthenticated() {
  return getLoggedInUser() !== null;
};

/////////////////////////////////////////////////////////////////////

//Beneficiars
export const getAllBeneficiariars = async (payload) => {
  if (Object.keys(payload).length > 0) {
    const params = {
      FromDate: payload.filter?.FromDate || "",
      ToDate: payload.filter?.ToDate || "",
      EquipmentDeliveredFromDate: payload.filter?.equipmentDeliveredFromDate || "",
      EquipmentDeliveredToDate: payload.filter?.equipmentDeliveredToDate || "",
      Fin: payload.filter?.fin || "",
      CompanyTraining: payload.filter?.companyTraining || "",
      FollowerId: payload.filter?.followerId || "",
      FullName: payload.filter?.fullName || "",
      hasVoen: payload.filter?.hasVoen || "",
      IsDisabled: payload.filter?.isDisabled || "",
      IsEquipmentConfigured: payload.filter?.isEquipmentConfigured || "",
      IsEquipmentNotConfigured: payload.filter?.isEquipmentNotConfigured || "",
      IsEquipmentValid: payload.filter?.IsEquipmentValid || "",
      IsMemberOfMartyrFamily: payload.filter?.isMemberOfMartyrFamily || "",
      IsVeteran: payload.filter?.isVeteran || "",
      ObservationId: payload.filter?.observationId || "",
      BusinessId: payload.filter?.BusinessId || "",
      NonObservationId: payload.filter?.nonObservationId || "",
      RegionId: payload.filter?.Regions || "",
      // JoinProfessionTrainings: payload.filter?.JoinProfessionTrainings,
      JoinMentor: payload.filter?.JoinMentor || "",
      Gender: payload.filter?.Gender || "",
      IDP: payload.filter?.IDP || "",
      UDSY: payload.filter?.UDSY || "",
      IgnoredId: payload.filter?.IgnoredId || "",
      PageNumber: payload?.pageIndex || 1,
      PageSize: payload?.pageSize || 10,
    }

    return await http.get(GET_ALL_BENEFICIARS + createParamString(params))
  } else return await http.get(GET_ALL_BENEFICIARS)

}

export const getAttachedBeneficiariars = async (payload) => {
  if (payload && Object.keys(payload).length > 0) {
    return await http.get(GET_ATTACHED_BENEFICIARIES, {
      params: {
        FullName: payload.fullName,
        Fin: payload.fin,
        ObservationId: payload.observationId,
        NonObservationId: payload.nonObservationId,
        EquipmentDeliveredFromDate: payload.equipmentDeliveredFromDate,
        EquipmentDeliveredToDate: payload.equipmentDeliveredToDate,
        PageNumber: payload?.pageIndex,
        PageSize: payload?.pageSize
      }
    })
  } else return await http.get(GET_ATTACHED_BENEFICIARIES)
}

export const getObservationQuestions = async (payload) => {
  return await http.get(GET_OBSERVATION_QUESTIONS, {
    params: {
      BeneficiaryId: payload?.BeneficiaryId || payload?.beneficiarId,
      ObservationId: payload?.ObservationId || payload?.observationId,
      isEquipment: payload?.isEquipment,
      PageNumber: payload?.pageIndex || payload.pagination.pageIndex + 1,
      PageSize: payload?.pageSize || payload.pagination.pageSize
    }
  })
}

export const postQuestionAnswer = async (payload) => {
  return await http.post(POST_QUESTION_ANSWER, payload)
}
export const postEquipment = async (payload) => {
  return await http.post(POST_EQUIPMENT, payload)
}

export const putUpdateAnsweredQuestion = async (payload) => {
  return await http.put(PUT_UPDATE_ANSWERED_QUESTION, payload)
}

export const postBeneficiar = async (payload) => {
  return await http.post(POST_BENEFICIAR, payload)
}

export const putSingleObservationNewDate = async (payload) => {
  return await http.put(PUT_OBSERVATION_DATE, payload)
}

export const deleteSingleObservation = async (payload) => {
  return await http.delete(DELETE_OBSERVATION, { data: payload })
}

export const postBeneficiarImages = async (payload) => {
  return await http.post(POST_BENEFICIAR_IMAGES, payload)
}

export const getExportBeneficiariars = async (payload) => {
  if (Object.keys(payload).length > 0) {
    return await http.get(GET_EXPORT, {
      responseType: 'arraybuffer',
      params: {
        FromDate: payload?.FromDate || "",
        ToDate: payload?.ToDate || "",
        EquipmentDeliveredFromDate: payload.filter?.equipmentDeliveredFromDate || "",
        EquipmentDeliveredToDate: payload.filter?.equipmentDeliveredToDate || "",
        Fin: payload?.fin || "",
        FollowerId: payload?.followerId || "",
        FullName: payload?.fullName || "",
        hasVoen: payload?.hasVoen || "",
        IsDisabled: payload?.isDisabled || "",
        IsEquipmentConfigured: payload?.isEquipmentConfigured || "",
        IsEquipmentNotConfigured: payload?.isEquipmentNotConfigured || "",
        IsEquipmentValid: payload?.IsEquipmentValid || "",
        IsMemberOfMartyrFamily: payload?.isMemberOfMartyrFamily || "",
        IsVeteran: payload?.isVeteran || "",
        ObservationId: payload?.observationId || "",
        PageNumber: payload?.pageIndex || "",
        PageSize: payload?.pageSize || "",
        BusinessId: payload.BusinessId || "",
        NonObservationId: payload.nonObservationId || "",
        RegionId: payload?.Regions || "",
        // JoinProfessionTrainings: payload.JoinProfessionTrainings || "",
        JoinMentor: payload?.JoinMentor || "",
        Gender: payload?.Gender || "",
        IDP: payload?.IDP || "",
        UDSY: payload?.UDSY || "",

      }
    })
  } else return await http.get(GET_EXPORT, { responseType: 'arraybuffer', });
}

export const getExportBeneficiariarsData = async (payload) => {
  console.log("payload: ",payload)
  
  if (Object.keys(payload).length > 0) {
    const params = {
      FromDate: payload?.FromDate || "",
      ToDate: payload?.ToDate || "",
      EquipmentDeliveredFromDate: payload?.equipmentDeliveredFromDate || "",
      EquipmentDeliveredToDate: payload?.equipmentDeliveredToDate || "",
      Fin: payload?.fin || "",
      CompanyTraining: payload?.companyTraining || "",
      FollowerId: payload?.followerId || "",
      FullName: payload?.fullName || "",
      hasVoen: payload?.hasVoen || "",
      IsDisabled: payload?.isDisabled || "",
      IsEquipmentConfigured: payload?.isEquipmentConfigured || "",
      IsEquipmentNotConfigured: payload?.isEquipmentNotConfigured || "",
      IsEquipmentValid: payload?.IsEquipmentValid || "",
      IsMemberOfMartyrFamily: payload?.isMemberOfMartyrFamily || "",
      IsVeteran: payload?.isVeteran || "",
      ObservationId: payload?.observationId || "",
      BusinessId: payload?.BusinessId || "",
      NonObservationId: payload?.nonObservationId || "",
      RegionId: payload?.Regions || "",
      // JoinProfessionTrainings: payload?.JoinProfessionTrainings || "",
      JoinMentor: payload?.JoinMentor || "",
      Gender: payload?.Gender || "",
      IDP: payload?.IDP || "",
      UDSY: payload?.UDSY || "",
      IgnoredId: payload?.IgnoredId || "",
    }
    return await http.get(GET_EXPORT_DATA + createParamString(params))
  } else return await http.get(GET_EXPORT_DATA);
}

export const getExportBeneficiariarsAnswerData = async (payload) => {
  if (Object.keys(payload).length > 0) {
    const params = {
      FromDate: payload?.FromDate || "",
      ToDate: payload?.ToDate || "",
      EquipmentDeliveredFromDate: payload?.filter?.equipmentDeliveredFromDate || "",
      EquipmentDeliveredToDate: payload?.filter?.equipmentDeliveredToDate || "",
      Fin: payload?.fin || "",
      CompanyTraining: payload?.companyTraining || "",
      FollowerId: payload?.followerId || "",
      FullName: payload?.fullName || "",
      hasVoen: payload?.hasVoen || "",
      IsDisabled: payload?.isDisabled || "",
      IsEquipmentConfigured: payload?.isEquipmentConfigured,
      IsEquipmentNotConfigured: payload?.isEquipmentNotConfigured,
      IsEquipmentValid: payload?.IsEquipmentValid,
      IsMemberOfMartyrFamily: payload?.isMemberOfMartyrFamily || "",
      IsVeteran: payload?.isVeteran || "",
      ObservationId: payload?.observationId || "",
      BusinessId: payload?.BusinessId || "",
      NonObservationId: payload?.nonObservationId || "",
      RegionId: payload?.Regions || "",
      // JoinProfessionTrainings: payload?.JoinProfessionTrainings || "",
      JoinMentor: payload?.JoinMentor || "",
      Gender: payload?.filter?.Gender || "",
      IDP: payload?.filter?.IDP || "",
      UDSY: payload?.filter?.UDSY || "",
      IgnoredId: payload?.filter?.IgnoredId || "",
    }
    return await http.get(GET_EXPORT_ANSWER_DATA + createParamString(params))
  } else return await http.get(GET_EXPORT_ANSWER_DATA);
}

export const getExportMissingEquipments = async () => {
  return await http.get(GET_MISSING_EQUIPMENTS)
}



export const postImportBeneficiaries = async (payload) => {
  return await http.post(POST_IMPORT, payload)
}

export const getBeneficiariarImages = async (payload) => {
  return await http.get(`${GET_BENEFICIAR_IMAGES}/${payload}/images`)
}

export const deleteBeneficiariarImage = async (payload) => {
  return await http.post(DELETE_BENEFICIAR_IMAGE, payload)
}

//Followers 
export const getAllFollowers = async (payload) => {
  return await http.get(GET_ALL_FOLLOWERS, payload)
}

export const getAllDetailedFollowers = async (payload) => {
  return await http.get(GET_FOLLOWER_DETAILS, {
    params: {
      FullName: payload?.FullName,
      ContractDate: payload?.ContractDate,
      ContractEndDate: payload?.ContractEndDate,
      RegionId: payload?.RegionId,
      ObservationFromDate: payload?.FromDate,
      ObservationToDate: payload?.ToDate,
      PageNumber: payload?.pageIndex,
      PageSize: payload?.pageSize
    }
  })
}

export const postFollowerContract = async (payload) => {
  return await http.post(POST_FOLLOWER_DETAIL, payload)
}

export const putFollowerContract = async (payload) => {
  return await http.put(PUT_FOLLOWER_DETAIL, payload)
}

export const postCompleteConservation = async (payload) => {
  return await http.post(POST_COMPLETE_OBSERVATION, payload)
}

//Businesses
export const getBusinessOptions = async () => {
  return await http.get(GET_BUSINESS_OPTIONS)
}

export const postAttachFollowers = async (payload) => {
  return await http.post(POST_ATTACH_FOLLOWER, payload)
}

//Users
export const getAllUsers = async () => {
  return await http.get(GET_USERS)
}

export const getQuestionAndAnswers = async (payload) => {
  if (Object.keys(payload).length > 0) {
    const params = {
      FromDate: payload.filter?.FromDate || "",
      ToDate: payload.filter?.ToDate || "",
      EquipmentDeliveredDate: payload.filter?.equipmentDeliveredDate || "",
      Fin: payload.filter?.fin || "",
      CompanyTraining: payload.filter?.companyTraining || "",
      FollowerId: payload.filter?.followerId || "",
      FullName: payload.filter?.fullName || "",
      hasVoen: payload.filter?.hasVoen || "",
      IsDisabled: payload.filter?.isDisabled || "",
      IsEquipmentConfigured: payload.filter?.isEquipmentConfigured || "",
      IsEquipmentNotConfigured: payload.filter?.isEquipmentNotConfigured || "",
      IsEquipmentValid: payload.filter?.IsEquipmentValid || "",
      IsMemberOfMartyrFamily: payload.filter?.isMemberOfMartyrFamily || "",
      IsVeteran: payload.filter?.isVeteran || "",
      ObservationId: payload.filter?.observationId || "",
      BusinessId: payload.filter?.BusinessId || "",
      NonObservationId: payload.filter?.nonObservationId || "",
      RegionId: payload.filter?.Regions,
      // JoinProfessionTrainings: payload.JoinProfessionTrainings || "",
      JoinMentor: payload?.JoinMentor || "",
      Gender: payload.filter?.Gender || "",
      IDP: payload.filter?.IDP || "",
      UDSY: payload.filter?.UDSY || "",
      PageNumber: payload?.pageIndex || "",
      PageSize: payload?.pageSize || "",
      IgnoredId: payload?.IgnoredId || "",
    }
    return await http.get(GET_QUESTION_AND_ANSWERS + createParamString(params))
  } else return await http.get(GET_QUESTION_AND_ANSWERS);
}

//Stats
export const getCommonStats = async (payload) => {
  if (Object.keys(payload).length > 0) {
    const params = {
      FromDate: payload?.FromDate || "",
      ToDate: payload?.ToDate || "",
      EquipmentDeliveredFromDate: payload?.equipmentDeliveredFromDate || "",
      EquipmentDeliveredToDate: payload?.equipmentDeliveredToDate || "",
      Fin: payload?.fin || "",
      CompanyTraining: payload?.companyTraining || "ALL",
      FollowerId: payload?.followerId || "",
      FullName: payload?.fullName || "",
      hasVoen: payload?.hasVoen || "",
      IsDisabled: payload?.isDisabled || "",
      IsEquipmentConfigured: payload?.isEquipmentConfigured || "",
      IsEquipmentNotConfigured: payload?.isEquipmentNotConfigured || "",
      IsEquipmentValid: payload?.IsEquipmentValid || "",
      IsMemberOfMartyrFamily: payload?.isMemberOfMartyrFamily || "",
      IsVeteran: payload?.isVeteran || "",
      ObservationId: payload?.observationId || "",
      BusinessId: payload?.BusinessId || "",
      NonObservationId: payload?.nonObservationId || "",
      RegionId: payload?.Regions || "",
      // JoinProfessionTrainings: payload.JoinProfessionTrainings,
      JoinMentor: payload.JoinMentor || "",
      Gender: payload?.Gender || "",
      IDP: payload?.IDP || "",
      UDSY: payload?.UDSY || "",
      IgnoredId: payload?.IgnoredId || "",
    }
    return await http.get(GET_COMMON_STATS + createParamString(params))
  } else return await http.get(GET_COMMON_STATS)
}

export const getCommonStatsLight = async () => {
  return await http.get(GET_COMMON_STATS_LIGHT)
}

export const getObservationsCount = async (payload) => {
  if (Object.keys(payload).length > 0) {
    const params = {
      FromDate: payload?.FromDate || "",
      ToDate: payload?.ToDate || "",
      EquipmentDeliveredFromDate: payload?.equipmentDeliveredFromDate || "",
      EquipmentDeliveredToDate: payload?.equipmentDeliveredToDate || "",
      Fin: payload?.fin || "",
      CompanyTraining: payload?.companyTraining || "ALL",
      FollowerId: payload?.followerId || "",
      FullName: payload?.fullName || "",
      hasVoen: payload?.hasVoen || "",
      IsDisabled: payload?.isDisabled || "",
      IsEquipmentConfigured: payload?.isEquipmentConfigured || "",
      IsEquipmentNotConfigured: payload?.isEquipmentNotConfigured || "",
      IsEquipmentValid: payload?.IsEquipmentValid || "",
      IsMemberOfMartyrFamily: payload?.isMemberOfMartyrFamily || "",
      IsVeteran: payload?.isVeteran || "",
      ObservationId: payload?.observationId || "",
      BusinessId: payload?.BusinessId || "",
      NonObservationId: payload?.nonObservationId || "",
      RegionId: payload?.Regions || "",
      // JoinProfessionTrainings: payload.JoinProfessionTrainings,
      JoinMentor: payload.JoinMentor || "",
      Gender: payload?.Gender || "",
      IDP: payload?.IDP || "",
      UDSY: payload?.UDSY || "",
      IgnoredId: payload?.IgnoredId || "",
    }
    return await http.get(GET_OBSERVATION_COUNTS + createParamString(params))
  }
}

//Questions 
export const getQuestions = async (payload) => {
  return await http.get(GET_QUESTIONS, {
    params: {
      content: payload?.question,
      ObservationId: payload?.observation || undefined,
      PageNumber: payload?.pageIndex,
      PageSize: payload?.pageSize
    }
  })
}

export const postQuestion = async (payload) => {
  if (payload.id === 0)
    return await http.post(POST_QUESTION, payload)
  else
    return await http.put(POST_QUESTION, payload)
}

export const deleteQuestion = async (payload) => {
  return await http.put(`${DELETE_QUESTION}/${payload}`)
}

export const getAnswerTypes = async () => {
  return await http.get(GET_ANSWERTYPES)
}


//Users
export const getUsers = async (payload) => {
  return await http.get(GET_USERS, {
    params: {
      fullname: payload?.fullname,
      userName: payload?.username,
      role: payload?.role,
      PageNumber: payload?.pageIndex,
      PageSize: payload?.pageSize
    }
  })
}

export const postUser = async (payload) => {
  if (payload.Id === 0)
    return await http.post(POST_USER, payload)
  else
    return await http.put(PUT_USER, payload)
}

export const deleteUser = async (payload) => {
  return await http.put(`${DELETE_USER}/${payload}`)
}

export const changePasswordAsAdmin = async (payload) => {
  return await http.post(RESET_PASSWORD_AS_ADMIN_PATH, {
    userId: payload.userId,
    newPassword: payload.newPassword,
    reNewPassword: payload.reNewPassword
  })
}

export const getRegions = async () => {
  return await http.get(GET_REGION_OPTIONS)
}

export const getBusinesses = async () => {
  return await http.get(GET_BUSINESSES)
}

export const deleteBeneficiar = async (payload) => {
  return await http.put(`${PUT_DELETE_BENEFICIAR}/${payload}`)
}

export const postBusinessEquipment = async (payload = {}) => {
  const equipmentBody = {
    equipmentId: payload?.equipmentId,
    businessId: payload?.businessId,
    name: payload?.name,
    active: payload?.active,
  }
  return await http.post(POST_BUSINESS_EQUIPMENT, equipmentBody)
}

export const deleteBusiness = async (id) => {
  return await http.put(`${PUT_BUSINESS_BY_ID}/${id}`)
}