import React, { useState } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  CardHeader,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  h4,
  CardSubtitle,
} from "reactstrap"
// import "./Filter.scss"
import { useSelector, useDispatch } from "react-redux"
import { requestUsers } from "store/actions"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import { useEffect } from "react"

const Roles = [
  {
    value: "",
    title: "Bütün Müşahidəçilər"
  },
  {
    value: "ROLE_FOLLOWER",
    title: "Müşahidəçi"
  },
  {
    value: "ROLE_ADMIN",
    title: "Admin"
  },
  {
    value: "ROLE_REPORT",
    title: "Reporter"
  },
  {
    value: "ROLE_MANAGER",
    title: "Manager"
  }
]


const Filter = ({ setFilters }) => {
  const dispatch = useDispatch();

  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,
    initialValues: {
      fullname: '',
      username: '',
      role: '',
    },
    onSubmit: values => {
      // console.table(values)
      setFilters(values)
      // dispatch(requestUsers(values));
    },
  })



 
  const RoleDropdownItems = () => {
    return (
      Roles.map((role) => {
        return (
          <option key={role.title} value={role.value}>{role.title}</option>
        )
      })
    )
  }

  return (
    <React.Fragment>
      <Card className="">
        <CardBody className="">
          <Row>
            <Col>
              <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                <Form
                  className="filter form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    form.handleSubmit()
                    return false
                  }}
                >

                  <Row className="primary-menu ">
                    <Col className="col-4">
                      <Label className="form-label"> Ad Soyad </Label>
                      <Input
                        name="fullname"
                        className="form-control"
                        placeholder=""
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.fullname || ""}
                        invalid={
                          form.touched.fullname && form.errors.fullname
                            ? true
                            : false
                        }
                      />
                    </Col>
                    <Col className="col-4">
                      <Label className="form-label"> İstifadəçi adı </Label>
                      <Input
                        name="username"
                        className="form-control"
                        placeholder=""
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.username || ""}
                        invalid={
                          form.touched.username && form.errors.username
                            ? true
                            : false
                        }
                      />
                    </Col>

                    <Col className="col-4">
                      <Label className="form-label"> Rol </Label>
                      <Input
                        type="select"
                        name="role"
                        className="form-control"
                        placeholder=""
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.role || ""}
                        invalid={
                          form.touched.role && form.errors.role
                            ? true
                            : false
                        }
                      >

                        {RoleDropdownItems()}
                      </Input>
                    </Col>
                  </Row>
                  <div className="d-flex filter-icon-wrapper justify-content-end">
                    <Button className="bg-primary">Axtar</Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default Filter