import React, { useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import { REQUEST_QUESTIONS } from "store/questions/actionTypes"

import Filter from "./Filter"
import EnhancedTable from "components/Common/EnhancedTable"
import { questionsQolumns } from "./QuestionsTable/columns"
import QuestionForm from "./QuestionForm"
import {
  requestAnswerTypes,
  requestDeleteQuestion,
  requestQestions,
} from "store/questions/actions"
import {
  selectQuestions,
  selectLoadingQuestions,
  selectAnswersTypes,
  selectQuestionsPostResult,
  selectQuestionsDeleteResult,
} from "selectors/QuestionSelectors"
import { useEffect } from "react"
import DeleteModal from "components/Common/DeleteModal"

//meta title

const Questions = props => {
  document.title = "Suallar | Self Employment Project"
  const isLoading = useSelector(selectLoadingQuestions)
  const selectedQuestions = useSelector(selectQuestions)
  const answerTypes = useSelector(selectAnswersTypes)
  const postQuestionResult = useSelector(selectQuestionsPostResult)
  const deleteQuestionResult = useSelector(selectQuestionsDeleteResult)
  const [filters, setFilters] = useState({})
  const [questionModal, setQuestionModal] = useState(false)
  const [questionFormMode, setQuestionFormMode] = useState("")
  const [selectedQuestion, setSelectedQuestion] = useState(null)
  const [addQuestionParentId, SetAddQuestionParentId] = useState(null)
  const [deleteQuestion, setDeleteQuestion] = useState(null)
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const dispatch = useDispatch()

  function handleEditQuestion(questionData) {
    setQuestionModal(true)
    setQuestionFormMode("edit")
    setSelectedQuestion(questionData)
    SetAddQuestionParentId(null)
  }

  function handleDeleteQuestion() {
    dispatch(requestDeleteQuestion(deleteQuestion))
  }

  function handleAddQuestion(parentQuestionId) {
    setQuestionModal(true)
    setQuestionFormMode("add")
    setSelectedQuestion({})
    SetAddQuestionParentId(parentQuestionId)
  }

  function handleToggleQuestionFormModal() {
    setQuestionModal(prevOpen => !prevOpen)
  }

  const tableColumns = useMemo(
    () => [
      ...questionsQolumns,
      {
        header: "Action",
        accessorKey: "action",
        cell: cellProps => {
          return (
            <div className="d-flex justify-content-center">
              <a
                className="text-success"
                href="#"
                // onClick={() => beneficiarSetter(row.original.beneficiarId)}
                onClick={() => {
                  const questionData = cellProps.row.original
                  handleEditQuestion(questionData)
                }}
              >
                <i
                  id={`edittooltip`}
                  className="mdi mdi-pencil font-size-18"
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    padding: "15px",
                  }}
                ></i>
                <UncontrolledTooltip placement="top" target={`edittooltip`}>
                  Edit
                </UncontrolledTooltip>
              </a>
              <a className="text-danger">
                <i
                  onClick={() => setDeleteQuestion(cellProps.row.original.id)}
                  id={`deletetooltip`}
                  className="mdi mdi-delete font-size-18"
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    padding: "15px",
                  }}
                ></i>
                <UncontrolledTooltip placement="top" target={`deletetooltip`}>
                  Delete
                </UncontrolledTooltip>
              </a>

              {!cellProps.row.parentId ? (
                <a className="text-primary">
                  <i
                    onClick={() => handleAddQuestion(cellProps.row.original.id)}
                    id={`addSubQuestion${cellProps.row.id}`}
                    className="mdi mdi-plus-circle font-size-18"
                    style={{
                      fontSize: "24px",
                      cursor: "pointer",
                      padding: "15px",
                    }}
                  ></i>
                  <UncontrolledTooltip placement="top" target={`addSubQuestion${cellProps.row.id}`}>
                    Alt sual əlavə et
                  </UncontrolledTooltip>
                </a>
              ) : (
                <div style={{ paddingRight: "48px" }} />
              )}
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    if (postQuestionResult && postQuestionResult?.success)
      setQuestionModal(false)
  }, [postQuestionResult])

  useEffect(() => {
    if (deleteQuestionResult && deleteQuestionResult?.success)
      setDeleteQuestion(null)
  }, [deleteQuestionResult])

  useEffect(() => {
    if (!Object.keys(answerTypes).length > 0) dispatch(requestAnswerTypes())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      requestQestions({
        ...filters,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      })
    )
  }, [filters, pagination, dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Questions")}
            breadcrumbItem={props.t("Suallar")}
          />
          <Row>
            <Col>
              <Filter setFilters={setFilters} />
            </Col>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-end m-3 ">
                  <a
                    className="btn btn-primary"
                    onClick={() => handleAddQuestion(0)}
                  >
                    Yeni sual
                  </a>
                </div>
                <EnhancedTable
                  onPaginationChange={setPagination}
                  pagination={pagination}
                  manualPagination
                  DataCount={selectedQuestions.count}
                  data={selectedQuestions.list}
                  columns={tableColumns}
                  isLoading={isLoading}
                />
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
      <Modal
        size="lg"
        isOpen={questionModal}
        toggle={handleToggleQuestionFormModal}
      >
        <ModalHeader toggle={handleToggleQuestionFormModal} tag="h4">
          {questionFormMode === "edit" ? "Sualın redaktə edilməsi" : "Yeni Sual"}
        </ModalHeader>
        <ModalBody>
          <QuestionForm
            question={selectedQuestion}
            parentQuestionId={addQuestionParentId}
            answerTypes={answerTypes}
            mode={questionFormMode}
          />
        </ModalBody>
      </Modal>
      <DeleteModal
        show={Boolean(deleteQuestion)}
        text={{
          title: `Silmək istədiyinizə əminsiniz ?`,
        }}
        onDeleteClick={handleDeleteQuestion}
        onCloseClick={() => setDeleteQuestion(null)}
      />
    </React.Fragment>
  )
}

export default withTranslation()(Questions)
