
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Col, Form, FormFeedback, Input, Label, Row, UncontrolledTooltip } from "reactstrap"
import { requestPostBeneficiar } from "store/actions";
import { useFormik } from "formik"
import formatDate from "helpers/format-date";
import * as Yup from "yup"
import { selectLoadingPostBeneficiar } from "selectors/BeneficiarisSelectors";

const Roles = [
  {
    value: "ROLE_ADMIN",
    label: "Admin"
  },
  {
    value: "ROLE_MANAGER",
    label: "Menecer"
  },
  {
    value: "ROLE_REPORT",
    label: "Reporter"
  },
  {
    value: "ROLE_FOLLOWER",
    label: "Müşahidəçi"
  }
]


export default function BeneficiarForm({ handleAddOrUpdate, beneficiar = {}, regions = [], businesses = [], mode = "" }) {
  const dispatch = useDispatch();
  const isLoadingPostBeneficiar = useSelector(selectLoadingPostBeneficiar);


  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      beneficiarId: beneficiar.beneficiarId || 0,
      fullName: beneficiar.fullName,
      fin: beneficiar.fin || "",
      address: beneficiar.address || "",
      groupCode: beneficiar.groupCode || "",
      trainingType: beneficiar.trainingType || "",
      regionId: beneficiar.regionId || "",
      contactNumbers: beneficiar.contactNumbers || "",
      specialServices: beneficiar.specialServices || "",
      deliveryDate: formatDate(beneficiar.equipmentDeliveryDate) || "",
      assessmentDate: formatDate(beneficiar.assestmentDate) || "",
      businessId: beneficiar.businessId || "",
      udsy: beneficiar.udsy || false,
      idp: beneficiar.idp || false,
      disabled: beneficiar.disabled || "",
      gender: beneficiar?.gender?.toLowerCase() || "m",
      status: beneficiar.status || 0
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("Ad Soyad daxil edilməlidir"),
      fin: Yup.string().min(7, 'FIN 7 karakterdən az olmamalıdır').max(7, 'FIN 7 karakterdən çox olmamalıdır').required("FIN daxil edilməlidir"),
      address: Yup.string().required("Ünvan daxil edilməlidir"),
      groupCode: Yup.string().required("Qrup kodu daxil edilməlidir"),
      trainingType: Yup.string().required("Şirkət təlimləri daxil edilməlidir"),
      contactNumbers: Yup.string().required("Əlaqə nömrələri daxil edilməlidir"),
      specialServices: Yup.string().required("Xüsusi xidmətlər daxil edilməlidir"),
      assessmentDate: Yup.date().required("Tarix seçin"),
      disabled: Yup.string().required("Əlillik daxil edilməlidir"),
      regionId: Yup.number().required("Region seçin"),
      deliveryDate: Yup.date().required("Tarix seçin"),
      businessId: Yup.number().required("Biznes seçin"),
    }),
    onSubmit: values => {
      const formattedBeneficiar = {
        beneficiarId: values.beneficiarId,
        fullName: values.fullName,
        fin: values.fin,
        address: values.address,
        groupCode: values.groupCode,
        trainingType: values.trainingType,
        regionId: values.regionId,
        contactNumbers: values.contactNumbers,
        specialServices: values.specialServices,
        deliveryDate: values.deliveryDate,
        assessmentDate: values.assessmentDate,
        businessId: values.businessId,
        udsy: values.udsy,
        idp: values.idp,
        disabled: values.disabled,
        gender: values?.gender?.toLowerCase(),
        status: values.status
      }
      handleAddOrUpdate(values);
    }
  })

  return (
    <Form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault()
        form.handleSubmit()
        return false
      }}
    >
      <Input hidden autoComplete="false" />
      <Row>

        {/* fullName */}
        <Col className="col-12 col-lg-8">
          <div className="mb-3">
            <Label className="form-label">Ad Soyad</Label>
            <Input
              name="fullName"
              type="text"
              placeholder="Ad Soyadı daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.fullName || ""}
              invalid={
                form.touched.fullName && form.errors.fullName
                  ? true
                  : false
              }
            />
            {form.touched.fullName && form.errors.fullName ? (
              <FormFeedback type="invalid">
                {form.errors.fullName}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* Fin */}
        <Col className="col-12 col-lg-4">
          <div className="mb-3">
            <Label className="form-label" id="fin">FIN</Label>
            <Input
              name="fin"
              type="text"
              placeholder="FIN i daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.fin || ""}
              invalid={
                form.touched.fin && form.errors.fin
                  ? true
                  : false
              }
            />
            {form.touched.fin && form.errors.fin ? (
              <FormFeedback type="invalid">
                {form.errors.fin}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* address */}
        <Col className="col-12 col-lg-8">
          <div className="mb-3">
            <Label className="form-label">Ünvan</Label>
            <Input
              name="address"
              type="textarea"
              rows={3}
              placeholder="Ünvanı daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.address || ""}
              invalid={
                form.touched.address && form.errors.address
                  ? true
                  : false
              }
            />
            {form.touched.address && form.errors.address ? (
              <FormFeedback type="invalid">
                {form.errors.address}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* disabled */}
        <Col className="col-12 col-lg-4">
          <div className="mb-3">
            <Label className="form-label">Əlillik</Label>
            <Input
              name="disabled"
              type="text"
              placeholder="Enter Disablity"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.disabled || ""}
              invalid={
                form.touched.disabled && form.errors.disabled
                  ? true
                  : false
              }
            />
            {form.touched.disabled && form.errors.disabled ? (
              <FormFeedback type="invalid">
                {form.errors.disabled}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* trainingType */}
        <Col className="col-12 col-lg-8">
          <div className="mb-3">
            <Label className="form-label">Şirkət təlimləri</Label>
            <Input
              name="trainingType"
              type="text"
              placeholder="Şirkət təlimlərini daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.trainingType || ""}
              invalid={
                form.touched.trainingType && form.errors.trainingType
                  ? true
                  : false
              }
            />
            {form.touched.trainingType && form.errors.trainingType ? (
              <FormFeedback type="invalid">
                {form.errors.trainingType}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* groupCode */}
        <Col className="col-12 col-lg-4">
          <div className="mb-3">
            <Label className="form-label">Qrup kodu</Label>
            <Input
              name="groupCode"
              type="text"
              placeholder="Qrup kodunu daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.groupCode || ""}
              invalid={
                form.touched.groupCode && form.errors.groupCode
                  ? true
                  : false
              }
            />
            {form.touched.groupCode && form.errors.groupCode ? (
              <FormFeedback type="invalid">
                {form.errors.groupCode}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* specialServices */}
        <Col className="col-12 col-lg-8">
          <div className="mb-3">
            <Label className="form-label">Xüsusi xidmətlər</Label>
            <Input
              name="specialServices"
              type="text"
              placeholder="Xüsusi xidmətlər daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.specialServices || ""}
              invalid={
                form.touched.specialServices && form.errors.specialServices
                  ? true
                  : false
              }
            />
            {form.touched.specialServices && form.errors.specialServices ? (
              <FormFeedback type="invalid">
                {form.errors.specialServices}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* contactNumbers */}
        <Col className="col-12 col-lg-4">
          <div className="mb-3">
            <Label className="form-label">Əlaqə nömrələri</Label>
            <Input
              name="contactNumbers"
              type="text"
              placeholder="Əlaqə nömrələrini daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.contactNumbers || ""}
              invalid={
                form.touched.contactNumbers && form.errors.contactNumbers
                  ? true
                  : false
              }
            />
            {form.touched.contactNumbers && form.errors.contactNumbers ? (
              <FormFeedback type="invalid">
                {form.errors.contactNumbers}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* deliveryDate */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Ləvazimatların çatdırılma tarixi</Label>
            <Input
              name="deliveryDate"
              type="date"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.deliveryDate}
              invalid={
                form.touched.deliveryDate && form.errors.deliveryDate
                  ? true
                  : false
              }
            />
            {form.touched.deliveryDate && form.errors.deliveryDate ? (
              <FormFeedback type="invalid">
                {form.errors.deliveryDate}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* assessmentDate */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Qiymətləndirmə tarixi</Label>
            <Input
              name="assessmentDate"
              type="date"
              placeholder="Enter assessmentDate"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.assessmentDate}
              invalid={
                form.touched.assessmentDate && form.errors.assessmentDate
                  ? true
                  : false
              }
            />
            {form.touched.assessmentDate && form.errors.assessmentDate ? (
              <FormFeedback type="invalid">
                {form.errors.assessmentDate}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* regionId */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Rayon</Label>
            <Input
              name="regionId"
              type="select"
              placeholder="Select Region"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.regionId || ""}
              invalid={
                form.touched.regionId && form.errors.regionId
                  ? true
                  : false
              } >
              <option value=''>Rayon seçin</option>
              {regions.map((region) => {
                return (
                  <option key={region.id} value={region.id}>{region.name}</option>
                )
              })}
            </Input>
            {form.touched.regionId && form.errors.regionId ? (
              <FormFeedback type="invalid">
                {form.errors.regionId}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* businessId  */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Biznes</Label>
            <Input
              name="businessId"
              type="select"
              placeholder="Select Region"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.businessId || ""}
              invalid={
                form.touched.businessId && form.errors.businessId
                  ? true
                  : false
              } >
              <option value=''>Biznes seçin</option>
              {businesses.map((business) => {

                return (
                  <option key={business.id} value={business.id}>{business.name}</option>
                )
              })}
            </Input>
            {form.touched.businessId && form.errors.businessId ? (
              <FormFeedback type="invalid">
                {form.errors.businessId}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* gender */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Cins</Label>
            <Input
              name="gender"
              type="select"
              placeholder="Enter gender"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.gender || ""}
              invalid={
                form.touched.gender && form.errors.gender
                  ? true
                  : false
              }
            >
              <option value='m'>Kişi</option>
              <option value='f'>Qadın</option>
            </Input>

            {form.touched.gender && form.errors.gender ? (
              <FormFeedback type="invalid">
                {form.errors.gender}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* status */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Status</Label>
            <Input
              name="status"
              type="select"
              placeholder="Enter status"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.status}
              invalid={
                form.touched.status && form.errors.status
                  ? true
                  : false
              }
            >
              <option value={1}>Aktiv</option>
              <option value={0}>Deaktiv</option>
            </Input>

            {form.touched.status && form.errors.status ? (
              <FormFeedback type="invalid">
                {form.errors.status}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* udsy */}
        <Col className="col-12 col-lg-6 mt-2">
          <Button
            name="udsy"
            className="form-control"
            color="primary"
            outline={Boolean(!form.values.udsy)}
            onClick={(e) => {
              e.preventDefault()
              form.setFieldValue("udsy", !form.values.udsy)
            }}
            active={form.values.udsy}
          >
            ÜDSY
          </Button>
        </Col>

        {/* idp */}
        <Col className="col-12 col-lg-6 mt-2">
          <Button
            name="idp"
            className="form-control"
            color="primary"
            outline={Boolean(!form.values.idp)}
            onClick={(e) => {
              e.preventDefault()
              form.setFieldValue("idp", !form.values.idp)
            }}
            active={form.values.idp}
          >
            Məcburi köçkün
          </Button>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <div className="text-end">
            <Button type="submit" className="btn btn-success save-beneficiar" disabled={isLoadingPostBeneficiar}>
              {isLoadingPostBeneficiar && (
                <span
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}{" "}
              {mode === "edit" ? "Yaddaşa yaz" : "Əlavə et"}
            </Button>
          </div>
        </Col>
      </Row>
    </Form >
  )
}
