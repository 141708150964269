import { call, put, takeEvery, } from "redux-saga/effects";
import * as FileSaver from "file-saver"
// import { read, utils, writeFile } from 'xlsx';

// Login Redux States
import {
  REQUEST_BENEFICIARS,
  REQUEST_POST_BENEFICIAR,
  REQUEST_POST_BENEFICIAR_FINISHED,
  REQUEST_FOLLOWERS,
  REQUEST_POST_ATTACH_FOLLOWERS,
  REQUEST_POST_ATTACH_FOLLOWERS_FINISHED,
  REQUST_BUSINESS_OPTIONS,
  REQUEST_POST_EXPORT_BENEFICIARIES,
  REQUEST_POST_IMPORT_BENEFICIARIES,
  REQUEST_POST_IMPORT_BENEFICIARIES_FINISHED,
  REQUEST_GET_EXPORT_BENEFICIARIES_DATA,
  REQUEST_DELETE_BENEFICIARS_FINISHED,
  REQUEST_DELETE_BENEFICIARS,
  REQUEST_GET_EXPORT_BENEFICIARIES_ANSWER_DATA,
  REQUEST_PUT_CHANGE_OBSERVATION_DATE,
  REQUEST_PUT_CHANGE_OBSERVATION_DATE_FINISHED,
  REQUEST_DELETE_OBSERVATION_FINISHED,
  REQUEST_DELETE_OBSERVATION,
  REQUEST_EXPORT_MISSING_EQUIPMENTS
} from "./actionTypes";

import {
  requestBeneficiarsFinished,
  requestFollowersFinished,
  requestPostAttachFollowersFinished,
  requestBusinessOptionsFinished,
  requestPostBeneficiarFinished,
  requestExportBeneficiariesFinished,
  requestImportBeneficiariesFinished,
  requestExportBeneficiariesDataFinished,
  requestDeleteBeneficiarFinished,
  requestPutObservationDateFinished,
  requestDeleteObservationFinished,
  requestExportMissingEquipmentFinished
} from "./actions";

import {
  getAllBeneficiariars,
  getAllFollowers,
  postAttachFollowers,
  getBusinessOptions,
  postBeneficiar,
  getExportBeneficiariars,
  postImportBeneficiaries,
  getExportBeneficiariarsData,
  deleteBeneficiar,
  getExportBeneficiariarsAnswerData,
  putSingleObservationNewDate,
  deleteSingleObservation,
  getExportMissingEquipments
} from "services";
import { take } from "lodash";
import { exportToExcel } from "helpers/excel_exporter";

function* fetchBeneficiaries({ error, payload }) {
  if (error) return;
  try {
    const response = yield call(getAllBeneficiariars, payload);

    if (response.status) {
      const { data } = response;
      const beneficiaries = data.data
      yield put(requestBeneficiarsFinished(beneficiaries))
      return
    }

    // success false
    yield put(requestBeneficiarsFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestBeneficiarsFinished(error, true))
  }
}

function* fetchFollowers({ error }) {
  if (error) return;
  try {
    const response = yield call(getAllFollowers);

    if (response.status) {
      const { data } = response;

      const followers = data.data.followers
      yield put(requestFollowersFinished(followers))
      return
    }
    // success false
    yield put(requestFollowersFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestFollowersFinished(error, true))
  }
}

function* postAttachFollower({ payload }) {
  try {
    const response = yield call(postAttachFollowers, payload.followers)
    if (response.data.success) {
      const formattedResponse = {
        ...response.data,
        ...payload.beneficiarFilter
      }
      yield put(
        requestPostAttachFollowersFinished(formattedResponse, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }

    yield put(requestPostAttachFollowersFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestPostAttachFollowersFinished(error, true))
  }
}

function* fetchBusinesses({ error }) {
  if (error) return;
  try {
    const response = yield call(getBusinessOptions);

    if (response.status) {
      const { data } = response;
      yield put(requestBusinessOptionsFinished(data))
      return
    }
    // success false
    yield put(requestBusinessOptionsFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestBusinessOptionsFinished(error, true))
  }
}

function* exportBeneficiaries({ error, payload }) {
  if (error) return;
  try {
    const response = yield call(getExportBeneficiariars, payload);
    if (response.data) {
      var blobData = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blobData, `Beneficiaries_${Date.now()}.xlsx`);
      yield put(
        requestExportBeneficiariesFinished(response.data, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }
    yield put(requestExportBeneficiariesFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestExportBeneficiariesFinished(error, true))
  }
}

function* exportBeneficiariesData({ error, payload, meta }) {
  if (error) return;
  try {
    const response = yield call(getExportBeneficiariarsData, payload);
    if (response.status === 200) {
      const { data } = response;
      const beneficiariesExcelData = data.data
      exportToExcel({ Data: beneficiariesExcelData, fileName: `Benefisiarlar_${Date.now()}`, type: meta?.type });
      yield put(requestExportBeneficiariesDataFinished({ success: true }, false))
      return
    }

    // success false
    yield put(requestExportBeneficiariesDataFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestExportBeneficiariesDataFinished(error, true))
  }
}

function* exportMissingEquipments({ error, payload }) {
  if (error) return;
  try {
    const response = yield call(getExportMissingEquipments, payload);
    if (response.status === 200) {
      const { data } = response;
      const beneficiariesExcelData = data.data
      exportToExcel({ Data: beneficiariesExcelData, fileName: `missing_equipments_${Date.now()}`, type: "equipment" });
      yield put(requestExportMissingEquipmentFinished({ success: true }, false))
      return
    }

    // success false
    yield put(requestExportMissingEquipmentFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestExportMissingEquipmentFinished(error, true))
  }
}

function* exportBeneficiariesAnswerData({ error, payload }) {
  if (error) return;
  try {
    const response = yield call(getExportBeneficiariarsAnswerData, payload);
    if (response.status === 200) {
      const { data } = response;
      const beneficiariesExcelData = data.data
      const heading = [
        [
          "Ad Soyad",
          "FIN",
          "Zərf",
          "M. səfəri",
          "Müşahidəçi",
          "Sual",
          "Qeyd",
          "Cavab",

        ],
      ];
      const columnsWidth = [
        { width: 22 },
        { width: 10 },
        { width: 30 },
        { width: 10 },
        { width: 22 },
        { width: 50 },
        { width: 20 },
        { width: 50 },
      ]
      exportToExcel({ excelData: beneficiariesExcelData, fileName: `Cavablar_${Date.now()}`, heading, columnsWidth });
      yield put(requestExportBeneficiariesDataFinished({ success: true }, false))
      return
    }

    // success false
    yield put(requestExportBeneficiariesDataFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestExportBeneficiariesDataFinished(error, true))
  }
}

function* importBeneficiaries({ error, payload }) {
  if (error) return;
  try {
    const response = yield call(postImportBeneficiaries, payload);
    if (response.data) {
      yield put(
        requestImportBeneficiariesFinished(response.data, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }
    yield put(requestImportBeneficiariesFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestImportBeneficiariesFinished(error, true))
  }
}

function* deleteSingleBeneficiar({ payload }) {
  try {
    const response = yield call(deleteBeneficiar, payload.deleteData)
    if (response.data.success) {
      const formattedResponse = {
        ...response.data,
        ...payload.filter
      }
      yield put(
        requestDeleteBeneficiarFinished(formattedResponse, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }

    yield put(requestDeleteBeneficiarFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestDeleteBeneficiarFinished(error, true))
  }
}

function* postBeneficiars({ payload }) {
  try {
    const response = yield call(postBeneficiar, payload.beneficiar)
    if (response.data.success) {
      const formattedResponse = {
        ...response.data,
        ...payload.filter
      }
      yield put(
        requestPostBeneficiarFinished(formattedResponse, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }

    yield put(requestPostBeneficiarFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestPostBeneficiarFinished(error, true))
  }
}

function* putObservationNewDate({ payload, meta }) {
  try {
    const response = yield call(putSingleObservationNewDate, payload.payload)
    if (response.data.success) {
      const formattedResponse = {
        ...response.data,
        ...meta.pagination
      }
      yield put(
        requestPutObservationDateFinished(formattedResponse, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }

    yield put(requestPutObservationDateFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestPutObservationDateFinished(error, true))
  }
}

function* deleteObservation({ payload, meta }) {
  try {
    const response = yield call(deleteSingleObservation, payload.payload)
    if (response.data.success) {
      const formattedResponse = {
        ...response.data,
        ...meta.pagination
      }
      yield put(
        requestDeleteObservationFinished(formattedResponse, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }

    yield put(requestDeleteObservationFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestDeleteObservationFinished(error, true))
  }
}


function* BeneficiarySaga() {
  yield takeEvery([
    REQUEST_BENEFICIARS,
    REQUEST_POST_ATTACH_FOLLOWERS_FINISHED,
    REQUEST_POST_BENEFICIAR_FINISHED,
    REQUEST_POST_IMPORT_BENEFICIARIES_FINISHED,
    REQUEST_DELETE_BENEFICIARS_FINISHED,
    REQUEST_PUT_CHANGE_OBSERVATION_DATE_FINISHED,
    REQUEST_DELETE_OBSERVATION_FINISHED
  ], fetchBeneficiaries);

  yield takeEvery([REQUEST_DELETE_BENEFICIARS], deleteSingleBeneficiar);
  yield takeEvery([REQUEST_POST_BENEFICIAR], postBeneficiars);
  yield takeEvery([REQUEST_FOLLOWERS], fetchFollowers);
  yield takeEvery([REQUST_BUSINESS_OPTIONS], fetchBusinesses);
  yield takeEvery([REQUEST_POST_ATTACH_FOLLOWERS], postAttachFollower);
  yield takeEvery([REQUEST_POST_EXPORT_BENEFICIARIES], exportBeneficiaries);
  yield takeEvery([REQUEST_GET_EXPORT_BENEFICIARIES_DATA], exportBeneficiariesData);
  yield takeEvery([REQUEST_GET_EXPORT_BENEFICIARIES_ANSWER_DATA], exportBeneficiariesAnswerData);
  yield takeEvery([REQUEST_POST_IMPORT_BENEFICIARIES], importBeneficiaries)
  yield takeEvery([REQUEST_PUT_CHANGE_OBSERVATION_DATE], putObservationNewDate)
  yield takeEvery([REQUEST_DELETE_OBSERVATION], deleteObservation)
  yield takeEvery([REQUEST_EXPORT_MISSING_EQUIPMENTS], exportMissingEquipments)
  
}


export default BeneficiarySaga;
