import {
  REQUEST_BENEFICIAR_IMAGES_IMAGES_FINISHED,
  REQUEST_DELETE_BENEFICIAR_IMAGE_FINISHED,
  REQUEST_POST_BENEFICIAR_IMAGES_FINISHED
} from "./actionTypes"

const initialState = {
  list: []
}

const BeneficiarImages = (state = initialState, { type, payload, error, meta }) => {
  if (error) return state;

  switch (type) {
    case REQUEST_BENEFICIAR_IMAGES_IMAGES_FINISHED:
      return { ...state, list: payload }
    case REQUEST_DELETE_BENEFICIAR_IMAGE_FINISHED:
      return { ...state, deleteResult: payload }
    case REQUEST_POST_BENEFICIAR_IMAGES_FINISHED:
      return { ...state, postBeneficiarImagesResult: payload }
    default:
      return state
  }
}

export default BeneficiarImages
