import axios from "axios";
import { Storage } from "helpers/storage-helper";
import { isUserAuthenticated, refreshingToken } from "./index"
import { REFRESH_TOKEN } from "./service-paths"
const API_URL = process.env.REACT_APP_BACKEND;

const http = axios.create({
    baseURL: API_URL,
});

http.interceptors.request.use(function (config) {
    if (isUserAuthenticated()) {
        config.headers["Authorization"] = `Bearer ${Storage.getToken()}`
    }
    return new Promise(resolve => setTimeout(() => resolve(config), 200))
})

let refreshTokenPromise = null
http.interceptors.response.use(
    response => response,
    error => {
        if (error.config && error.response && error.response.status === 401 && error.config.url === REFRESH_TOKEN) {
            Storage.removeAll(["authToken", "refreshToken", "userId", "routes"])
            // window.location.replace("/");
            console.log("error.config.url === REFRESH_TOKEN")
            return;
        }

        if (error.config && error.response && error.response.status === 401) {
            console.log("error.response.status === 401")
            if (!refreshTokenPromise) {
                const accessToken = Storage.getToken();
                const refreshToken = Storage.getRefreshToken();
                refreshTokenPromise = refreshingToken(accessToken, refreshToken).then(response => {
                    if (response?.data?.success) {
                        console.log("response?.data : ",response?.data)
                        Storage.set({
                            authToken: response.data.data.accessToken,
                            refreshToken: response.data.data.refreshToken,
                        })
                        // setTimeout(() => {
                        //     window.location.replace("/")
                        //     return;
                        // }, 350);
                    } else {
                        Storage.removeAll(["authToken", "refreshToken", "userId", "routes"])
                        window.location.replace("/login");
                        return;
                    }
                    refreshTokenPromise = null
                    return response
                })
            }

            return refreshTokenPromise.then(token => {

                error.config.headers[
                    "Authorization"
                ] = `Bearer ${token.data.data.accessToken}`
                return axios.request(error.config)
            })
        }
        return Promise.reject(error)
    }
)
export default http;