import React from "react"
import { useState } from "react"
import {
  Alert,
  Button,
  Col,
  DropdownMenu,
  DropdownToggle,
  FormFeedback,
  Input,
  Label,
  Row,
  UncontrolledDropdown,
} from "reactstrap"
import filterInitialValues from "../filterInitialValues"

export default function Filter({ form, filters = [], setFilters, onChange, removeFilter, isGeneral }) {


  return (
    <div>
      {filters
        .filter(filter => {
          return !form.errors[filter.key]
        })
        .map(filter => {
          let itemRender = null
          if (filter.bool) {
            itemRender = filter.name
          } else if (filter.nameSecond) {
            itemRender = `${form.values[filter.key]} ${filter.name}`
          }
          else {
            if (form.values.Gender && filter.name === "Cins") {
              itemRender = `${filter.name} : ${form.values.Gender === "F" ? "Qadın" : "Kişi"}`
            } else {
              itemRender = `${filter.name} : ${form.values[filter.key]}`
            }
          }
          return (
            <Alert
              style={{
                width: "fit-content",
                display: "inline-block",
                marginRight: "12px",
              }}
              key={filter.key}
              color="info"
              isOpen={true}
              toggle={() => {
                removeFilter(filter.key)
              }}
            >
              <span>{itemRender}</span>
            </Alert>
          )
        })}
      <UncontrolledDropdown>
        <DropdownToggle type="button" className="btn btn-light">
          Filtr əlavə et <i className="fa fa-plus"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-xl p-4">
          <Row>
            <Col xs="12" xl="6">
              <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="isEquipmentConfigured"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={() => {
                    onChange("isEquipmentConfigured",
                      !!!form.values.isEquipmentConfigured
                    )()
                  }}
                  active={Boolean(form.values.isEquipmentConfigured)} >
                  Avadanlıq quraşdırılan
                </Button>
              </div>
              <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="isEquipmentNotConfigured"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={() => {
                    onChange("isEquipmentNotConfigured",
                      !!!form.values.isEquipmentNotConfigured
                    )()
                  }}
                  active={Boolean(form.values.isEquipmentNotConfigured)} >
                  Avadanlıq quraşdırılmayan
                </Button>
              </div>
              <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="IsEquipmentValid"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={() => {
                    onChange("IsEquipmentValid",
                      !!!form.values.IsEquipmentValid
                    )()
                  }}
                  active={Boolean(form.values.IsEquipmentValid)} >
                  Avadanlığı satan
                </Button>
              </div>
              {isGeneral && <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="isMemberOfMartyrFamily"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={() => {
                    onChange(
                      "isMemberOfMartyrFamily",
                      !!!form.values.isMemberOfMartyrFamily
                    )()
                  }}
                  active={Boolean(form.values.isMemberOfMartyrFamily)}
                >
                  Şəhid ailəsi
                </Button>
              </div>}


              {isGeneral && <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="hasVoen"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={() => {
                    onChange(
                      "hasVoen",
                      !!!form.values.hasVoen
                    )()
                  }}
                  active={Boolean(form.values.hasVoen)}
                >
                  VÖEN var
                </Button>
              </div>}

              {isGeneral && <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="isVeteran"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={e => {
                    onChange("isVeteran", !!!form.values.isVeteran)()
                    // form.setFieldValue("isVeteran", !!!form.values.isVeteran )
                  }}
                  active={Boolean(form.values.isVeteran)}
                >
                  Veteran
                </Button>
              </div>}

            </Col>

            <Col xs="12" xl="6">
              <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="isDisabled"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={e => {
                    onChange("isDisabled", !!!form.values.isDisabled)()
                    // form.setFieldValue("isDisabled", !!!form.values.isDisabled )
                  }}
                  active={Boolean(form.values.isDisabled)}
                >
                  Əlildir
                </Button>
              </div>
              <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="JoinMentor"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={e => {
                    onChange("JoinMentor", !!!form.values.JoinMentor)()
                  }}
                  active={Boolean(form.values.JoinMentor)}
                >
                  Mentorluq təlimi olan
                </Button>
              </div>
              <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="IDP"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={e => {
                    onChange("IDP", !!!form.values.IDP)()
                  }}
                  active={Boolean(form.values.IDP)}
                >
                  Məcburi köçkün
                </Button>
              </div>

              <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="UDSY"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={e => {
                    onChange("UDSY", !!!form.values.UDSY)()
                  }}
                  active={Boolean(form.values.UDSY)}
                >
                  Sosial yardım alan
                </Button>
              </div>
              <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="Gender"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={e => {
                    onChange("Gender", form.values.Gender === "M" ? "" : "M")()

                  }}
                  active={form.values.Gender === "M" ? true : false}
                >
                  Kişi
                </Button>
              </div>
              <div className="mb-2">
                <Label className="form-label">&nbsp;</Label>
                <Button
                  name="Gender"
                  className="form-control"
                  color="primary"
                  outline
                  onClick={e => {
                    onChange("Gender", form.values.Gender === "F" ? "" : "F")()
                  }}
                  active={form.values.Gender === "F" ? true : false}
                >
                  Qadın
                </Button>
              </div>


              {/* <div className="mb-2">
                <Label className="form-label">Cins</Label>
                <Input
                  name="Gender"
                  type="select"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.Gender || ""}
                  invalid={
                    form.touched.Gender &&
                      form.errors.Gender
                      ? true
                      : false
                  }
                >
                  <option value="">Kişi və qadın</option>
                  <option value="M">Kişi</option>
                  <option value="F">Qadın</option>
                </Input>
              </div> */}
            </Col>
          </Row>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  )
}
