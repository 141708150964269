import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  InputGroup,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, Navigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { requestChangePassword } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import { ReactComponent as Logo } from "assets/images/logo.svg"
import { ReactComponent as LogoLight } from "assets/images/logo-light.svg"
import { REQUEST_CHANGE_PASSWORD, REQUEST_CHANGE_PASSWORD_FINISHED } from "store/auth/changePassword/actionTypes"
const ChangePassword = props => {
  //meta title
  document.title = "Change Password | Self Employment Project"
  const [showOldPassword, setShopOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const dispatch = useDispatch();

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      oldPassword: "",
      newPassword: "",
      reNewPassword: "",
      userName: ""
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string().required("Please enter your old password"),
      newPassword: Yup.string().required("Şifrə doldurulmayıb").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, "Şifrə az 8 karakterə, ən az bir böyük və bir kiçik hərfə , ən az bir rəqəmə sahib olmalıdır və yalnız ingiliz əlifbası istifadə olunmalıdır"),
      reNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Təsdiq şifrəsi doğru deyil').required("Təsdiq şifrəsi doğru deyil"),
      userName: Yup.string().required("İstifadçi adı doldurulmalıdır")
    }),
    onSubmit: values => {
      dispatch(requestChangePassword(values, props.router.navigate))
    },
  });

  const { error, isRequesting } = useSelector(state => ({
    error: state.Error[REQUEST_CHANGE_PASSWORD_FINISHED],
    isRequesting: state.Requesting[REQUEST_CHANGE_PASSWORD]
  }));

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="p-4">
                        <p className="text-primary">
                          Öncə ilk verilən şifrəni dəyişin, daha sonra yeni şifrə ilə hesabınıza daxil olun.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4 w-50 logo-dark-element">
                      <span className="avatar-title rounded-2  overflow-hidden">
                        <img src={require("../../assets/images/Logo_ss.png")} style={{ width: '100%', objectFit: "cover" }} />
                      </span>
                    </div>
                  </div>
                  {/* <div>
                    <div className="avatar-md profile-user-wid mb-4 w-50 logo-light-element">
                      <span className="avatar-title rounded-2 bg-light dark-compatible p-4">
                        <LogoLight style={{ height: "50px" }} />
                      </span>
                    </div>
                  </div> */}
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        form.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      <Col className="mb-3">
                        <Label className="form-label">İstifadəçi adı</Label>
                        <InputGroup>
                          <Input
                            name="userName"
                            type="text"
                            placeholder="İstifadçi adını daxil edin"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.userName || ""}
                            invalid={
                              form.touched.userName && form.errors.userName
                                ? true
                                : false
                            }
                          />
                          {form.touched.userName && form.errors.userName ? (
                            <FormFeedback type="invalid">
                              {form.errors.userName}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </Col>

                      <Col className="mb-3">
                        <Label className="form-label">Cari şifrə</Label>
                        <InputGroup>
                          <Input
                            name="oldPassword"
                            type={`${showOldPassword ? 'text' : 'password'}`}
                            placeholder="Cari şifrəni daxil edin"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.oldPassword || ""}
                            autoComplete='new-password'
                            invalid={
                              form.touched.oldPassword && form.errors.oldPassword
                                ? true
                                : false
                            }
                          />
                          <a className="p-2" onClick={() => setShopOldPassword(!showOldPassword)}>
                            <i className={`${showOldPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'} font-size-18`}></i>
                          </a>
                          {form.touched.oldPassword && form.errors.oldPassword ? (
                            <FormFeedback type="invalid">
                              {form.errors.oldPassword}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </Col>

                      <Col className="mb-3">
                        <Label className="form-label">Yeni şifrə</Label>
                        <InputGroup>
                          <Input
                            name="newPassword"
                            type={`${showPassword ? 'text' : 'password'}`}
                            placeholder="Yeni şifrəni daxil edin"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.newPassword || ""}
                            autoComplete='new1-password'
                            invalid={
                              form.touched.newPassword && form.errors.newPassword
                                ? true
                                : false
                            }
                          />
                          <a className="p-2" onClick={() => setShowPassword(!showPassword)}>
                            <i className={`${showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'} font-size-18`}></i>
                          </a>
                          {form.touched.newPassword && form.errors.newPassword ? (
                            <FormFeedback type="invalid">
                              {form.errors.newPassword}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </Col>

                      <Col className="mb-3">
                        <Label className="form-label">Yeni şifrənin təsdiqi</Label>
                        <InputGroup>
                          <Input
                            name="reNewPassword"
                            type={`${showNewPassword ? 'text' : 'password'}`}
                            placeholder="Yeni şifrəni təsdiq edin"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.reNewPassword || ""}
                            autoComplete='new2-password'
                            invalid={
                              form.touched.reNewPassword && form.errors.reNewPassword
                                ? true
                                : false
                            }
                          />
                          <a className="p-2" onClick={() => setShowNewPassword(!showNewPassword)}>
                            <i className={`${showNewPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'} font-size-18`}></i>
                          </a>
                          {form.touched.reNewPassword && form.errors.reNewPassword ? (
                            <FormFeedback type="invalid">
                              {form.errors.reNewPassword}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </Col>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                          disabled={isRequesting}
                        >
                          {isRequesting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>} {" "}
                          Göndər
                        </button>
                      </div>

                      {/* <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Forgot your password?
                        </Link>
                      </div> */}
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}



export default withRouter(ChangePassword)

ChangePassword.propTypes = {
  history: PropTypes.object,
}
