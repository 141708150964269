import EnhancedTable from "components/Common/EnhancedTable"
import React, { useMemo } from "react"
import { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip
} from "reactstrap"
import { beneficiarColumns } from "./columns";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { requestBeneficiars, requestExportBeneficiariesAnserData, requestExportBeneficiariesData, saveBeneficiarsFilter } from "store/beneficiars/actions";
import { selectBeneficiarExportResult, selectBeneficiarFilter, selectBeneficiaris, selectLoadingBeneficiaris, } from "selectors/BeneficiarisSelectors";
import { defaultColumn } from "react-table";
import ObservationQuestions from "pages/Results/observationQuestions";
import formatDate from "helpers/format-date";

export default function BeneficiarTable({
  beneficiarSetter,
  followers
}) {
  const filter = useSelector(selectBeneficiarFilter);
  const isLoading = useSelector(selectLoadingBeneficiaris);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [observationModal, setObservationModal] = useState(false);
  const [selectedObservtaion, setSelectedObservation] = useState(null);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [isExportAnswersLoading, setIsExportAnswersLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })

  const beneficiarExportResult = useSelector(selectBeneficiarExportResult);
  const data = useSelector(selectBeneficiaris);

  const columns = beneficiarColumns;


  const dispatch = useDispatch();

  const handleObservationDetails = (observation) => {
    setObservationModal(true);
    setSelectedObservation(observation)
  }

  const handleToggleObservationModal = () => {
    setObservationModal(false)
    setSelectedObservation(null)
  }

  const handleExportBeneficiarData = () => {
    if (!isExportLoading) {
      dispatch(requestExportBeneficiariesData(filter, { type: "report" }))
      setIsExportLoading(true)
    }

  }

  const handleExportAnswersData = () => {
    if (!isExportAnswersLoading) {
      dispatch(requestExportBeneficiariesAnserData(filter));
      setIsExportAnswersLoading(true);
    }
  }

  useEffect(() => {
    if (!isFirstLoad) {
      dispatch(
        requestBeneficiars({
          filter,
          pageIndex: pagination.pageIndex + 1,
          pageSize: pagination.pageSize,
        })
      )

    } else {
      setIsFirstLoad(false), () => { dispatch(saveBeneficiarsFilter({})) }
    }
  }, [filter, isFirstLoad, pagination])


  useEffect(() => {
    if (beneficiarExportResult && beneficiarExportResult?.success) {
      setTimeout(() => {
        setIsExportLoading(false);
        setIsExportAnswersLoading(false);
      }, 550);
    }
  }, [beneficiarExportResult])



  const tableColumns = useMemo(
    () => {
      return [
        {
          id: "gallery",
          header: "Şəkillər",
          accessorKey: "images",
          cell: ({ row }) => {
            if (row.original.isPhotoValid) {
              return (
                <i
                  onClick={() => beneficiarSetter(row.original.beneficiarId)}
                  className="mdi mdi-folder-image text-primary"
                  style={{ fontSize: "24px", cursor: "pointer", padding: "15px" }}
                ></i>
              )
            } else {
              return (
                <i
                  className="mdi mdi-folder text-secondary"
                  style={{ fontSize: "24px", padding: "15px" }}
                ></i>
              )
            }
          },
        },
        ...columns,
        {
          header: () => {
            return <div className="m-3">  Müşahidə səfərləri </div>
          },
          id: "observations",
          meta: {
            align: "center",
            border: {
              bottom: "1px solid #c9c9c9",
            }
          },
          columns: [
            {
              header: ({ table }) => {
                return <div className="headerWithImage">
                  <p>
                    Müşahidə səfəri 1
                  </p>
                </div>
              },
              id: 'obervation-1',
              accessorKey: "followers",
              cell: ({ row }) => {
                const data = row.original;
                const follower = data.followers.filter(follower => follower.observationId === 1)[0];
                return follower?.observationDate ? <div className="m-2 d-flex justify-content-center" style={{ width: '200px' }}>
                  {follower?.isCompleted && <p className="text-center m-0 me-2">{formatDate(follower?.observationDate)}</p>}
                  {follower?.ignored ? <p className="text-center text-danger m-0 me-2"> {follower.ignoredText ? follower.ignoredText : "Şərait yaratmadı"}</p> : follower?.isCompleted ? < p className="text-center m-0  text-primary" style={{ cursor: 'pointer' }} onClick={() => handleObservationDetails({ ...data, observationId: follower.observationId })}>Detallar</p> : <p className="text-center text-warning m-0 me-2">Davam edir...</p>}
                </div>
                  : <p className="m-2 d-flex justify-content-center" style={{ color: '#d79090', textAlign: 'start' }}>Səfər edilməyib</p>
              }
            },

            {
              header: ({ table }) => {
                return <div className="headerWithImage">
                  <p>
                    Müşahidə səfəri 2
                  </p>
                </div>
              },
              id: 'obervation-2',
              accessorKey: "followers",
              cell: ({ row }) => {
                const data = row.original;
                const follower = data.followers.filter(follower => follower.observationId === 2)[0];
                return follower?.observationDate ? <div className="m-2 d-flex justify-content-center" style={{ width: '200px' }}>
                  {follower?.isCompleted && <p className="text-center m-0 me-2">{formatDate(follower?.observationDate)}</p>}
                  {follower?.ignored ? <p className="text-center text-danger m-0 me-2"> {follower.ignoredText ? follower.ignoredText : "Şərait yaratmadı"}</p> : follower?.isCompleted ? < p className="text-center m-0  text-primary" style={{ cursor: 'pointer' }} onClick={() => handleObservationDetails({ ...data, observationId: follower.observationId })}>Detallar</p> : <p className="text-center text-warning m-0 me-2">Davam edir...</p>}
                </div>
                  : <p className="m-2 d-flex justify-content-center" style={{ color: '#d79090', textAlign: 'start' }}>Səfər edilməyib</p>
              }
            },

            {
              header: ({ table }) => {
                return <div className="headerWithImage">
                  <p > Müşahidə səfəri 3 </p>
                </div>
              },
              id: 'obervation-3',
              accessorKey: "followers",
              cell: ({ row }) => {
                const data = row.original;
                const follower = data.followers.filter(follower => follower.observationId === 3)[0];
                return follower?.observationDate ? <div className="m-2 d-flex justify-content-center" style={{ width: '200px' }}>
                  {follower?.isCompleted && <p className="text-center m-0 me-2">{formatDate(follower?.observationDate)}</p>}
                  {follower?.ignored ? <p className="text-center text-danger m-0 me-2"> {follower.ignoredText ? follower.ignoredText : "Şərait yaratmadı"}</p> : follower?.isCompleted ? < p className="text-center m-0  text-primary" style={{ cursor: 'pointer' }} onClick={() => handleObservationDetails({ ...data, observationId: follower.observationId })}>Detallar</p> : <p className="text-center text-warning m-0 me-2">Davam edir...</p>}
                </div>
                  : <p className="m-2 d-flex justify-content-center" style={{ color: '#d79090', textAlign: 'start' }}>Səfər edilməyib</p>
              }
            },

            {
              header: ({ table }) => {
                return <div className="headerWithImage">
                  <p> Müşahidə səfəri 4 </p>
                </div>
              },
              id: 'obervation-4',
              accessorKey: "followers",
              cell: ({ row }) => {
                const data = row.original;
                const follower = data.followers.filter(follower => follower.observationId === 4)[0];
                return follower?.observationDate ? <div className="m-2 d-flex justify-content-center" style={{ width: '200px' }}>
                  {follower?.isCompleted && <p className="text-center m-0 me-2">{formatDate(follower?.observationDate)}</p>}
                  {follower?.ignored ? <p className="text-center text-danger m-0 me-2"> {follower.ignoredText ? follower.ignoredText : "Şərait yaratmadı"}</p> : follower?.isCompleted ? < p className="text-center m-0  text-primary" style={{ cursor: 'pointer' }} onClick={() => handleObservationDetails({ ...data, observationId: follower.observationId })}>Detallar</p> : <p className="text-center text-warning m-0 me-2">Davam edir...</p>}
                </div>
                  : <p className="m-2 d-flex justify-content-center" style={{ color: '#d79090', textAlign: 'start' }}>Səfər edilməyib</p>
              }
            },
          ]
        },
      ]

    },
    [columns]
  )

  return (
    <Card>
      <CardBody>
        <Row className="my-2">
          <Col className="col-6" />
          <div className="my-2 text-start">
            <a className={`btn me-3 ${isExportLoading ? "btn-secondary" : "btn-success"}`} onClick={handleExportBeneficiarData}>
              {isExportLoading ? (
                <span
                  className="spinner-border spinner-border-sm me-3 font-size-18 p-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : <i className="mdi mdi-download me-2 font-size-18"></i>}
              {" "}
              Export
            </a>
            {/* <a className={`btn me-3 ${isExportAnswersLoading ? "btn-secondary" : "btn-success"}`} title="export to excel file" onClick={handleExportAnswersData}>
              {isExportAnswersLoading ? (
                <span
                  className="spinner-border spinner-border-sm me-3 font-size-18 p-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : <i className="mdi mdi-download me-2 font-size-18"></i>}
              {" "}
              Cavablar
            </a> */}
          </div>
        </Row>
        <EnhancedTable
          onPaginationChange={setPagination}
          pagination={pagination}
          manualPagination
          defaultColumn={defaultColumn}
          data={data.beneficiaries}
          columns={tableColumns}
          isLoading={isLoading}
          DataCount={data.count}
          followers={followers}
        />
        <Modal size="xl" isOpen={observationModal} toggle={handleToggleObservationModal}>
          <ModalHeader toggle={handleToggleObservationModal} tag="h4">
            <p>{selectedObservtaion?.fullName}</p>
            {selectedObservtaion?.observationId}{" "}
            {selectedObservtaion?.observationId < 3 ? "ci" : "cü"} Müşahidə səfərlərinin detalları
          </ModalHeader>
          <ModalBody>
            {selectedObservtaion && <ObservationQuestions data={selectedObservtaion} />}
          </ModalBody>
        </Modal>
      </CardBody>
    </Card >
  )
}
