import { call, put, takeEvery } from "redux-saga/effects"

import {
  REQUEST_ATTACHED_BENEFICIARIES,
  REQUEST_DETAILED_FOLLOWERS,
  REQUEST_POST_ANSWER_QUESTION,
  REQUEST_POST_ANSWER_QUESTION_FINISHED,
  REQUEST_QUESTIONS_BY_FILTER,
  REQUEST_POST_FOLLOWER_CONTRACT,
  REQUEST_POST_FOLLOWER_CONTRACT_FINISHED,
  REQUEST_POST_COMPLETE_OBSERVATION,
  REQUEST_POST_EQUIPMENT,
  REQUEST_POST_EQUIPMENT_FINISH,
  REQUEST_EXPORT_FOLLOWER_EXCEL,
  REQUEST_EXPORT_ATTACHED_BENEFICIARIES
} from "./actionTypes"

import {
  requestAttachedBeneficiariesFinished,
  requestDetailedFollowersFinished,
  requestExportAttachedBeneficiariesFinished,
  requestExportFollowerExcelFinished,
  requestObservationQuestionsFinished,
  requestPostCompleteObservationFinished,
  requestPostEquipmentFinished,
  requestPostFollowerContractFinished,
  requestPostQuestionAnswerFinished
} from "./actions"

import {
  getAllDetailedFollowers,
  getAttachedBeneficiariars,
  getObservationQuestions,
  postFollowerContract,
  postQuestionAnswer,
  putFollowerContract,
  putUpdateAnsweredQuestion,
  postCompleteConservation,
  postEquipment
} from "services"

import { toast } from "react-toastify"
import { formatQuestionAndAnswers, fotmatObservationQuestions } from "helpers/format-helper"
import { exportToExcel } from "helpers/excel_exporter"

function* fetchDetailedFollowers({ error, payload }) {
  if (error) return
  try {
    const response = yield call(getAllDetailedFollowers, payload)
    if (response.status) {
      const { data } = response;
      const detailedFollowers = data.data;
      yield put(requestDetailedFollowersFinished(detailedFollowers))
      return
    }
    yield put(requestDetailedFollowersFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestDetailedFollowersFinished(error, true))
  }
}

function* fetchAttachedBeneficiaries({ error, payload }) {
  if (error) return
  try {
    const response = yield call(getAttachedBeneficiariars, payload)
    if (response.status) {
      const { data } = response;
      const beneficiariesData = data.data;
      yield put(requestAttachedBeneficiariesFinished({ list: beneficiariesData.beneficiaries, count: beneficiariesData.count }))
      return
    }
    yield put(requestAttachedBeneficiariesFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestAttachedBeneficiariesFinished(error, true))
  }
}

function* ExportAttachedBeneficiaries({ error, payload }) {
  if (error) return
  try {
    const response = yield call(getAttachedBeneficiariars, payload)
    if (response.status) {
      const { data } = response;
      const beneficiariesData = data.data;
      exportToExcel({ Data: beneficiariesData.beneficiaries, fileName: `Attached_Beneficiaries_${Date.now()}`, type: "beneficiar" });
      // yield put(requestAttachedBeneficiariesFinished({ list: beneficiariesData.beneficiaries, count: beneficiariesData.count }))
      return
    }
    yield put(requestExportAttachedBeneficiariesFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestExportAttachedBeneficiariesFinished(error, true))
  }
}

function* fetchObservationQuestions({ error, payload }) {
  if (error) return
  try {
    const response = yield call(getObservationQuestions, payload)
    if (response.status) {
      const { data } = response;
      const questionData = data.data;
      const formattedQuestions = fotmatObservationQuestions(questionData)
      yield put(requestObservationQuestionsFinished(formattedQuestions))
      return
    }

    yield put(requestObservationQuestionsFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestObservationQuestionsFinished(error, true))
  }
}

function* postSingleQuestionAnswer({ payload, mode, pagination }) {
  try {
    const response = yield call(mode === 'add' ? postQuestionAnswer : putUpdateAnsweredQuestion, payload);
    if (response.data.success) {
      const formattedResponse = {
        success: response.data.success,
        message: response.data.message,
        BeneficiaryId: payload.BeneficiaryId,
        ObservationId: payload.ObservationId,
        pagination
      }
      yield put(requestPostQuestionAnswerFinished(formattedResponse, false, { toast: { type: "success" }, refresh: true }))

      toast.success('Question Successfully answered', {
        position: toast.POSITION.TOP_RIGHT
      });
      return
    }
    toast.error(response.data?.message || response.data?.title || "Bilinməyən xəta baş verdi", {
      position: toast.POSITION.TOP_RIGHT
    });
  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* postSingleFollowerContract({ payload, mode, pagination }) {
  try {
    const response = yield call(mode === 'add' ? postFollowerContract : putFollowerContract, payload);
    const formattedResponse = {
      ...response.data,
      pageIndex: pagination.pageIndex + 1,
      pageSize: pagination.pageSize,

    }
    if (response.data.success) {
      yield put(requestPostFollowerContractFinished(formattedResponse, false, { toast: { type: "success" }, refresh: true }))
      return
    }
    yield put(requestPostFollowerContractFinished(formattedResponse, true))
  } catch (error) {
    yield put(requestPostFollowerContractFinished(error, true))
  }
}

function* postCompleteSingleObservation({ payload }) {
  try {
    const response = yield call(postCompleteConservation, payload);
    if (response.data.success) {
      yield put(requestPostCompleteObservationFinished(response.data, false, { toast: { type: "success" }, refresh: true }))
      return
    }
    yield put(requestPostCompleteObservationFinished(response.data, true))
  } catch (error) {
    yield put(requestPostCompleteObservationFinished(error, true))
  }
}

function* postSingleEquipment({ payload, pagination }) {
  try {
    const response = yield call(postEquipment, payload);
    if (response.data.success) {
      const formattedResponse = {
        success: response.data.success,
        message: response.data.message,
        BeneficiaryId: payload.BeneficiaryId,
        ObservationId: payload.ObservationId,
        pagination
      }
      yield put(requestPostEquipmentFinished(formattedResponse, false, { toast: { type: "success" }, refresh: true }))

      return
    }

  } catch (error) {
    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* exportFollowerExcel({ error, payload }) {
  if (error) return
  try {
    const response = yield call(getAllDetailedFollowers, payload)
    if (response.status) {
      const { data } = response;
      const followerExcelData = data.data.followerDetails;
      exportToExcel({ Data: followerExcelData, fileName: `followers_${Date.now()}`, type: "follower" });
      yield put(requestExportFollowerExcelFinished({ success: true }, false))
      return
    }
    yield put(requestExportFollowerExcelFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestExportFollowerExcelFinished(error, true))
  }
}

function* FollowersSaga() {
  yield takeEvery([REQUEST_DETAILED_FOLLOWERS, REQUEST_POST_FOLLOWER_CONTRACT_FINISHED], fetchDetailedFollowers);
  yield takeEvery([REQUEST_ATTACHED_BENEFICIARIES], fetchAttachedBeneficiaries);
  yield takeEvery([REQUEST_EXPORT_ATTACHED_BENEFICIARIES], ExportAttachedBeneficiaries);
  yield takeEvery([REQUEST_QUESTIONS_BY_FILTER, REQUEST_POST_ANSWER_QUESTION_FINISHED], fetchObservationQuestions);
  yield takeEvery([REQUEST_POST_ANSWER_QUESTION], postSingleQuestionAnswer);
  yield takeEvery([REQUEST_POST_FOLLOWER_CONTRACT], postSingleFollowerContract);
  yield takeEvery([REQUEST_POST_COMPLETE_OBSERVATION], postCompleteSingleObservation);
  yield takeEvery([REQUEST_POST_EQUIPMENT], postSingleEquipment)
  yield takeEvery([REQUEST_EXPORT_FOLLOWER_EXCEL], exportFollowerExcel)
  

}

export default FollowersSaga
