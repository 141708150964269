import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, REQUEST_LOGIN_USER } from "./actionTypes";
import { apiError, loginUserDelayed, requestLoginUserFinished } from "./actions";
import { login } from "services";
import { Storage } from "helpers/storage-helper";
import jwt_decode from "jwt-decode";
// const fireBaseBackend = getFirebaseBackend();

const pathDefiner = (role) => {
  switch (role) {
    case 'ROLE_ADMIN':
      return "/dashboard"
    case 'ROLE_MANAGER':
      return "/dashboard"
    case 'ROLE_FOLLOWER':
      return "/results"
    case 'ROLE_REPORT':
      return "/reports"
    case 'ROLE_ACCOUNTANT':
      return "/accounting"
    default: return "/"
  }

}
function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(login, user);
      const { data } = response;

      if (data.success) {
        if (data.data.isFirstLogin) {
          yield put(requestLoginUserFinished({}));
          history('/changePassword', { replace: true });
        }
        else {
          Storage.set({
            authToken: data.data.accessToken,
            refreshToken: data.data.refreshToken,
            isFirstLogin: data.data.isFirstLogin
          })
          const decodedToken = jwt_decode(data.data.accessToken);
          const path = pathDefiner(decodedToken.role)
          history(path, { replace: true });
          yield put(requestLoginUserFinished(decodedToken))
        }
      } else yield put(requestLoginUserFinished(data, true))

    }
  } catch (error) {
    console.error(error)
    yield put(requestLoginUserFinished(error?.message, true))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    Storage.removeAll(["authToken", "refreshToken", "isFirstLogin", "authUser", "Auth"]);
    history('/login', { replace: true });
  } catch (error) {
    yield put(apiError(error));
  }
}


function* authSaga() {
  yield takeEvery(REQUEST_LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
