export function createParamString(params, startWithQuestionMark = true) {
  if (params == null || typeof params !== "object") {
    return ""
  }
  
  let paramsString = [];
  
  params = Object.entries(params).reduce(
    (params, [key, value]) => {
      if (Array.isArray(value)) {
        const paramValue = value.map(valueItem => [key, valueItem]);
        paramsString.push(new URLSearchParams(paramValue).toString()) ;
      } else if (key !== "" && value !== "" && value != null) {
        paramsString.push(new URLSearchParams({[key]: value}).toString());
      }
      return params
    },
    {}
    )
    
    const paramString = paramsString.filter(param => Boolean(param)).join("&")
  return paramString ? "?" + paramString : paramString
}