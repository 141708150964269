import { call, put, takeEvery } from "redux-saga/effects";
import { deleteBusiness, getBusinesses, postBusinessEquipment } from "services";

import { requestBusinessesFinished, requestDeleteBusinessFinished, requestPostBusinessEquipmentFinished } from "./actions";
import { REQUEST_BUSINESSES, REQUEST_DELETE_BUSINESS, REQUEST_DELETE_BUSINESS_FINISHED, REQUEST_POST_BUSINESS_EQUIPMENT, REQUEST_POST_BUSINESS_EQUIPMENT_FINISHED } from "./actionTypes";

function* fetchBusinesses({ error, payload }) {
  if (error) return;
  try {
    const response = yield call(getBusinesses, payload);

    if (response.status) {
      const { data } = response;
      const businessData = data.data
      yield put(requestBusinessesFinished(businessData.businesses))
      return
    }

    // success false
    yield put(requestBusinessesFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestBusinessesFinished(error, true))
  }
}

function* postSingleBusinessEquipment(action) {
  if (action.error) return;

  try {
    const response = yield call(postBusinessEquipment, action.payload);

    if (response.status) {
      yield put(requestPostBusinessEquipmentFinished(response.data, false, { toast: { type: "success" } }))
      if (action.meta?.onSuccess) {
        action.meta.onSuccess()
      }
      return
    }

    // success false
    yield put(requestPostBusinessEquipmentFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestPostBusinessEquipmentFinished(error, true))
  }
}

function* deleteSingleBusinessEquipment(action) {
  if (action.error) return;

  try {
    const response = yield call(deleteBusiness, action.payload);

    if (response.status) {
      yield put(requestDeleteBusinessFinished(response.data, false, { toast: { type: "success" } }))
      return
    }

    // success false
    yield put(requestDeleteBusinessFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestDeleteBusinessFinished(error, true))
  }
}

function* BusinessesSaga() {
  yield takeEvery([
    REQUEST_BUSINESSES,
    REQUEST_POST_BUSINESS_EQUIPMENT_FINISHED,
    REQUEST_DELETE_BUSINESS_FINISHED
  ], fetchBusinesses);
  yield takeEvery(REQUEST_POST_BUSINESS_EQUIPMENT, postSingleBusinessEquipment)
  yield takeEvery(REQUEST_DELETE_BUSINESS, deleteSingleBusinessEquipment)
}


export default BusinessesSaga;