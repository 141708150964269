import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { REQUEST_QA } from "./actionTypes"
import { requestQAFinished } from "./actions"
import { getQuestionAndAnswers } from "services"

// const fireBaseBackend = getFirebaseBackend();

function* fetchQuestinAndAnswers({ error,payload }) {
  if (error) return
  try {
    const response = yield call(getQuestionAndAnswers,payload)

    if (response.status) {
      const { data } = response
      const answers = data.data.beneficiarQuestionsAnswers
      yield put(requestQAFinished(answers))
      return
    }

    // success false
    yield put(requestQAFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestQAFinished(error, true))
  }
}

function* QuestionAnswersSaga() {
  yield takeEvery([REQUEST_QA], fetchQuestinAndAnswers)
}

export default QuestionAnswersSaga
