import PropTypes from 'prop-types'
import React from "react"
import { Modal, ModalBody } from "reactstrap"

const DeleteModal = ({ show, onDeleteClick, onCloseClick, isLoading, text = {}}) => {
  return (
    <Modal role='dialog' size="sm" isOpen={show} toggle={() => onCloseClick()} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={() => onCloseClick()} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-danger text-danger bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-trash-can-outline"></i>
            </div>
          </div>
          <p className="text-muted font-size-16 mb-4">{text.title || "Silmək istədiyinizə əminsiniz?"}</p>

          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-danger" disabled={isLoading} onClick={() => onDeleteClick()}>
            {isLoading && <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>} {" "}
              {text.delete || "Sil"}
              </button>
            <button type="button" className="btn btn-secondary" onClick={() => onCloseClick()}>{text.close || "Bağla"}</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

DeleteModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default DeleteModal
