import React from "react"

const defaultBeneficiarColumn = {
    cell: ({ getValue }) => {
      return (
        <p
          style={{
            whiteSpace: "normal",
            maxWidth: "200px",
            minWidth: "125px",
          }}
        >
          {getValue()}
        </p>
      )
    },
  }

  export default defaultBeneficiarColumn