import {
  REQUEST_USERS,
  REQUEST_USERS_FINISHED,
  REQUEST_POST_USER,
  REQUEST_POST_USER_FINISHED,
  REQUEST_DELETE_USER,
  REQUEST_DELETE_USER_FINISHED,
  REQUEST_REGIONS,
  REQUEST_REGIONS_FINISHED,
  REQUEST_CHANGE_PASSWORD,
  REQUEST_CHANGE_PASSWORD_FINISHED,
  REQUEST_CHANGE_PASSWORD_AS_ADMIN,
  REQUEST_CHANGE_PASSWORD_AS_ADMIN_FINISHED
} from "./actionTypes"



export const requestUsers = (payload) => {
  return {
    type: REQUEST_USERS,
    payload
  }
}

export const requestUsersFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_USERS_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestPostUser = (payload) => {
  return {
    type: REQUEST_POST_USER,
    payload
  }
}

export const requestPostUsersFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_USER_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestChangePasswordAsAdmin = (payload,meta) => {
  return {
    type: REQUEST_CHANGE_PASSWORD_AS_ADMIN,
    payload,
    meta
  }
}

export const requestChangePasswordAsAdminFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_CHANGE_PASSWORD_AS_ADMIN_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestDeleteUser = (payload,meta) => {
  return { type: REQUEST_DELETE_USER, payload ,meta}
}

export const requestDeleteUserFinished = (payload, error = false, meta = null) => {
  return { type: REQUEST_DELETE_USER_FINISHED, payload, error, meta }
}

export const requestRegions = () => {
  return {
    type: REQUEST_REGIONS
  }
}

export const requestRegionsFinished = (payload) => {
  return {
    type: REQUEST_REGIONS_FINISHED,
    payload
  }
}