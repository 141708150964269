import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { REQUEST_OBSERVATIONS_COUNT,REQUEST_OBSERVATIONS_COUNT_FINISHED} from "./actionTypes";
import {requestObservationsCountFinished} from "./actions";
import { getObservationsCount } from "services";

// const fireBaseBackend = getFirebaseBackend();

function* fetchObservationsCount({error,payload}) {
  if (error) return;
  try {
    const response = yield call(getObservationsCount,payload);
    if (response.status) {
      const { data } = response;
      const observationsCount = data.data
      yield put(requestObservationsCountFinished(observationsCount))
      return
    }

    yield put(requestObservationsCountFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestObservationsCountFinished(error, true))
  }
}


function* ObservationsCountSaga() {
  yield takeLatest([REQUEST_OBSERVATIONS_COUNT], fetchObservationsCount);
}

export default ObservationsCountSaga;
