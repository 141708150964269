export const REQUEST_BENEFICIARS = "REQUEST_BENEFICIARS"
export const REQUEST_BENEFICIARS_FINISHED = "REQUEST_BENEFICIARS_FINISHED"

export const REQUEST_POST_BENEFICIAR = "REQUEST_POST_BENEFICIAR"
export const REQUEST_POST_BENEFICIAR_FINISHED = "REQUEST_POST_BENEFICIAR_FINISHED"

export const REQUEST_POST_EXPORT_BENEFICIARIES = "REQUEST_POST_EXPORT_BENEFICIARIES"
export const REQUEST_POST_EXPORT_BENEFICIARIES_FINISHED = "REQUEST_POST_EXPORT_BENEFICIARIES_FINISHED"

export const REQUEST_GET_EXPORT_BENEFICIARIES_DATA = "REQUEST_GET_EXPORT_BENEFICIARIES_DATA"
export const REQUEST_GET_EXPORT_BENEFICIARIES_DATA_FINISHED = "REQUEST_GET_EXPORT_BENEFICIARIES_DATA_FINISHED"

export const REQUEST_GET_EXPORT_BENEFICIARIES_ANSWER_DATA = "REQUEST_GET_EXPORT_BENEFICIARIES_ANSWER_DATA"
export const REQUEST_GET_EXPORT_BENEFICIARIES_ANSWER_DATA_FINISHED = "REQUEST_GET_EXPORT_BENEFICIARIES_ANSWER_DATA_FINISHED"

export const REQUEST_POST_IMPORT_BENEFICIARIES = "REQUEST_POST_IMPORT_BENEFICIARIES"
export const REQUEST_POST_IMPORT_BENEFICIARIES_FINISHED = "REQUEST_POST_IMPORT_BENEFICIARIES_FINISHED"

export const REQUEST_FOLLOWERS = "REQUEST_FOLLOWERS"
export const REQUEST_FOLLOWERS_FINISHED = "REQUEST_FOLLOWERS_FINISHED"

export const REQUEST_POST_ATTACH_FOLLOWERS = "REQUEST_POST_ATTACH_FOLLOWERS"
export const REQUEST_POST_ATTACH_FOLLOWERS_FINISHED = "REQUEST_POST_ATTACH_FOLLOWERS_FINISHED"

export const SAVE_BENEFICIAR_FILTER = "SAVE_BENEFICIAR_FILTER"

export const REQUST_BUSINESS_OPTIONS = "REQUST_BUSINESS_OPTIONS"
export const REQUST_BUSINESS_OPTIONS_FINISHED = "REQUST_BUSINESS_OPTIONS_FINISHED"

export const REQUEST_DELETE_BENEFICIARS = "REQUEST_DELETE_BENEFICIARS"
export const REQUEST_DELETE_BENEFICIARS_FINISHED = "REQUEST_DELETE_BENEFICIARS_FINISHED"

export const REQUEST_PUT_CHANGE_OBSERVATION_DATE = "REQUEST_PUT_CHANGE_OBSERVATION_DATE"
export const REQUEST_PUT_CHANGE_OBSERVATION_DATE_FINISHED = "REQUEST_PUT_CHANGE_OBSERVATION_DATE_FINISHED"

export const REQUEST_DELETE_OBSERVATION = "REQUEST_DELETE_OBSERVATION"
export const REQUEST_DELETE_OBSERVATION_FINISHED = "REQUEST_DELETE_OBSERVATION_FINISHED"

export const REQUEST_EXPORT_MISSING_EQUIPMENTS = "REQUEST_EXPORT_MISSING_EQUIPMENTS"
export const REQUEST_EXPORT_MISSING_EQUIPMENTS_FINSHED = "REQUEST_EXPORT_MISSING_EQUIPMENTS_FINSHED"

export const API_ERROR = "BENEFICIARS_API_ERROR"