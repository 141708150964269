import {
  REQUEST_ATTACHED_BENEFICIARIES_FINISHED,
  REQUEST_DETAILED_FOLLOWERS_FINISHED,
  REQUEST_POST_ANSWER_QUESTION_FINISHED,
  REQUEST_QUESTIONS_BY_FILTER_FINISHED,
  REQUEST_POST_FOLLOWER_CONTRACT_FINISHED,
  REQUEST_POST_COMPLETE_OBSERVATION_FINISHED,
  REQUEST_EXPORT_FOLLOWER_EXCEL_FINISHED
} from "./actionTypes"

const initialState = {
  list: [],
  DetailedFollowers: [],
  AttachedBeneficiaries: [],
  ObservationQuestions: []
}

const Followers = (state = initialState, { type, payload, error, meta }) => {
  if (error) return state;

  switch (type) {
    case REQUEST_ATTACHED_BENEFICIARIES_FINISHED:
      return { ...state, AttachedBeneficiaries: payload.list, count: payload.count }
    case REQUEST_QUESTIONS_BY_FILTER_FINISHED:
      return { ...state, ObservationQuestions: payload }
    case REQUEST_POST_ANSWER_QUESTION_FINISHED:
      return { ...state, AnswerQuestionPostResult: payload }
    case REQUEST_DETAILED_FOLLOWERS_FINISHED:
      return { ...state, DetailedFollowers: payload }
    case REQUEST_POST_FOLLOWER_CONTRACT_FINISHED:
      return { ...state, postContractResult: payload }
    case REQUEST_POST_COMPLETE_OBSERVATION_FINISHED:
      return { ...state, postCompleteObservationResult: payload }
    case REQUEST_EXPORT_FOLLOWER_EXCEL_FINISHED:
      return { ...state, followerExportResult: payload }
    default:
      return state
  }
}

export default Followers
