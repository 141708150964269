import { createSelector } from "reselect"
import {
  REQUEST_ATTACHED_BENEFICIARIES,
  REQUEST_DETAILED_FOLLOWERS,
  REQUEST_POST_COMPLETE_OBSERVATION,
  REQUEST_QUESTIONS_BY_FILTER
} from "store/followers/actionTypes";

export const selectAttachedBeneficiaries = state => state.Followers;
export const selectBeneficiarQuestions = state => state.Followers.ObservationQuestions;
export const selectLoadingBeneficiarQuestions = state => { return state.Requesting[REQUEST_QUESTIONS_BY_FILTER] };

export const selectFollowersState = state => state.Followers;
export const selectDetailedFollowers = state => state.Followers.DetailedFollowers;
export const selectQuestionsPostResult = createSelector(selectFollowersState, (follower) => follower.AnswerQuestionPostResult);
export const selectContractPostResult = createSelector(selectFollowersState, (follower) => follower.postContractResult);
export const selectFollowerExportResult = createSelector(selectFollowersState, (follower) => follower.followerExportResult);
export const selectCompleteObservationPostResult = createSelector(selectFollowersState, (follower) => follower.postCompleteObservationResult);
export const selectLoadingCompleteObservation = state => { return state.Requesting[REQUEST_POST_COMPLETE_OBSERVATION] };

export const selectLoadingAttachedBeneficiaries = state => { return state.Requesting[REQUEST_ATTACHED_BENEFICIARIES] };
export const selectLoadingDetailedFollowers = state => { return state.Requesting[REQUEST_DETAILED_FOLLOWERS] };
export const selectAttachedBeneficiariesState = state => state.Followers.AttachedBeneficiaries;

const selectPageState = createSelector(
  selectLoadingAttachedBeneficiaries, selectLoadingDetailedFollowers,
  (isLoadingAttachedBeneficiaries, isLoadingDetailedFollowers) => ({
    isLoadingAttachedBeneficiaries, isLoadingDetailedFollowers
  })
)

export default selectPageState;