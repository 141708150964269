import {
  REQUEST_COMMON_STATS,
  REQUEST_COMMON_STATS_FINISHED
} from "./actionTypes"



export const requestCommonStats = (payload,meta) => {
  return {
    type: REQUEST_COMMON_STATS,
    payload,
    meta
  }
}

export const requestCommonStatsFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_COMMON_STATS_FINISHED,
    payload,
    error,
    meta
  }
}


