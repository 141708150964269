import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Questions from "pages/Questions"
import PagesMaintenance from "pages/Utility/pages-maintenance"
import Pages404 from "pages/Utility/pages-404"
import Users from "pages/Users"
import Beneficiars from "pages/Beneficiars"
import ChangePassword from "pages/Authentication/ChangePassword"
import Results from "pages/Results"
import Reports from "pages/Reports"
import Equipments from "pages/Equipments"
import Accounting from "pages/Accounting"
import ResetPassword from "pages/Authentication/ResetPassword"
import NotFound from "pages/NotFound"

const authProtectedRoutes = [
  {
    id: 1,
    path: "/dashboard",
    title: 'Admin panel',
    allowedRoles: ['ROLE_MANAGER', 'ROLE_ADMIN'],
    component: <Dashboard />,
    subItems: [
      {
        id: 2,
        path: "/questions",
        title: 'Suallar',
        allowedRoles: ['ROLE_MANAGER', 'ROLE_ADMIN'],
        component: <Questions />
      },
      {
        id: 3,
        path: "/users",
        title: 'İstifadəçilər',
        allowedRoles: ['ROLE_ADMIN'],
        component: <Users />
      },
    ]
  },

  {
    id: 4,
    path: "/beneficiars",
    title: 'Benefisiarlar',
    allowedRoles: ['ROLE_MANAGER', 'ROLE_ADMIN'],
    component: <Beneficiars />,
    subItems: []
  },
  {
    id: 5,
    path: "/results",
    title: 'Nəticələr',
    allowedRoles: ['ROLE_FOLLOWER'],
    component: <Results />,
    subItems: []
  },
  {
    id: 6,
    path: "/reports",
    title: 'Hesabatlar',
    allowedRoles: ['ROLE_ADMIN', 'ROLE_REPORT'],
    component: <Reports />,
    subItems: []
  },
  {
    id: 7,
    path: "/equipments",
    title: 'Avadanlıqlar',
    allowedRoles: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_REPORT'],
    component: <Equipments />,
    subItems: []
  },
  {
    id: 8,
    path: "/accounting",
    title: 'Mühasibatlıq',
    allowedRoles: ['ROLE_ADMIN', 'ROLE_ACCOUNTANT'],
    component: <Accounting />,
    subItems: []
  },
]

const publicRoutes = [
  { id: "p1", path: "/logout", component: <Logout /> },
  { id: "p2", path: "/login", component: <Login /> },
  { id: "p3", path: "/forgot-password", component: <ForgetPwd /> },
  { id: "p4", path: "/register", component: <Register /> },
  { id: "p5", path: "/changePassword", component: <ChangePassword /> },
  { id: "p6", path: "/reset-password", component: <ResetPassword /> },
  { id: "p7", path: "*", component: <NotFound /> }
]

export { authProtectedRoutes, publicRoutes }

