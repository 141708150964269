import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  InputGroup,
  Label,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"
import { Link, Navigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"

// actions
import { requestLoginUser } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"
import { ReactComponent as Logo } from "assets/images/logo.svg"
import { ReactComponent as LogoLight } from "assets/images/ekvita-light.svg"
import LogoLightEkvita from "assets/images/Logo_ss.png"
import { REQUEST_LOGIN_USER, REQUEST_LOGIN_USER_FINISHED } from "store/auth/login/actionTypes"

const Login = props => {
  const { error, isRequesting } = useSelector(state => ({
    // error: state.Error[REQUEST_LOGIN_USER_FINISHED],
    isRequesting: state.Requesting[REQUEST_LOGIN_USER]
  }))

  const [showPassword, setShowPassword] = useState(false);
  //meta title
  document.title = "Login | Self Employment Project"

  const dispatch = useDispatch()

  // useEffect(()=>{
  //   window.location.reload(false);
  // },[])

  const form = useFormik({
    enableReinitialize: true,

    initialValues: {
      username:  "",
      password:  "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(requestLoginUser(values, props.router.navigate))
    },
  })

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="p-4">
                        <p className="text-primary">
                          Monitoring sisteminə davam etmək üçün daxil olun.
                        </p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <div className="avatar-md profile-user-wid mb-4 w-50 logo-dark-element">
                      <span className="avatar-title rounded-2  overflow-hidden">
                        <img style={{ width: "100%", objectFit: 'cover' }} src={LogoLightEkvita} />
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="avatar-md profile-user-wid mb-4 w-50 logo-light-element">
                      <span className="avatar-title rounded-2 bg-light dark-compatible p-4">
                        <LogoLight style={{ height: "50px" }} />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    <Form
                      className="form-horizontal"
                      onSubmit={e => {
                        e.preventDefault()
                        form.handleSubmit()
                        return false
                      }}
                    >
                      {error ? <Alert color="danger">{error}</Alert> : null}
                      <div className="mb-3">
                        <Label className="form-label"> İstifadəçi adı</Label>
                        <Input
                          autoComplete="username"
                          name="username"
                          className="form-control"
                          placeholder="Enter Username"
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.username || ""}
                          invalid={
                            form.touched.username && form.errors.username
                              ? true
                              : false
                          }
                        />
                        {form.touched.username && form.errors.username ? (
                          <FormFeedback type="invalid">
                            {form.errors.username}
                          </FormFeedback>
                        ) : null}
                      </div>

                      <Col className="mb-3">
                        <Label className="form-label">Şifrə</Label>
                        <InputGroup>
                          <Input
                            name="password"
                            type={`${showPassword ? 'text' : 'password'}`}
                            placeholder="Şifrəni daxil edin"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.password || ""}
                            autoComplete='new-password'
                            invalid={
                              form.touched.password && form.errors.password
                                ? true
                                : false
                            }
                          />
                          <a className="p-2" onClick={() => setShowPassword(!showPassword)}>
                            <i className={`${showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'} font-size-18`}></i>
                          </a>
                          {form.touched.password && form.errors.password ? (
                            <FormFeedback type="invalid">
                              {form.errors.password}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </Col>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Məni xatırla
                        </label>
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          {isRequesting && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>} {" "}
                          Daxil ol
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to="/forgot-password" className="text-muted">
                          <i className="mdi mdi-lock me-1" />
                          Şifrəni unutmusunuz?
                        </Link>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

const withAuthRedirect = Component => {
  const AuthRedirectWrapper = props => {
    if (localStorage.getItem("authToken")) {
      return <Navigate to="/" />
    }

    return <Component {...props} />
  }
  return AuthRedirectWrapper
}

export default withRouter(withAuthRedirect(Login))

// export default withRouter(Login)
Login.propTypes = {
  history: PropTypes.object,
}
