import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Card, CardBody, CardTitle, Progress, UncontrolledTooltip } from "reactstrap"
import Doughnut from "./doughnutchart"
import "./Monitoring.scss"
import { requestObservationsCount } from "store/observationCount/actions"
import { requestCommonStats } from "store/commonStats/actions"
import StatisticSkeleton from "components/Common/StatisticSkeleton"

const Monitoring = props => {
  const ObservationsCount = useSelector(state => state.ObservationsCount.observationStatistics)
  const filtersCompanyTraining = useSelector(state => state.Beneficiars.filters.companyTraining);
  const filter = useSelector(state => state.Beneficiars.filters)
  const CommonStats = useSelector(state => state.CommonStats.list)
  const [observationData, setObservationData] = useState([])
  const [isFirstLoad, setIsfirstLoad] = useState(true);
  const [isStatsLoaded, setIsStatsLoaded] = useState(false)
  const user = useSelector(state => state.Auth);

  const dispatch = useDispatch();

  const BarGenerator = (Data) => {
    return (
      Data.observationCounts.map((item, index) => {
        const title = `Müşahidə səfəri ${item.observationId}`
        const progress = (item.count / ObservationsCount.beneficiarCount) * 100
        const colors = ['success', 'warning', 'danger', 'dark']

        return <div key={item.observationId}>
          <div className="mb-4" style={{ height: '80px' }}>
            <p className="card-title-desc mb-1"> {title}</p>
            <span style={{ color: "#ababab" }}>{item.count} nəfər</span>
            <Progress
              className="progress-xl"
              color={colors[index]}
              value={progress}
              animated
            />
          </div>
        </div>
      })
    )
  }

  const statCard = (icon, count, title) => {
    return (
      <Card style={{height:"150px"}}>
        <CardBody className="p-4">
          <div className="d-flex justify-content-between">
            <div className="IconWrapper">
              <img src={require(`../../../assets/images/icons/${icon}`)} />
            </div>
            <div className="cardInfo">
              <CardTitle style={{height:"50px"}} className="font-size-24">{count || " 0 "}</CardTitle>
              <span className="text-dark font-size-14" >{title || " - "}</span>
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }

  useEffect(() => {
    dispatch(requestObservationsCount(filter));
    dispatch(requestCommonStats(filter, { isFirstLoad: isFirstLoad }));
    setIsfirstLoad(false);
  }, [filter]);

  useEffect(() => {
    setObservationData(ObservationsCount);
  }, [ObservationsCount]);

  useEffect(() => {
    if (observationData && CommonStats && Object.keys(observationData).length > 0 && Object.keys(CommonStats).length > 0) {
      setIsStatsLoaded(true)
    }
  }, [observationData, CommonStats, observationData])


  return (
    <React.Fragment>
      {<Row className="justify-content-between">
        <Col sm={12} md={6} xl={3}>
          {
            isStatsLoaded ? statCard("userGroup.png", observationData?.beneficiarCount, "Cəmi aktiv alanlar")
              : <StatisticSkeleton height="13vh" />
          }

        </Col>

        <Col sm={12} md={6} xl={3}>
          {
            isStatsLoaded ? statCard("regions.png", observationData?.regionCount, "Rayonlar")
              : <StatisticSkeleton height="13vh" />
          }
        </Col>

        <Col sm={12} md={6} xl={3}>
          {
            isStatsLoaded ? statCard("operator.png", observationData?.followerCount, "Müşahidəçilər")
              : <StatisticSkeleton height="13vh" />
          }
        </Col>

        <Col sm={12} md={6} xl={3}>
          {
            isStatsLoaded ? statCard("observation.png", observationData?.totalObservation, "Müşahidə səfərləri")
              : <StatisticSkeleton height="13vh" />
          }
        </Col>
      </Row>}

      <Row className="justify-content-around">
        {<>
          <Col sm={12} md={6} xl={3}>
            <Card >
              {isStatsLoaded ? <CardBody className="p-2">
                <CardTitle className="p-2" style={{height:"50px"}}>Müşahidə səfərləri üzrə</CardTitle>
                <div id="doughnut-chart" className="e-chart">
                  <Doughnut
                    dataColors='["--bs-dark","--bs-success", "--bs-primary","--bs-info", "--bs-warning"]'
                    data={{
                      Labels: [
                        `Müşahidə olunub  ${CommonStats.beneficiaryStatistics.observed}`,
                        `Müşahidə olunmayıb  ${CommonStats.beneficiaryStatistics.nonObserved}`,
                        `Şərait yaratmır  ${CommonStats.beneficiaryStatistics.ignored}`],
                      ChartHeader: "Benefisiarlar",
                      Items: [
                        { value: CommonStats.beneficiaryStatistics.observed, name: `Müşahidə olunub  ${CommonStats.beneficiaryStatistics.observed}` },
                        { value: CommonStats.beneficiaryStatistics.nonObserved, name: `Müşahidə olunmayıb  ${CommonStats.beneficiaryStatistics.nonObserved}` },
                        { value: CommonStats.beneficiaryStatistics.ignored, name: `Şərait yaratmır  ${CommonStats.beneficiaryStatistics.ignored}` },
                      ]

                    }}
                  />
                </div>
              </CardBody> : <StatisticSkeleton height="408px" />
              }
            </Card>
          </Col>

          <Col sm={12} md={6} xl={3}>
            <Card>
              {isStatsLoaded ? <CardBody className="p-2">
                <CardTitle className="p-2" style={{height:"50px"}}>Aktivlərin quraşdırılması üzrə</CardTitle>
                <div id="doughnut-chart" className="e-chart">
                  <Doughnut
                    dataColors='["--bs-dark","--bs-success", "--bs-primary","--bs-info", "--bs-warning"]'
                    data={{
                      Labels: [
                        `Aktiv quraşdırılıb ${CommonStats.beneficiaryStatistics.configured}`,
                        `Aktiv quraşdırmayıb ${CommonStats.beneficiaryStatistics.nonConfigured}`
                      ],
                      ChartHeader: "Avadanlıq statistikaları",
                      Items: [
                        { value: CommonStats.beneficiaryStatistics.configured, name: `Aktiv quraşdırılıb ${CommonStats.beneficiaryStatistics.configured}` },
                        { value: CommonStats.beneficiaryStatistics.nonConfigured, name: `Aktiv quraşdırmayıb ${CommonStats.beneficiaryStatistics.nonConfigured}` },
                      ]
                    }} />
                </div>
              </CardBody> : <StatisticSkeleton height="408px" />
              }
            </Card>
          </Col>

          <Col sm={12} md={6} xl={3}>
            <Card>
              {isStatsLoaded ? <CardBody className="p-2">
                <CardTitle className="p-2" style={{height:"50px"}}>VÖEN üzrə</CardTitle>
                <div id="doughnut-chart" className="e-chart">
                  <Doughnut
                    dataColors='["--bs-dark","--bs-success", "--bs-primary","--bs-info", "--bs-warning"]'
                    data={{
                      Labels: [
                        `VÖEN olan  ${CommonStats.beneficiaryStatistics.voen}`,
                        `VÖEN olmayan  ${CommonStats.beneficiaryStatistics.nonVoen}`
                      ],
                      ChartHeader: "VÖEN statistikaları",
                      Items: [
                        { value: CommonStats.beneficiaryStatistics.voen, name: `VÖEN olan  ${CommonStats.beneficiaryStatistics.voen}` },
                        { value: CommonStats.beneficiaryStatistics.nonVoen, name: `VÖEN olmayan  ${CommonStats.beneficiaryStatistics.nonVoen}` },
                      ]
                    }} />
                </div>
              </CardBody> : <StatisticSkeleton height="408px" />
              }
            </Card>
          </Col>
        </>}
        {<Col sm={12} md={6} xl={3}>
          <Card>
            {isStatsLoaded ? <CardBody className="p-2">
              <CardTitle style={{height:"50px"}} className="h4 p-2 mb-5">Müşahidə səfərləri</CardTitle>
              <div style={{ height: '408px' }}>
                {BarGenerator(observationData)}
              </div>
            </CardBody> : <StatisticSkeleton height="408px" />
            }
          </Card>
        </Col>}
      </Row>

    </React.Fragment>
  )
}
export default Monitoring
