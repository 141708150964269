import { REQUEST_QA_FINISHED } from "./actionTypes"

const initialState = {
  list: [],
}

const Beneficiars = (state = initialState, { type, payload, error, meta }) => {
  if (error) return state

  switch (type) {
    case REQUEST_QA_FINISHED:
      return { ...state, list: payload }
    default:
      return state
  }
}

export default Beneficiars
