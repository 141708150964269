import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Collapse, Container, Label, Row } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { withTranslation } from "react-i18next"
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { requestFollowers, saveBeneficiarsFilter } from "store/beneficiars/actions"
import { requestBeneficiarImages } from "store/beneficiar-images/actions"
import { requestRegions } from "store/actions"
import { selectRegions } from "selectors/UsersSelectors"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import BeneficiarGalleryModal from "./BeneficiarGallery"
import Monitoring from "./Monitoring"
import BeneficiarFilter from "./BeneficiarFilter"
import BeneficiarTable from "./BeneficiarTable"
import { REQUEST_COMMON_STATS } from "store/commonStats/actionTypes";
import { selectCompanyTraining } from "selectors/BeneficiarisSelectors";

ChartJS.register(ArcElement, Tooltip);


const Dashboard = props => {
  document.title = "Idarə paneli | Self Employment Project"
  const dispatch = useDispatch();

  //selectors
  const selectedBeneficiarImages = useSelector(state => state.BeneficiarImages.list)
  const followers = useSelector(state => state.Beneficiars.followers);
  const statsLoading = useSelector(state => { return state.Requesting[REQUEST_COMMON_STATS] });
  const regions = useSelector(selectRegions);
  const companyTraining = useSelector(selectCompanyTraining);
  //states
  const [filter, setFilter] = useState({});
  const [GalleryMode, setGalleryMode] = useState("");
  const [selectedBeneficiar, setSelectedBeneficiar] = useState(null);

  const [accordionState, setAccordionState] = useState(true);


  function handleViewGallery() {
    setGalleryMode(prevMode => prevMode === "view" ? "" : "view");
    setSelectedBeneficiar(null)
  }

  const onFilterSubmit = (filter) => {
    dispatch(saveBeneficiarsFilter({ ...filter, companyTraining }));
  }

  // useEffect(() => {
  //  dispatch(saveBeneficiarsFilter({ companyTraining }));
  // }, [companyTraining]);


  useEffect(() => {
    if (!Object.keys(followers).length > 0) dispatch(requestFollowers())
    if (!Object.keys(regions).length > 0) dispatch(requestRegions())
  }, [dispatch]);

  useEffect(() => {
    if (selectedBeneficiar) {
      dispatch(requestBeneficiarImages(selectedBeneficiar.beneficiarId))
      setGalleryMode("view")
    }
  }, [selectedBeneficiar, dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={props.t("")}
            breadcrumbItem={props.t("")}
          />

          <Row>
            {/* <Col xs={12}>
              <div className="accordion-item">
                <Card>
                  <CardBody>
                    <button
                      className="accordion-button fw-medium font-size-20" //{"accordion-button", "fw-medium", { collapsed: !accordionState }}
                      type="button"
                      onClick={() => setAccordionState(!accordionState)}
                      style={{ cursor: "pointer" }}
                    >
                      Statistikalar <div className="me-2"></div>
                      {statsLoading ? <span
                        className="spinner-border spinner-border-sm me-3"
                        role="status"
                        aria-hidden="true"
                      ></span> : <i className={`mdi ${accordionState ? "mdi-chevron-down" : "mdi-chevron-right"} font-size-24 mt-1`}></i>}
                    </button>
                  </CardBody>

                </Card>

                <Collapse isOpen={accordionState} className="accordion-collapse">
                  <div className="accordion-body">
                    <Monitoring />
                  </div>
                </Collapse>
              </div>

            </Col> */}

            <Col xs={12}>
              <Monitoring />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                    <b className="m-1 w-100">Axtarış bazasını seç: </b>
                    <div className="m-1">
                      <Button
                        name="All"
                        className="btn"
                        color="primary"
                        outline={companyTraining !== "ALL" ? true : false}
                        onClick={(e) => {
                          e.preventDefault()
                          dispatch(saveBeneficiarsFilter({ companyTraining: "ALL" }))
                        }}
                        active={companyTraining === "ALL" ? true : false}
                      >
                        HAMISI
                      </Button>
                    </div>
                    <div className="m-1">
                      <Button
                        name="LİQ"
                        className="btn"
                        color="primary"
                        outline={companyTraining !== "LİQ" ? true : false}
                        onClick={(e) => {
                          e.preventDefault()
                          dispatch(saveBeneficiarsFilter({ companyTraining: companyTraining !== "LİQ" ? "LİQ" : "ALL" }))
                        }}
                        active={companyTraining === "LİQ" ? true : false}
                      >
                        LİQ təlimləri üzrə
                      </Button>
                    </div>
                    <div className="m-1">
                      <Button
                        name="Konsultant"
                        className="btn"
                        color="primary"
                        outline={companyTraining !== "Konsultant" ? true : false}
                        onClick={(e) => {
                          e.preventDefault()
                          dispatch(saveBeneficiarsFilter({ companyTraining: companyTraining !== "Konsultant" ? "Konsultant" : "ALL" }))
                        }}
                        active={companyTraining === "Konsultant" ? true : false}
                      >
                        KONSORSİUM təlimləri üzrə
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <BeneficiarFilter filterSetter={setFilter} isGeneral={true} onFilterSubmit={onFilterSubmit} />
            </Col>
          </Row>
          <Row>
            <Col>
              <BeneficiarTable beneficiarSetter={setSelectedBeneficiar} followers={followers} />
            </Col>
          </Row>

        </Container>
        <BeneficiarGalleryModal
          toggle={handleViewGallery}
          isOpen={GalleryMode === "view"}
          images={selectedBeneficiarImages}
          selectedBeneficiar={selectedBeneficiar}
          isGeneral={true}
        />
      </div>

    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
