import React from "react"

export default function ConfirmtionBody({ type = "", text }) {
  return (
    <>
      {type === "delete" && (
        <div className="avatar-sm mb-4 mx-auto">
          <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
            <i className="mdi mdi-trash-can-outline"></i>
          </div>
        </div>
      )}
      <p className="text-muted font-size-16 mb-4 text-center">{text}</p>
    </>
  )
}
