import React from "react"

export const userColumns = [
  {
    header: "Ad Soyad",
    accessorKey: "fullName",
  },
  {
    header: "İstifadəçi adı",
    accessorKey: "userName",
  },
  {
    header: "Rayon",
    accessorKey: "region",
  },
  {
    header: "Mobil",
    accessorKey: "mobile",
  },
  {
    header: "Rol",
    accessorKey: "role",
    cell: ({ getValue }) => {
      switch (getValue()) {
        case 'ROLE_FOLLOWER':
          return 'Müşahidəçi'
        case 'ROLE_ADMIN':
          return 'Admin'
        case 'ROLE_REPORT':
          return 'Reporter'
        case 'ROLE_MANAGER':
          return 'Menecer'
        case 'ROLE_ACCOUNTANT':
          return 'Mühasib'
        default:
          return ' - '
      }
    }
  },
  {
    header: "Müşahidə səfəri",
    accessorKey: "observerTraining",
    cell: ({ getValue }) => {
      return <p style={{ color: getValue() === false && '#d79090' }}>
        {getValue() === true ? 'İştirak edib' : 'İştirak etməyib'}
      </p>
    }
  },
  {
    header: "Biznes Təlimi",
    accessorKey: "businessTraining",
    cell: ({ getValue }) => {
      return <p style={{ color: getValue() === false && '#d79090' }}>
        {getValue() === true ? 'İştirak edib' : 'İştirak etməyib'}
      </p>
    }
  },
  {
    header: "Status",
    accessorKey: "isActive",
    cell: ({ getValue }) => {
      return <p>
        {getValue() === 1 ? "Aktiv" : "Deaktiv"}
      </p>
    }
  },
]
