import { useFormik } from "formik"
import React, { useState } from "react"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"
import { Col, Form, FormFeedback, Input, InputGroup, Label, Row, Button } from "reactstrap"
import { selectLoadingPostUser } from "selectors/UsersSelectors";
import { requestPostUser } from "store/actions";
import * as Yup from "yup"

const Roles = [
  {
    value: "ROLE_ADMIN",
    label: "Admin"
  },
  {
    value: "ROLE_MANAGER",
    label: "Menecer"
  },
  {
    value: "ROLE_REPORT",
    label: "Reporter"
  },
  {
    value: "ROLE_FOLLOWER",
    label: "Müşahidəçi"
  },
  {
    value: "ROLE_ACCOUNTANT",
    label: "Mühasib"
  }
]


export default function UserForm({ user = {}, regions = [], mode = "" }) {
  const [showPassword, setShopPassword] = useState(false);
  const isLoadingPostUser = useSelector(selectLoadingPostUser);
  const dispatch = useDispatch();
  function handleAddOrUpdate(user) {
    dispatch(requestPostUser(user))
  }

  const form = useFormik({
    enableReinitialize: true,
    // autoComplete:"new-password",
    // autoFill: "new-password",
    initialValues: {
      id: user.id || 0,
      fullname: user.fullName || "",
      username: user.userName || "",
      regionId: user.regionId || "",
      email: user.email || "",
      mobile: user.mobile || "",
      observerTraining: user.observerTraining === true ? "true" : "false",
      businessTraining: user.businessTraining === true ? "true" : "false",
      role: user.role || "",
      isActive: user.isActive === 1 ? "true" : "false",
      address: "",
      password: "",
      mode: mode
    },
    validationSchema: Yup.object({
      fullname: Yup.string().required("Ad Soyad doldurulmayıb"),
      username: Yup.string().required("Istifadəçi adı doldurulmayıb"),
      email: Yup.string().email("Email düzgün doldurulmayıb").required("Email adı doldurulmayıb"),
      mobile: Yup.string().required("Mobil adı doldurulmayıb"),
      regionId: Yup.number().required("Region seçilməyib"),
      role: Yup.string().required("Rol doldurulmayıb"),
      address: Yup.string().when('mode',
        {
          is: (mode) => mode === "add",
          then: Yup.string().required("Ünvan adı doldurulmayıb"),
          otherwise: Yup.string()
        }),
      password: Yup.string().when('mode',
        {
          is: (mode) => mode === "add",
          then: Yup.string().required("Şifrə doldurulmayıb").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, "Şifrə az 8 karakterə, ən az bir böyük və bir kiçik hərfə , ən az bir rəqəmə sahib olmalıdır və yalnız ingiliz əlifbası istifadə olunmalıdır"),
          otherwise: Yup.string()
        }),
    }),
    onSubmit: values => {
      const formattedUser = {
        Id: values.id,
        FullName: values.fullname,
        UserName: values.username,
        RegionId: values.regionId,
        Email: values.email,
        Mobile: values.mobile,
        ObserverTraining: values.observerTraining === "true" ? true : false,
        BusinessTraining: values.businessTraining === "true" ? true : false,
        Role: values.role,
        IsActive: values.isActive === "true" ? 1 : 0,
        Address: values.address,
        Password: values.password
      }
      handleAddOrUpdate(formattedUser)
    }
  })

  return (
    <Form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault()
        form.handleSubmit()
        return false
      }}
    >
      <Input hidden autoComplete="false" />
      <Row>

        {/* Fullname */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Ad Soyad</Label>
            <Input
              name="fullname"
              type="text"
              placeholder="Ad Soyadı daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.fullname || ""}
              invalid={
                form.touched.fullname && form.errors.fullname
                  ? true
                  : false
              }
            />
            {form.touched.fullname && form.errors.fullname ? (
              <FormFeedback type="invalid">
                {form.errors.fullname}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* User Name */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">İstifadəçi adı</Label>
            <Input
              name="username"
              type="text"
              placeholder="istifadəçi adını daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.username || ""}
              invalid={
                form.touched.username && form.errors.username
                  ? true
                  : false
              }
            />
            {form.touched.username && form.errors.username ? (
              <FormFeedback type="invalid">
                {form.errors.username}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {mode === "add" && <Row className="m-0 p-0">
          <Col className="col-12 col-lg-6">
            <div className="mb-3">
              <Label className="form-label">Ünvan</Label>
              <Input
                name="address"
                type="textarea"
                rows={3}
                placeholder="Ünvanı daxil edin"
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                value={form.values.address || ""}
                invalid={
                  form.touched.address && form.errors.address
                    ? true
                    : false
                }
              />
              {form.touched.address && form.errors.address ? (
                <FormFeedback type="invalid">
                  {form.errors.address}
                </FormFeedback>
              ) : null}
            </div>
          </Col>

          <Col className="col-12 col-lg-6">
            <div className="mb-3">
              <Label className="form-label">Şifrə</Label>
              <InputGroup>
                <Input
                  name="password"
                  type={`${showPassword ? 'text' : 'password'}`}
                  placeholder="Şifrəni daxil edin"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.password || ""}
                  autoComplete='new-password'
                  invalid={
                    form.touched.password && form.errors.password
                      ? true
                      : false
                  }
                />
                <a className="p-2" onClick={() => setShopPassword(!showPassword)}>
                  <i className={`${showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'} font-size-18`}></i>
                </a>
                {form.touched.password && form.errors.password ? (
                  <FormFeedback type="invalid">
                    {form.errors.password}
                  </FormFeedback>
                ) : null}
              </InputGroup>

            </div>
          </Col>



        </Row>}

        {/* Role */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Rol</Label>
            <Input
              name="role"
              type="select"
              placeholder="Enter role"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.role || ""}
              invalid={
                form.touched.role && form.errors.role
                  ? true
                  : false
              }
            >
              <option value=''>Rol seçin</option>
              {Roles.map((role) => {
                return (
                  <option key={role.value} value={role.value}>{role.label}</option>
                )
              })}

            </Input>
            {form.touched.role && form.errors.role ? (
              <FormFeedback type="invalid">
                {form.errors.role}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* Regions */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Rayon</Label>
            <Input
              name="regionId"
              type="select"
              placeholder="Select Region"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.regionId || ""}
              invalid={
                form.touched.regionId && form.errors.regionId
                  ? true
                  : false
              } >
              <option value=''>Rayon seçin</option>
              {regions.map((region) => {
                return (
                  <option key={region.id} value={region.id}>{region.name}</option>
                )
              })}
            </Input>
            {form.touched.region && form.errors.region ? (
              <FormFeedback type="invalid">
                {form.errors.region}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* Email */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Email</Label>
            <Input
              name="email"
              type="text"
              placeholder="Emaili daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.email || ""}
              invalid={
                form.touched.email && form.errors.email
                  ? true
                  : false
              }
            />
            {form.touched.email && form.errors.email ? (
              <FormFeedback type="invalid">
                {form.errors.email}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* Mobile */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Mobile</Label>
            <Input
              name="mobile"
              type="text"
              placeholder="Mobil nömrəni daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.mobile || ""}
              invalid={
                form.touched.mobile && form.errors.mobile
                  ? true
                  : false
              }
            />
            {form.touched.mobile && form.errors.mobile ? (
              <FormFeedback type="invalid">
                {form.errors.mobile}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* observerTraining */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Müşahidə səfəri təlimində iştirak edib</Label>
            <Input
              name="observerTraining"
              type="select"
              placeholder="Enter observerTraining"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.observerTraining || ""}
              invalid={form.touched.observerTraining && form.errors.observerTraining ? true : false}
            >
              <option value={true}>Bəli</option>
              <option value={false}>Xeyr</option>
            </Input>
            {form.touched.observerTraining && form.errors.observerTraining ? (
              <FormFeedback type="invalid">
                {form.errors.observerTraining}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        {/* businessTraining */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Biznes təlimində iştirak edib</Label>
            <Input
              name="businessTraining"
              type="select"
              placeholder="Enter businessTraining"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.businessTraining || ""}
              invalid={
                form.touched.businessTraining && form.errors.businessTraining
                  ? true
                  : false
              }
            >
              <option value={true}>Bəli</option>
              <option value={false}>Xeyr</option>
            </Input>

            {form.touched.businessTraining && form.errors.businessTraining ? (
              <FormFeedback type="invalid">
                {form.errors.businessTraining}
              </FormFeedback>
            ) : null}
          </div>
        </Col>


        <Col className="col-12 col-lg-6"></Col>

        {/* Status */}
        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Status</Label>
            <Input
              name="isActive"
              type="select"
              placeholder="Enter isActive"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.isActive || ""}
              invalid={
                form.touched.isActive && form.errors.isActive
                  ? true
                  : false
              }
            >
              <option value={true}>Aktiv</option>
              <option value={false}>Deaktiv</option>
            </Input>

            {form.touched.isActive && form.errors.isActive ? (
              <FormFeedback type="invalid">
                {form.errors.isActive}
              </FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="text-end">
            <Button
              type="submit"
              className="btn btn-success save-user"
              disabled={isLoadingPostUser}
            >
              {isLoadingPostUser && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>} {" "}
              {mode === "edit" ? "Yaddaşa yaz" : "Əlavə et"}
            </Button>
          </div>
        </Col>
      </Row>
    </Form >
  )
}
