import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Form,
  Input,
  Label,
  Button,
} from "reactstrap"
import { useFormik } from "formik"
import { useDispatch } from "react-redux"
import { requestAttachedBeneficiaries } from "store/followers/actions"


const ObservationQuestionsFilter = ({setFilters}) => {
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      fullName: "",
      fin: "",
      observationId: "",
      nonObservationId: "",
      equipmentDeliveredFromDate: "",
      equipmentDeliveredToDate: "",
    },
    onSubmit: values => {
      setFilters(values);
    },
  })

  const Monitoring = [
    {
      id: 1,
      title: "Müşahidə səfəri 1",
    },
    {
      id: 2,
      title: "Müşahidə səfəri 2",
    },
    {
      id: 3,
      title: "Müşahidə səfəri 3",
    },
    {
      id: 4,
      title: "Müşahidə səfəri 4",
    }
  ]


  const MonitoringDropdownItems = () => {
    return (
      <>
        <option value="">Bütün müşahidə səfərləri</option>

        {Monitoring.map(Monitoring => {
          return (
            <option key={Monitoring.id} value={Monitoring.id} onClick={form.handleChange}>
              {Monitoring.title}
            </option>
          )
        })}
      </>
    )
  }

  return (
    <React.Fragment>
      <Card className="">
        <CardBody className="">
          <Row>
            <Col>
              <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                <Form
                  className="filter form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    form.handleSubmit()
                    return false
                  }}
                >

                  <Row className="primary-menu ">
                    {/* Ad Soyad */}
                    <Col className="col-6 col-xl-3">
                      <Label className="form-label">Ad Soyad</Label>
                      <Input
                        name="fullName"
                        className="form-control"
                        placeholder="Ad və soyad"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.fullName || ""}
                        invalid={
                          form.touched.fullName && form.errors.fullName
                            ? true
                            : false
                        }
                      />
                    </Col>

                    {/* FIN */}
                    <Col className="col-6 col-xl-3">
                      <Label className="form-label">FIN Kod</Label>
                      <Input
                        name="fin"
                        className="form-control"
                        placeholder="FIN kod"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.fin || ""}
                        invalid={
                          form.touched.fin && form.errors.fin
                            ? true
                            : false
                        }
                      />
                    </Col>

                    {/* Keçilən müşahidə səfərləri */}
                    <Col className="col-12 col-xl-3">
                      <Label className="form-label">
                        Keçilən müşahidə səfərləri
                      </Label>
                      <Input
                        name="observationId"
                        type="select"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.observationId || ""}
                        invalid={
                          form.touched.observationId &&
                            form.errors.observationId
                            ? true
                            : false
                        }

                      >
                        {MonitoringDropdownItems()}
                      </Input>
                    </Col>

                    {/* Keçilməyən müşahidə səfərləri */}
                    <Col className="col-12 col-xl-3">
                      <Label className="form-label">
                        Keçilməyən müşahidə səfərləri
                      </Label>
                      <Input
                        name="nonObservationId"
                        type="select"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.nonObservationId || ""}
                        invalid={
                          form.touched.nonObservationId &&
                            form.errors.nonObservationId
                            ? true
                            : false
                        }

                      >
                        {MonitoringDropdownItems()}
                        <option value={5} onClick={form.handleChange}>  Şərait yaratmadı </option>
                      </Input>
                    </Col>

                    
                    <Col className={"col-6 col-xl-3"}>
                      <Label className="form-label">Aktiv verilmə tarixindən</Label>
                      <Input
                        name="equipmentDeliveredFromDate"
                        type="date"
                        className="form-control"
                        placeholder=""
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.equipmentDeliveredFromDate || ""}
                        invalid={
                          form.touched.equipmentDeliveredFromDate &&
                            form.errors.equipmentDeliveredFromDate
                            ? true
                            : false
                        }
                      />
                    </Col>

                    <Col className={"col-6 col-xl-3"}>
                      <Label className="form-label">Aktiv verilmə tarixinə</Label>
                      <Input
                        name="equipmentDeliveredToDate"
                        type="date"
                        className="form-control"
                        placeholder=""
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.equipmentDeliveredToDate || ""}
                        invalid={
                          form.touched.equipmentDeliveredToDate &&
                            form.errors.equipmentDeliveredToDate
                            ? true
                            : false
                        }
                      />
                    </Col>
                  </Row>

                  <div className="d-flex filter-icon-wrapper justify-content-end">
                    <Button type="submit" className="bg-primary">
                      Axtar
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default ObservationQuestionsFilter
