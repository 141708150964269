import formatDate from "helpers/format-date";
import React from "react"
import FollowerSelect from "pages/Dashboard/BeneficiarTable/beneficiar-cells/FollowerSelect";


export const beneficiarColumns = [
  {
    header: "Şəxsi məlumatlar",
    id: "personal",
    meta: {
      align: 'center',
      border: {
        bottom: "1px solid #c9c9c9",
      },
    },
    columns: [
      {
        header: () => {
          return <div className="text-center"> Ad Soyad </div>
        },
        accessorKey: "fullName",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '150px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center"> FIN kod </div>
        },
        accessorKey: "fin",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '100px' }}>{getValue()}</p>

        }
      },
      {
        header: () => {
          return <div className="text-center"> Qrup kodu </div>
        },
        accessorKey: "groupCode",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '100px' }}>{getValue()}</p>
        }
      },
      {
        header: () => {
          return <div className="text-center">  Ünvan </div>
        },
        accessorKey: "address",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '150px' }}> {getValue()} </p>
        },
      },
      {
        header: () => {
          return <div className="text-center"> Rayon </div>
        },
        accessorKey: "region",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '100px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center">
            Zərf
          </div>
        },
        accessorKey: "business",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '100px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center">
            Şirkət təlimləri
          </div>
        },
        accessorKey: "trainingType",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '100px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center"> Xüsusi xidmətlər </div>
        },
        accessorKey: "specialServices",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '150px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center"> Əlaqə nömrələri </div>
        },
        accessorKey: "contactNumbers",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '150px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center"> Əlillik </div>
        },
        accessorKey: "disabled",
        cell: ({ getValue }) => {
          return (
            <p style={{ minWidth: '90px' }}>{getValue()}</p>
          )
        }
      },
      {
        header: () => {
          return <div className="text-center"> ÜDSY </div>
        },
        accessorKey: "udys",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '90px' }}>{getValue() ? "Bəli" : "Xeyr"}</p>
        }
      },
      {
        header: () => {
          return <div className="text-center"> Məcburi köçkündür </div>
        },
        accessorKey: "idp",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '70px' }}>{getValue() ? "Bəli" : "Xeyr"}</p>
        }
      },
      {
        header: () => {
          return <div className="text-center"> Qiymətləndirmə </div>
        },
        accessorKey: "assestmentDate",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '150px' }}>{formatDate(getValue())}</p>

        }
      },
      {
        header: () => {
          return <div className="text-center"> Aktivlərin verilmə tarixi </div>
        },
        accessorKey: "equipmentDeliveryDate",
        meta: {
          border: {
            right: "1px solid #d9d9dc",
          }
        },
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '150px' }}>{formatDate(getValue())}</p>

        }
      },
      {
        header: () => {
          return <div className="text-center"> Cins </div>
        },
        accessorKey: "gender",
        cell: ({ getValue }) => {
          return <p style={{ minWidth: '50px' }}>{getValue()?.toLowerCase() === "f" ? "Qadın" : "Kişi"}</p>

        }
      },
    ],
  },
  
]