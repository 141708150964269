import { useFormik } from "formik"
import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Form, FormFeedback, Input, Button, Label, Row } from "reactstrap"
import Select from 'react-select';
import { selectLoadingPostQuestion } from "selectors/QuestionSelectors";
import { requestPostQuestion } from "store/actions"
import * as Yup from "yup"


const Observations = [
  {
    value: 1,
    label: "Müşahidə səfəri 1"
  },
  {
    value: 2,
    label: "Müşahidə səfəri 2"
  },
  {
    value: 3,
    label: "Müşahidə səfəri 3"
  },
  {
    value: 4,
    label: "Müşahidə səfəri 4"
  }
]


export default function QuestionForm({ question = {}, parentQuestionId = 0, answerTypes, mode = "" }) {
  const isLoadingPostQuestions = useSelector(selectLoadingPostQuestion);
  const [selectedObservations, setSelectedObservations] = useState([]);
  const [formattedAnswerTypes, setFormattedAnswerTypes] = useState([])
  const [selectedAnswerType, setSelectedAnswerType] = useState(null);
  const [answers, setAnswers] = useState([]);

  const dispatch = useDispatch();
  function handleAddOrUpdate(question) {
    dispatch(requestPostQuestion(question))
  }

  useEffect(() => {
    if (Object.keys(question).length > 0) {
      question.observationId.map((oId) => {
        Observations.map((observation) => {
          if (oId === observation.value)
            setSelectedObservations(selectedObservations => [...selectedObservations, observation])
        })
      })
      setAnswers(question.answers);
    }
  }, [question]);

  useEffect(() => {
    if (Object.keys(question).length > 0) {
      formattedAnswerTypes.map((answerType) => {
        if (answerType.value === question.answerTypeId)
          setSelectedAnswerType(answerType);
      })
    }
  }, [formattedAnswerTypes]);

  useEffect(() => {
    if (selectedAnswerType) {
      form.setFieldValue("answerTypeId", selectedAnswerType.value)
      if (selectedAnswerType.label !== "dropdown" && selectedAnswerType.label !== "multi-dropdown") {
        setAnswers([])
      }
    } else form.setFieldValue("answerTypeId", answerTypes[0].id);
  }, [selectedAnswerType])

  useEffect(() => {
    answerTypes.map((answerType) => {
      let newFormattedType = {
        value: answerType.id,
        label: answerType.name
      }
      setFormattedAnswerTypes(formattedAnswerTypes => [...formattedAnswerTypes, newFormattedType])
    })
  }, [])

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: question.id || 0,
      content: question.content || "",
      answerTypeId: question.answerTypeId || "",
      additionalNote: question.additionalNote || "",
      observationId: question.observationId || [],
    },
    validationSchema: Yup.object({
      content: Yup.string().required("Please enter content"),
    }),
    onSubmit: values => {
      let correctedAnswers = [];
      let correctObservations = [];

      answers.map((item) => {
        if (typeof item.answerId === "string") {
          const formattedItem = {
            id: 0,
            content: item.content,
            status: 1,
          }
          correctedAnswers.push(formattedItem)
        } else {
          const formattedItem = {
            id: item.answerId,
            content: item.content,
            status: 1,
          }
          correctedAnswers.push(formattedItem)
        }
      });

      selectedObservations.map((item) => {
        correctObservations.push(item.value)
      })

      const formattedQuestion = {
        id: values.id,
        content: values.content,
        answerTypeId: values.answerTypeId,
        additionalNote: values.additionalNote,
        observationIds: correctObservations,
        answers: correctedAnswers,
        parentQuestionId: parentQuestionId
      }
      handleAddOrUpdate(formattedQuestion)
    }
  })

  return (
    <Form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault()
        form.handleSubmit()
        return false
      }}
    >
      <Input hidden autoComplete="false" />
      <Row>
        <Col className="col-lg-12 col-md-5">

          <div className="mb-3">
            <Label className="form-label">Sualın məzmunu</Label>
            <Input
              name="content"
              type="textarea"
              rows={3}
              placeholder="Sualı daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.content || ""}
              invalid={
                form.touched.content && form.errors.content
                  ? true
                  : false
              }
            />
            {form.touched.content && form.errors.content ? (
              <FormFeedback type="invalid">
                {form.errors.content}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Cavab tipi</Label>
            <Select
              value={selectedAnswerType}
              styles={{
                menuList: base => ({
                  ...base,
                  zIndex: 100,
                  background: "#fff",
                  padding: 0
                })
              }}
              name="answerTypes"
              onChange={e => setSelectedAnswerType(e)}
              options={formattedAnswerTypes}
              className="basic-multi-select bg-light"
              classNamePrefix="select"
            />
            {form.touched.observationId && form.errors.observationId ? (
              <FormFeedback type="invalid">
                {form.errors.observationId}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">Müşahidə səfəri</Label>
            <Select
              value={selectedObservations}
              isMulti
              styles={{
                menuList: base => ({
                  ...base,
                  zIndex: 100,
                  background: "#fff",
                  padding: 0
                })
              }}
              name="Observations"
              onChange={e => setSelectedObservations(e)}
              options={Observations}
              className="basic-multi-select bg-light"
              classNamePrefix="select"
            />
            {form.touched.observationId && form.errors.observationId ? (
              <FormFeedback type="invalid">
                {form.errors.observationId}
              </FormFeedback>
            ) : null}
          </div>

          {answers.map((answer, index) => {
            return (
              <div className="mb-3" key={index}>
                <Label className="form-label"> {index + 1}. Cavab </Label>
                <div className="d-flex">
                  <Input
                    name="content"
                    // type={`${answer.}`}
                    placeholder="Cavabı daxil edin"
                    onChange={(e) => {
                      setAnswers(answers => answers.map(item => item.answerId === answer.answerId ? { ...item, content: e.target.value } : item))
                    }}
                    value={answer.content}
                    invalid={
                      form.touched.content && form.errors.content
                        ? true
                        : false
                    }
                  />
                  <a style={{ margin: 'auto', }} onClick={() => {
                    setAnswers(answers => answers.filter(item => item.answerId !== answer.answerId))
                  }}>
                    <i className="mdi mdi-close font-size-18 p-1"></i>
                  </a>

                </div>
                {form.touched.content && form.errors.content ? (
                  <FormFeedback type="invalid">
                    {form.errors.content}
                  </FormFeedback>
                ) : null}
              </div>
            )
          })}

          {selectedAnswerType?.label === "dropdown" ?
            <a className="btn btn-primary my-3" onClick={() => setAnswers(answers => [...answers, { answerId: Date.now().toString(), content: '' }])}>
              Cavab əlavə et <i className="fa fa-plus"></i>
            </a> : selectedAnswerType?.label === "multi-dropdown" &&
            <a className="btn btn-primary my-3" onClick={() => setAnswers(answers => [...answers, { answerId: Date.now().toString(), content: '' }])}>
              Cavab əlavə et <i className="fa fa-plus"></i>
            </a>}

          {/* {selectedAnswerType?.label === "dropdown" && <a className="btn btn-primary my-3" onClick={() => setAnswers(answers => [...answers, { answerId: Date.now().toString(), content: '' }])}>
            Cavab əlavə et <i className="fa fa-plus"></i>
          </a>} */}

          <div className="mb-3">
            <Label className="form-label">Əlavə qeydlər</Label>
            <Input
              name="additionalNote"
              type="text"
              placeholder="Qeydlərinizi daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.additionalNote || ""}
              invalid={
                form.touched.additionalNote && form.errors.additionalNote
                  ? true
                  : false
              }
            />
            {form.touched.additionalNote && form.errors.additionalNote ? (
              <FormFeedback type="invalid">
                {form.errors.additionalNote}
              </FormFeedback>
            ) : null}
          </div>

        </Col>


      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <Button
              type="submit"
              className="btn btn-success save-user"
              disabled={isLoadingPostQuestions}
            >
              {isLoadingPostQuestions && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>} {" "}
              {mode === "edit" ? "Yaddaşa yaz" : "Əlavə et"}
            </Button>
          </div>
        </Col>
      </Row>
    </Form >
  )
}
