import PropTypes from "prop-types"
import React, { useLayoutEffect } from "react"
import { connect } from "react-redux"
import withRouter from "components/Common/withRouter"

//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"

import { Link } from "react-router-dom"
import { ReactComponent as LogoNoText } from "../../assets/images/logo-no-text.svg"
import { ReactComponent as Logo } from "../../assets/images/logo.svg"
import { ReactComponent as LogoLightNoText } from "../../assets/images/logo-light-no-text.svg"
import { ReactComponent as LogoLight } from "../../assets/images/ekvita-light.svg"
import LogoLightPng  from "../../assets/images/ekvita-light.png"

const Sidebar = props => {
  useLayoutEffect(() => {
    const bodyCollapseClass = "vertical-collpsed"

    function turnOffCollapsing() {
      const isCollapsible = document.body.classList.contains(bodyCollapseClass)

      if (window.screen.width < 992 && isCollapsible) {
        document.body.classList.remove(bodyCollapseClass)
      }
    }

    window.addEventListener("resize", turnOffCollapsing)
    return () => window.removeEventListener("resize", turnOffCollapsing)
  }, [])

  return (
    <React.Fragment>
      <div className="vertical-menu">
        
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
  type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)))
