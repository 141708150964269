import React from "react"

function Loader() {
  return (
    <div className="mb-1 mt-1">
      <div className="linear-activity w-100 bg-light">
        <div className="indeterminate"></div>
      </div>
    </div>
  )
}

export default Loader
