import formatDate from "helpers/format-date"
import React, { useState, useEffect } from "react"
import { useRef } from "react"
import { Input, Label } from "reactstrap"

export default function FollowerSelect({
  data = {},
  follower = {},
  observationId = '',
  followers = [],
  followerChangingHandler,
  handleObservationDetails,
  isDisabled,
  setDeleteObservation
}) {
  const [initialSelectedFollowerId, setInitialSelectedFollowerId] = useState(null)
  const [selectedFollowerId, setSelectedFollowerId] = useState(null);

  useEffect(() => {
    setSelectedFollowerId(follower?.followerId);
    setInitialSelectedFollowerId(follower?.followerId);
  }, [data]);
  
  useEffect(() => {
    if (selectedFollowerId !== null && selectedFollowerId !== initialSelectedFollowerId) {
      const newAttachFollower = {
        beneficiarId: data?.beneficiarId,
        attachments: [
          {
            observationId: observationId,
            followerId: selectedFollowerId
          }
        ]
      }

      followerChangingHandler(newAttachFollower)
    }
  }, [selectedFollowerId]);

  return (
    <div>
      <div
        style={{
          width: '20px',
          height: '3px',
          marginBottom: '7px',
          marginLeft: '3px',
          backgroundColor: `${selectedFollowerId !== initialSelectedFollowerId ? "#FFBC00" : 'transparent'}`
        }}
      />
      {followers && <Input
        style={{ cursor: 'pointer', width: '200px' }}
        name="followers"
        type="select"
        disabled={isDisabled}
        placeholder="Select Follower"
        onChange={(e) => setSelectedFollowerId(Number(e.target.value))}
        value={selectedFollowerId || ""}
      >
        <option value='0'>Müşahidəçi seçilməyib</option>
        {followers.map((follower) => {
          return (<option key={follower.followerId} value={follower.followerId}>{follower.fullName}</option>)
        })}
      </Input>}

      {follower?.observationDate ? <div className="m-2 d-flex">
        {follower?.isCompleted && <p className="text-center m-0 me-2">{formatDate(follower?.observationDate)}</p>}

        {follower?.ignored ?
          <p className="text-center text-danger m-0 me-2">
            <i
              className="mdi mdi-trash-can text-danger font-size-18 m-2 ms-0 "
              onClick={() => {
                const payload = {
                  beneficiarId: data?.beneficiarId,
                  observationId: follower?.observationId,
                }
                setDeleteObservation(payload);
              }}
              style={{ cursor: "pointer" }}
            ></i>
            {follower.ignoredText ? follower.ignoredText : "Şərait yaratmadı"}
          </p> : follower?.isCompleted ?
            <p
              className="text-center m-0  text-primary"
              style={{ cursor: 'pointer' }}
              onClick={() => handleObservationDetails(
                {
                  ...data,
                  observationId: follower.observationId,
                  observationDate: follower.observationDate
                })}
            > Detallar </p> :
            <p className="text-center text-warning m-0 me-2">
              <i
                className="mdi mdi-trash-can text-danger font-size-18 m-2 ms-0 "
                onClick={() => {
                  const payload = {
                    beneficiarId: data?.beneficiarId,
                    observationId: follower?.observationId,
                  }
                  setDeleteObservation(payload);
                }}
                style={{ cursor: "pointer" }}
              ></i>
              Davam edir...
            </p>}
      </div> : <p className="m-2" style={{ color: '#d79090', textAlign: 'start' }}>Səfər edilməyib</p>}
    </div >
  )
}
