import { REQUEST_BUSINESSES, REQUEST_BUSINESSES_FINISHED, REQUEST_DELETE_BUSINESS, REQUEST_DELETE_BUSINESS_FINISHED, REQUEST_POST_BUSINESS_EQUIPMENT, REQUEST_POST_BUSINESS_EQUIPMENT_FINISHED } from "./actionTypes"

export const requestBusinesses = (payload, meta = null) => {
  return {
    type: REQUEST_BUSINESSES,
    payload,
    meta,
  }
}

export const requestBusinessesFinished = (
  payload,
  error = false,
  meta = null
) => {
  return {
    type: REQUEST_BUSINESSES_FINISHED,
    payload,
    error,
    meta,
  }
}

export const requestPostBusinessEquipment = (payload, meta = null) => {
  return {
    type: REQUEST_POST_BUSINESS_EQUIPMENT,
    payload,
    meta
  }
}

export const requestPostBusinessEquipmentFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_BUSINESS_EQUIPMENT_FINISHED,
    error,
    payload,
    meta
  }
}

export const requestDeleteBusiness = (payload, meta = null) => {
  return {
    type: REQUEST_DELETE_BUSINESS,
    payload,
    meta
  }
}

export const requestDeleteBusinessFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_DELETE_BUSINESS_FINISHED,
    error,
    payload,
    meta
  }
}
