import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { REQUEST_COMMON_STATS } from "./actionTypes";
import { requestCommonStatsFinished } from "./actions";
import { getCommonStats, getCommonStatsLight } from "services";

// const fireBaseBackend = getFirebaseBackend();

function* fetchCommonStats({ error, payload, meta }) {

  if (error) return;
  try {
    const response = yield call(meta.isFirstLoad ? getCommonStatsLight : getCommonStats, payload);
    if (response.status) {
      const { data } = response;
      const commonStats = data.data
      yield put(requestCommonStatsFinished(commonStats))
      return
    }
    yield put(requestCommonStatsFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestCommonStatsFinished(error, true))
  }
}


function* CommonStatsSaga() {
  yield takeLatest([REQUEST_COMMON_STATS], fetchCommonStats);
}

export default CommonStatsSaga;
