import React, { useMemo, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"

import Filter from "./Filter"
import UserForm from "./UsersForm"
import EnhancedTable from "components/Common/EnhancedTable"
import { userColumns } from "./UsersTable/columns"
import { requestChangePasswordAsAdmin, requestDeleteUser, requestRegions, requestUsers } from "store/actions"

import {
  selectUsers,
  selectLoadingUsers,
  selectUsersPostResult,
  selectUsersDeleteResult,
  selectRegions,
  selectChangePasswordPostResult,
  selectLoadingDeleteUser,
} from "selectors/UsersSelectors"

import { useEffect } from "react"
import DeleteModal from "components/Common/DeleteModal"
import PasswordForm from "./PasswordForm"

//meta title

const Users = props => {
  document.title = "Istifadəçilər | Self Employment Project"
  const isLoading = useSelector(selectLoadingUsers)
  const selectedUsers = useSelector(selectUsers)
  const selectedRegions = useSelector(selectRegions)
  const postUserResult = useSelector(selectUsersPostResult)
  const changePasswordResult = useSelector(selectChangePasswordPostResult)
  const deleteUserResult = useSelector(selectUsersDeleteResult)
  const isLoadingDeleteUser = useSelector(selectLoadingDeleteUser);
  const [filters, setFilters] = useState({})
  const [modal, setModal] = useState(false)
  const [formMode, setFormMode] = useState("")
  const [selectedData, setSelectedData] = useState(null)
  const [changePasswordData, setChangePasswordData] = useState(null)
  const [deleteData, setDeleteData] = useState(null)
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const dispatch = useDispatch()

  function handleEditUser(userData) {
    setModal(true)
    setFormMode("edit")
    setSelectedData(userData)
  }

  function handleDeleteUser() {
    dispatch(requestDeleteUser(
      deleteData,
      {
        ...filters,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      }
    ))
  }

  
  function handleChangePassword(data) {
    dispatch(requestChangePasswordAsAdmin(
      data,
      {
        ...filters,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      }
    ))
  }

  function handleAddUser() {
    setModal(true)
    setFormMode("add")
    setSelectedData({})
  }

  function handleToggleUserFormModal() {
    setModal(prevOpen => !prevOpen)
  }
  function handleTogglePasswordModal() {
    setChangePasswordData(null)
  }

  const tableColumns = useMemo(
    () => [
      ...userColumns,
      {
        header: "Action",
        accessorKey: "action",
        cell: cellProps => {
          return (
            <div className="d-flex justify-content-center">
              <a
                className="text-success"
                href="#"
                // onClick={() => beneficiarSetter(row.original.beneficiarId)}
                onClick={() => {
                  const userData = cellProps.row.original
                  handleEditUser(userData)
                }}
              >
                <i
                  id={`edittooltip${cellProps.row.id}`}
                  className="mdi mdi-pencil font-size-18"
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    padding: "15px",
                  }}
                ></i>
                <UncontrolledTooltip placement="top" target={`edittooltip${cellProps.row.id}`}>
                  Redaktə et
                </UncontrolledTooltip>
              </a>
              <a className="text-danger">
                <i
                  onClick={() => setDeleteData(cellProps.row.original.id)}
                  id={`deletetooltip${cellProps.row.id}`}
                  className="mdi mdi-delete font-size-18"
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    padding: "15px",
                  }}
                ></i>
                <UncontrolledTooltip placement="top" target={`deletetooltip${cellProps.row.id}`}>
                  Sil
                </UncontrolledTooltip>
              </a>
              <a className="text-primary">
                <i
                  onClick={() => setChangePasswordData(cellProps.row.original)}
                  id={`changePassword${cellProps.row.id}`}
                  className="mdi mdi-key-variant font-size-18"
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    padding: "15px",
                  }}
                ></i>
                <UncontrolledTooltip placement="top" target={`changePassword${cellProps.row.id}`}>
                  Şifrəni dəyiş
                </UncontrolledTooltip>
              </a>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    if (postUserResult && postUserResult?.success) setModal(false)
  }, [postUserResult])



  useEffect(() => {
    if (deleteUserResult && deleteUserResult?.success) setDeleteData(null)
  }, [deleteUserResult])

  useEffect(() => {
    if (changePasswordResult && changePasswordResult?.success) setChangePasswordData(null)
  }, [changePasswordResult])

  useEffect(() => {
    if (!Object.keys(selectedRegions).length > 0) dispatch(requestRegions())
  }, [dispatch])

  useEffect(() => {
    dispatch(
      requestUsers({
        ...filters,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      })
    )
  }, [dispatch, filters, pagination])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs
            title={props.t("Users")}
            breadcrumbItem={props.t("İSRİFADƏÇİLƏR")}
          />
          <Row>
            <Col>
              <Filter setFilters={setFilters} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-end m-3 ">
                    <a className="btn btn-primary" onClick={handleAddUser}>
                      Yeni İstifadəçi
                    </a>
                  </div>
                  <EnhancedTable
                    onPaginationChange={setPagination}
                    pagination={pagination}
                    manualPagination
                    data={selectedUsers.list}
                    DataCount={selectedUsers.count}
                    columns={tableColumns}
                    isLoading={isLoading}
                  />
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
      <Modal size="lg" isOpen={modal} toggle={handleToggleUserFormModal}>
        <ModalHeader toggle={handleToggleUserFormModal} tag="h4">
          {formMode === "edit" ? "İstifadəçinin redaktə edilməsi" : "Yeni İstifadəçi"}
        </ModalHeader>
        <ModalBody>
          <UserForm
            user={selectedData}
            mode={formMode}
            regions={selectedRegions}
          />
        </ModalBody>
      </Modal>
      <DeleteModal
        show={Boolean(deleteData)}
        text={{
          title: `Silmək istədiyinizə əminsiniz ?`,
        }}
        onDeleteClick={handleDeleteUser}
        onCloseClick={() => setDeleteData(null)}
        isLoading={isLoadingDeleteUser}
      />
      <Modal size="md" isOpen={Boolean(changePasswordData)} toggle={handleTogglePasswordModal}>
        <ModalHeader toggle={handleTogglePasswordModal} tag="h4">
          {changePasswordData?.fullName}
          <p>Şifrənin yenilənməsi</p>
        </ModalHeader>
        <ModalBody>
          <PasswordForm userId={changePasswordData?.id} handleChangePassword={handleChangePassword} />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(Users)
