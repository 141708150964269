import useModal from "hooks/useModal"
import React, { useState } from "react"
import CreateEquipment from "./CreateEquipment"
import { useSelector } from "react-redux"
import { requestDeleteBusiness, requestPostBusinessEquipment } from "store/businesses/actions"
import DeleteModal from "components/Common/DeleteModal"
import { useDispatch } from "react-redux"
import { Button, Row } from "reactstrap"
import { REQUEST_DELETE_BUSINESS } from "store/businesses/actionTypes"

export default function ViewEquipments({ businessId }) {
  const { openModal, confirm, renderModals } = useModal();
  const [deleteItem, setDeleteItem] = useState(null);
  const business = useSelector(state => state.Business.list.find(business => business.businessId === businessId));
  const user = useSelector(state => state.Auth);
  const equipments = business.equipments ?? [];
  const dispatch = useDispatch();

  const handleEditEquipment = equipment => () => {
    openModal({
      header: "Avadanlığı redaktə edin",
      content: (
        <CreateEquipment equipment={equipment} businessId={businessId} />
      ),
    })
  };

  const handleDeleteEquipment = () => {
    dispatch(
      requestPostBusinessEquipment({ businessId, name: deleteItem.name, equipmentId: deleteItem.equipmentId, active: 0 })
    );
    setDeleteItem(null);
  };

  const handleCreateEquipment = () => {
    openModal({
      header: "Yeni avadalıq",
      content: <CreateEquipment businessId={businessId} />,
      size: "md",
    })
  };

  return (
    <React.Fragment>
      <div>
        {user.role !== "ROLE_REPORT" && <div className="d-flex justify-content-end m-2">
          <div>
            <Button onClick={handleCreateEquipment} color="primary">
              <i className="fas fa-plus me-2"></i>
              Yeni avadanlıq
            </Button>
          </div>
        </div>}

        <div style={{ maxHeight: "500px", overflowY: "auto" }}>
          <ul className="list-group">
            {renderModals()}
            {equipments && equipments.map(equipment => {
              return (
                <li
                  className="list-group-item d-flex justify-content-between"
                  style={{ flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center' }}
                  key={equipment.equipmentId}
                >
                  {equipment.equipmentName}
                  {user.role !== "ROLE_REPORT" && <div className="d-flex ">
                    <div
                      onClick={handleEditEquipment(equipment)}
                      style={{ cursor: 'pointer', padding: '5px', marginRight: '10px' }}
                    >
                      <i className="mdi mdi-pencil text-success font-size-18"></i>
                    </div>
                    <div
                      onClick={() => setDeleteItem(equipment)}
                      style={{ cursor: 'pointer', padding: '5px' }}
                    >
                      <i className="mdi mdi-delete text-danger font-size-18"></i>
                    </div>
                  </div>}
                </li>
              )
            })}
          </ul>
        </div >

        {user.role !== "ROLE_REPORT" && <div className="d-flex justify-content-end m-2">
          <div>
            <Button
              color="danger"
              onClick={() => {
                confirm({
                  title: `Zərfi silmək istədiyinizə əminsiniz ?`,
                  type: "delete",
                  confirmText: "Delete",
                  actions: [REQUEST_DELETE_BUSINESS],
                  onConfirm: ({ closeModal }) => {
                    dispatch(
                      requestDeleteBusiness(businessId,
                        { onSuccess: closeModal }
                      )
                    )
                  },
                })

              }}
            >
              <i className="fas fa-trash m-2"></i>
              Zərfi silmək
            </Button>
          </div>
        </div>}
      </div>

      <DeleteModal
        show={Boolean(deleteItem)}
        text={{
          title: `Silmək istədiyinizə əminsiniz ?`,
        }}
        onDeleteClick={handleDeleteEquipment}
        onCloseClick={() => setDeleteItem(null)}
      />

    </React.Fragment>

  )
}
