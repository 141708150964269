import { Storage } from "helpers/storage-helper";
import {
  LOGOUT_USER,
  REQUEST_LOGIN_USER_DELAYED,
  REQUEST_LOGIN_USER_FINISHED,
} from "./actionTypes"
import jwtDecode from "jwt-decode";

const authToken = Storage.getToken();
let decodedUser = null;

if (authToken) {
  decodedUser = jwtDecode(authToken);
}

const initialState = {
  isUserLoggedIn: Boolean(decodedUser),
  ...decodedUser
}

const login = (state = initialState, action) => {
  if (action.error) return { ...state };
  switch (action.type) {
    case REQUEST_LOGIN_USER_FINISHED:
      if (!action.payload.error)
        return { ...state, isUserLoggedIn: true, ...action.payload }
      else return { isUserLoggedIn: false }

    case REQUEST_LOGIN_USER_DELAYED:
      return { ...state, isUserLoggedIn: false }
    case LOGOUT_USER:
      return { state: null, isUserLoggedIn: false }
    default:
      return { ...state }
  }
}

export default login
