import {
  REQUEST_COMMON_STATS_FINISHED
} from "./actionTypes"

const initialState = {
  list: {},
}

const CommonStats = (state = initialState, { type, payload, error, meta }) => {
  if (error) return state;

  switch (type) {
    case REQUEST_COMMON_STATS_FINISHED:
      return { ...state, list: payload }
    default:
      return state
  }
}

export default CommonStats;
