import React from "react"
import { Button } from "reactstrap";

export const questionsQolumns = [
  {
    header: '',
    id: "manage",
    meta: {
      headerAlign: "end",
      cellAlign: "end",
    },
    cell: ({ row, getValue }) => {
      return (
        <>
          {row.getCanExpand() ? (
            <div className="d-flex align-items-center justify-content-end gap-3 w-fit-content" style={{ maxWidth: '60px' }}>
              <Button
                outline
                onClick={e => {
                  e.preventDefault()
                  row.getToggleExpandedHandler()()
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  border: "none",
                }}
              >
                {row.getIsExpanded() ? (
                  <i className="mdi mdi-chevron-down font-size-18"></i>
                ) : (
                  <i className="mdi mdi-chevron-right font-size-18"></i>
                )}
              </Button>
            </div>
          ) : (
            ""
          )}{" "}
        </>
      )
    },
  },
  {
    header: "Sualın məzmunu",
    accessorKey: "content",
    cell: ({ getValue }) => {
      return <div className="d-flex justify-content-center">
        <p style={{ maxWidth: '250px' }}>{getValue()}</p>
      </div>
    }
  },
  {
    header: "Cavab",
    accessorKey: "answerText",
    cell: ({ getValue }) => {
      return <div className="d-flex justify-content-center">
        <p style={{ maxWidth: '250px' }}>{getValue()}</p>
      </div>
    }
  },
  {
    header: "Əlavə qeyd",
    accessorKey: "additionalNote",
  }
]
