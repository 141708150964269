import Login from 'pages/Authentication/Login';
import Pages404 from 'pages/Utility/pages-404';
import React from 'react'
import { useSelector } from 'react-redux';

export default function NotFound() {
  const user = useSelector(state => state.Auth);
  const isValidUser = user.isUserLoggedIn && user.name && user.role
  return isValidUser ? <Pages404 /> : <Login />
}
