import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
  RESET_FORGOT_PASSWORD,
  RESTART_PASSWORD,
} from "./actionTypes"

export const userForgetPassword = (email) => {
  return {
    type: FORGET_PASSWORD,
    payload: email,
  }
}

export const userResetPassword = ({payload,router}) => {
  return {
    type: RESTART_PASSWORD,
    payload,
    router
  }
}

export const userForgetPasswordSuccess = message => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: message,
  }
}

export const userForgetPasswordError = message => {
  return {
    type: FORGET_PASSWORD_ERROR,
    payload: message,
  }
}

export const resetForgotPassword = () => {
  return {
    type: RESET_FORGOT_PASSWORD,
  }
}
