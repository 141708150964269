export const REQUEST_BUSINESSES = "REQUEST_BUSINESSES"
export const REQUEST_BUSINESSES_FINISHED = "REQUEST_BUSINESSES_FINISHED"

export const REQUEST_PUT_BUSINESSES = "REQUEST_PUT_BUSINESSES"
export const REQUEST_PUT_BUSINESSES_FINISHED = "REQUEST_PUT_BUSINESSES_FINISHED"

export const REQUEST_PUT_BUSINESS = "REQUEST_PUT_BUSINESS"
export const REQUEST_PUT_BUSINESS_FINISHED = "REQUEST_PUT_BUSINESS_FINISHED"

export const REQUEST_POST_BUSINESS_EQUIPMENT = "REQUEST_POST_BUSINESS_EQUIPMENT"
export const REQUEST_POST_BUSINESS_EQUIPMENT_FINISHED = "REQUEST_POST_BUSINESS_EQUIPMENT_FINISHED"

export const REQUEST_DELETE_BUSINESS = "REQUEST_DELETE_BUSINESS"
export const REQUEST_DELETE_BUSINESS_FINISHED = "REQUEST_DELETE_BUSINESS_FINISHED"