import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  REQUEST_USERS,
  REQUEST_POST_USER_FINISHED,
  REQUEST_DELETE_USER,
  REQUEST_DELETE_USER_FINISHED,
  REQUEST_POST_USER,
  REQUEST_REGIONS,
  REQUEST_CHANGE_PASSWORD_AS_ADMIN,
  REQUEST_CHANGE_PASSWORD_AS_ADMIN_FINISHED

} from "./actionTypes"
import {
  requestUsersFinished,
  requestPostUsersFinished,
  requestDeleteUserFinished,
  requestRegionsFinished,
  requestChangePasswordAsAdminFinished
} from "./actions"
import {
  getUsers,
  postUser,
  deleteUser,
  getRegions,
  changePasswordAsAdmin
} from "services"

// const fireBaseBackend = getFirebaseBackend();

function* fetchUsers({ error, payload }) {
  if (error) return
  try {
    const response = yield call(getUsers, payload)

    if (response.status) {
      const { data } = response
      const userData = data.data
      yield put(requestUsersFinished({ list: userData.users, count: userData.count }))
      return
    }
    yield put(requestUsersFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestUsersFinished(error, true))
  }
}

function* postSingleUser({ payload }) {
  try {
    const response = yield call(postUser, payload);
    if (response.data.success) {
      yield put(
        requestPostUsersFinished(response.data, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }

    yield put(requestPostUsersFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestPostUsersFinished(error, true))
  }
}

function* deleteSingleUser({ payload, meta }) {

  try {
    const response = yield call(deleteUser, payload);
    if (response.data.success) {
      const formatResponse = {
        ...response.data,
        ...meta
      }
      yield put(
        requestDeleteUserFinished(formatResponse, false, { toast: { type: "success" } })
      )
      return
    }

    yield put(requestDeleteUserFinished(response.data, true))
  } catch (error) {
    console.error(error);
    yield put(requestDeleteUserFinished(error, true))
  }
}

function* fetchRegions({ error }) {
  if (error) return
  try {
    const response = yield call(getRegions)

    if (response.status) {
      const { data } = response
      yield put(requestRegionsFinished(data))
      return
    }
    yield put(requestRegionsFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestRegionsFinished(error, true))
  }
}

function* changePassword({ payload, meta }) {
  try {
    const response = yield call(changePasswordAsAdmin, payload);
    if (response.data.success) {
      const formatResponse = {
        ...response.data,
        ...meta
      }
      yield put(
        requestChangePasswordAsAdminFinished(formatResponse, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }

    yield put(requestChangePasswordAsAdminFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestChangePasswordAsAdminFinished(error, true))
  }
}



function* UsersSaga() {
  yield takeEvery([
    REQUEST_USERS,
    REQUEST_POST_USER_FINISHED,
    REQUEST_DELETE_USER_FINISHED,
    REQUEST_CHANGE_PASSWORD_AS_ADMIN_FINISHED
  ], fetchUsers);

  
  yield takeEvery([REQUEST_POST_USER], postSingleUser);
  yield takeEvery([REQUEST_DELETE_USER], deleteSingleUser);
  yield takeEvery([REQUEST_REGIONS], fetchRegions);
  yield takeEvery([REQUEST_CHANGE_PASSWORD_AS_ADMIN], changePassword);
}

export default UsersSaga
