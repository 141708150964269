import React from "react"
import { Button } from "reactstrap";

export const questionsQolumns = [
  {
    header: '',
    id: "manage",
    meta: {
      headerAlign: "end",
      cellAlign: "end",
    },
    cell: ({ row, getValue }) => {
      return (
        <>
          {row.getCanExpand() ? (
            <div className="d-flex align-items-center justify-content-end gap-3 w-fit-content" style={{ maxWidth: '60px' }}>
              <Button
                outline
                onClick={e => {
                  e.preventDefault()
                  row.getToggleExpandedHandler()()
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                  border: "none",
                }}
              >
                {row.getIsExpanded() ? (
                  <i className="mdi mdi-chevron-down font-size-18"></i>
                ) : (
                  <i className="mdi mdi-chevron-right font-size-18"></i>
                )}
              </Button>
            </div>
          ) : (
            ""
          )}{" "}
        </>
      )
    },
  },
  {
    header: "Sualın məzmunu",
    accessorKey: "content",
    cell: ({ getValue }) => {
      return <div className="d-flex justify-content-center">
        <p style={{ maxWidth: '350px' }}>{getValue()}</p>
      </div>
    }
  },
  {
    header: "Cavabın tipi",
    accessorKey: "name",
  },
  {
    header: "Əlavə qeyd",
    accessorKey: "additionalNote",
  },
  {
    header: "Müşahidə səfəri",
    id: "observations",
    accessorKey: "observationId",
    cell: ({ getValue }) => {
      const data = getValue();
      const dataIterator = () => {
        if (data) {
          return <p key={data.observationDate} style={{ maxWidth: '250px' }}>
            {data.map((observationId, index) => {
              return `Müşahidə səfəri ${observationId} ${index + 1 < data.length ? ' , ' : ""}`
            })}
          </p>
        }
      }
      return <div className="d-flex justify-content-center">
        {dataIterator()}
      </div>

    }
  }
]
