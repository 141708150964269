const FileSaver = require("file-saver");
import XLSX from "sheetjs-style";
import formatDate from "helpers/format-date";
// {
//   "followerId": 84,
//   "fullName": "murad taghiyev",
//   "userName": "murad taghiyev",
//   "email": "murad@mail.ru",
//   "regionId": 3,
//   "mobile": "06777777",
//   "contractPath": null,
//   "contractStart": null,
//   "contractEnd": null,
//   "salary": null,
//   "createdAt": null,
//   "firstObservationCount": 53,
//   "secondObservationCount": 66,
//   "thirdObservationCount": 95,
//   "fourthObservationCount": 42,
//   "totalCount": 256
// }


export const exportToExcel = async ({ Data = [], fileName = "Beneficiaries", type = "dashboard" }) => {
  const heading = () => {
    switch (type) {
      case "dashboard":
        return [
          [
            "Rayon",
            "Ad Soyad",
            "FIN",
            "Qrup Kodu",
            "Ünvan",
            "Əlaqə nömrələri",
            "Aktivlərin verilmə tarixi",
            "Zərf paketi",
            "ÜDSY",
            "Məcburi köçkün",
            "Cins",
            "Qiymətləndirmə tarixi",
            "Əlillik",
            "Xüsusi xidmətlər",
            "Şirkət təlimləri",
            "Müşahidə səfəri 1",
            "Müşahidə səfəri 2",
            "Müşahidə səfəri 3",
            "Müşahidə səfəri 4",
          ],
        ]

      case "beneficiar":
        return [
          [
            "Rayon",
            "Ad Soyad",
            "FIN",
            "Qrup Kodu",
            "Ünvan",
            "Əlaqə nömrələri",
            "Aktivlərin verilmə tarixi",
            "Zərf paketi",
            "ÜDSY",
            "Məcburi köçkün",
            "Cins",
            "Qiymətləndirmə tarixi",
            "Əlillik",
            "Xüsusi xidmətlər",
            "Şirkət təlimləri",
          ],
        ]

      case "report":
        return [
          [
            "Rayon",
            "Ad Soyad",
            "FIN",
            "Qrup Kodu",
            "Ünvan",
            "Əlaqə nömrələri",
            "Aktivlərin verilmə tarixi",
            "Zərf paketi",
            "ÜDSY",
            "Məcburi köçkün",
            "Cins",
            "Qiymətləndirmə tarixi",
            "Əlillik",
            "Xüsusi xidmətlər",
            "Şirkət təlimləri",
            "Müşahidə səfəri 1",
            "Müşahidə səfəri 2",
            "Müşahidə səfəri 3",
            "Müşahidə səfəri 4",
          ],
        ]

      case "equipment":
        return [
          [
            "Ad Soyad",
            "FIN",
            "Zərf",
            "Avadanlıqların tam sayı",
            "Əksik avadanlıqların sayı",
            "Əksik Avadanlıqlar",
            "Əlaqə nömrəsi",
            "Ünvan",
          ],
        ]

      case "follower":
        return [
          [
            "Ad Soyad",
            "Müqavilə başlama tarixi",
            "Müqavilə bitmə tarixi",
            "Əmək haqqı ₼",
            "1 ci Müşahidə səfərləri",
            "2 ci Müşahidə səfərləri",
            "3 cü Müşahidə səfərləri",
            "4 cü Müşahidə səfərləri",
            "Toplam",
          ],
        ]

      default:
        return [
          [
            "Error",
          ],
        ]
    }
  };

  const columnsWidth = () => {
    switch (type) {
      case "dashboard": {
        return [
          { width: 12 },
          { width: 36 },
          { width: 10 },
          { width: 14 },
          { width: 40 },
          { width: 25 },
          { width: 21 },
          { width: 25 },
          { width: 10 },
          { width: 15 },
          { width: 11 },
          { width: 20 },
          { width: 15 },
          { width: 25 },
          { width: 19 },
          { width: 42 },
          { width: 42 },
          { width: 42 },
          { width: 42 },
        ]
      }

      case "beneficiar": {
        return [
          { width: 12 },
          { width: 36 },
          { width: 10 },
          { width: 14 },
          { width: 40 },
          { width: 25 },
          { width: 21 },
          { width: 25 },
          { width: 10 },
          { width: 15 },
          { width: 11 },
          { width: 20 },
          { width: 15 },
          { width: 25 },
          { width: 19 },
        ]
      }

      case "report": {
        return [
          { width: 12 },
          { width: 36 },
          { width: 10 },
          { width: 14 },
          { width: 40 },
          { width: 25 },
          { width: 21 },
          { width: 25 },
          { width: 10 },
          { width: 15 },
          { width: 11 },
          { width: 20 },
          { width: 15 },
          { width: 25 },
          { width: 19 },
          { width: 30 },
          { width: 30 },
          { width: 30 },
          { width: 30 },
        ]
      }

      case "equipment": {
        return [
          { width: 35 },
          { width: 11 },
          { width: 33 },
          { width: 22 },
          { width: 23 },
          { width: 50 },
          { width: 16 },
          { width: 37 },
        ]
      }

      case "follower": {
        return [
          { width: 35 },
          { width: 22 },
          { width: 22 },
          { width: 15 },
          { width: 23 },
          { width: 23 },
          { width: 23 },
          { width: 23 },
          { width: 10 },
        ]
      }

      default:
        return [
          { width: 50 },
        ]
    }
  }


  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
  const fileExtention = '.xlsx'
  const ws = XLSX.utils.json_to_sheet([]);
  XLSX.utils.sheet_add_aoa(ws, heading());

  const excelData = Data?.map((data) => {
    switch (type) {
      case "dashboard": {
        const observation1 = data?.followers?.filter(follower => follower.observationId === 1)[0];
        const observation2 = data?.followers?.filter(follower => follower.observationId === 2)[0];
        const observation3 = data?.followers?.filter(follower => follower.observationId === 3)[0];
        const observation4 = data?.followers?.filter(follower => follower.observationId === 4)[0];
        return {
          region: data?.region || "-",
          fullName: data?.fullName || "-",
          fin: data?.fin || "-",
          groupCode: data?.groupCode || "-",
          address: data?.address || "-",
          contactNumbers: data?.contactNumbers || "-",
          equipmentDeliveryDate: data?.equipmentDeliveryDate ? formatDate(data?.equipmentDeliveryDate) : "-",
          business: data?.business || "-",
          udys: data?.udys ? "Bəli" : "Xeyr",
          idp: data?.idp ? "Bəli" : "Xeyr",
          gender: data?.gender?.toLowerCase() === "m" ? "Kişi" : "Qadın",
          assestmentDate: data?.assestmentDate ? formatDate(data?.assestmentDate) : "-",
          disabled: data?.disabled || "-",
          specialServices: data?.specialServices || "-",
          trainingType: data?.trainingType || "-",
          observation1: `${observation1?.ignoredText ? `${observation1?.ignoredText}` : observation1?.isCompleted ? "Tamamlanıb" : observation1?.observationDate ? "Davam edir" : "Səfər edilməyib"} ${observation1?.observationDate ? ` - ${formatDate(observation1?.observationDate)}` : ""} ${observation1?.followerName ? ` - ${observation1?.followerName}` : ""}`,
          observation2: `${observation2?.ignoredText ? `${observation2?.ignoredText}` : observation2?.isCompleted ? "Tamamlanıb" : observation2?.observationDate ? "Davam edir" : "Səfər edilməyib"} ${observation2?.observationDate ? ` - ${formatDate(observation2?.observationDate)}` : ""} ${observation2?.followerName ? ` - ${observation2?.followerName}` : ""}`,
          observation3: `${observation3?.ignoredText ? `${observation3?.ignoredText}` : observation3?.isCompleted ? "Tamamlanıb" : observation3?.observationDate ? "Davam edir" : "Səfər edilməyib"} ${observation3?.observationDate ? ` - ${formatDate(observation3?.observationDate)}` : ""} ${observation3?.followerName ? ` - ${observation3?.followerName}` : ""}`,
          observation4: `${observation4?.ignoredText ? `${observation4?.ignoredText}` : observation4?.isCompleted ? "Tamamlanıb" : observation4?.observationDate ? "Davam edir" : "Səfər edilməyib"} ${observation4?.observationDate ? ` - ${formatDate(observation4?.observationDate)}` : ""} ${observation4?.followerName ? ` - ${observation4?.followerName}` : ""}`,

        }
      }

      case "report": {
        const observation1 = data?.followers?.filter(follower => follower.observationId === 1)[0];
        const observation2 = data?.followers?.filter(follower => follower.observationId === 2)[0];
        const observation3 = data?.followers?.filter(follower => follower.observationId === 3)[0];
        const observation4 = data?.followers?.filter(follower => follower.observationId === 4)[0];
        return {
          region: data?.region || "-",
          fullName: data?.fullName || "-",
          fin: data?.fin || "-",
          groupCode: data?.groupCode || "-",
          address: data?.address || "-",
          contactNumbers: data?.contactNumbers || "-",
          equipmentDeliveryDate: data?.equipmentDeliveryDate ? formatDate(data?.equipmentDeliveryDate) : "-",
          business: data?.business || "-",
          udys: data?.udys ? "Bəli" : "Xeyr",
          idp: data?.idp ? "Bəli" : "Xeyr",
          gender: data?.gender?.toLowerCase() === "m" ? "Kişi" : "Qadın",
          assestmentDate: data?.assestmentDate ? formatDate(data.assestmentDate) : "-",
          disabled: data?.disabled || "-",
          specialServices: data?.specialServices || "-",
          trainingType: data?.trainingType || "-",
          observation1: `${observation1?.ignoredText ? ` (${observation1?.ignoredText}) ` : ""}${observation1?.observationDate ? `${formatDate(observation1?.observationDate)}` : ""}`,
          observation2: `${observation2?.ignoredText ? ` (${observation2?.ignoredText}) ` : ""}${observation2?.observationDate ? `${formatDate(observation2?.observationDate)}` : ""}`,
          observation3: `${observation3?.ignoredText ? ` (${observation3?.ignoredText}) ` : ""}${observation3?.observationDate ? `${formatDate(observation3?.observationDate)}` : ""}`,
          observation4: `${observation4?.ignoredText ? ` (${observation4?.ignoredText}) ` : ""}${observation4?.observationDate ? `${formatDate(observation4?.observationDate)}` : ""}`,
        }
      }

      case "beneficiar":
        return {
          region: data?.region || "-",
          fullName: data?.fullName || "-",
          fin: data?.fin || "-",
          groupCode: data?.groupCode || "-",
          address: data?.address || "-",
          contactNumbers: data?.contactNumbers || "-",
          equipmentDeliveryDate: data?.equipmentDeliveryDate ? formatDate(data?.equipmentDeliveryDate) : "-",
          business: data?.business || "-",
          udys: data?.udys ? "Bəli" : "Xeyr",
          idp: data?.idp ? "Bəli" : "Xeyr",
          gender: data?.gender?.toLowerCase() === "m" ? "Kişi" : "Qadın",
          assestmentDate: data?.assestmentDate ? formatDate(data?.assestmentDate) : "-",
          disabled: data?.disabled || "-",
          specialServices: data?.specialServices || "-",
          trainingType: data?.trainingType || "-"
        }

      case "equipment":
        return {
          fullName: data?.fullName || "-",
          fin: data?.fin || "-",
          business: data?.business || "-",
          equipmentsCount: data?.equipmentsCount || "-",
          missingEquipmentsCount: data?.missingEquipmentsCount || "-",
          missingList: data?.missingList || "-",
          contactNumbers: data?.contactNumbers || "-",
          address: data?.address || "-"
        }

      case "follower":
        return {
          fullName: data?.fullName || "-",
          contractStart: data?.contractStart || "-",
          contractEnd: data?.contractEnd || "-",
          salary: data?.salary || "-",
          firstObservationCount: data?.firstObservationCount || "-",
          secondObservationCount: data?.secondObservationCount || "-",
          thirdObservationCount: data?.thirdObservationCount || "-",
          fourthObservationCount: data?.fourthObservationCount || "-",
          totalCount: data?.totalCount || "-"
        }

      default:
        return {
          notFound: "Excel export type not specified or specified type not configured.",
        }
    }
  });

  //#region styles
  ws['!rows'] = [{ hpx: 25 }]
  ws['!cols'] = columnsWidth();

  if (ws["A1"]) ws["A1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center",
        wrapText: '1'
      },
      color: {
        rgb: "495057"
      },
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["B1"]) ws["B1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["C1"]) ws["C1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["D1"]) ws["D1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["E1"]) ws["E1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["F1"]) ws["F1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["G1"]) ws["G1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["H1"]) ws["H1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["I1"]) ws["I1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["J1"]) ws["J1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["K1"]) ws["K1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["L1"]) ws["L1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["M1"]) ws["M1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["N1"]) ws["N1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["O1"]) ws["O1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["P1"]) ws["P1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["Q1"]) ws["Q1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["R1"]) ws["R1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  if (ws["S1"]) ws["S1"].s = {
    font: {
      sz: 12,
      bold: true,
      alignment: {
        vertical: "center",
        horizontal: "center"
      },
      color: {
        rgb: "495057"
      }
    },
    fill: {
      type: 'pattern',
      pattern: "solid",
      bgColor: { rgb: "E7DEEB" },
      fgColor: { rgb: "E7DEEB" }
    }
  };
  //#endregion


  XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A2', skipHeader: true });
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtention);
}

// export const exportLocalBenficiarTableToExcel = async ({ Data }) => {

//   const excelData = Data?.map((beneficiar) => {
//     const observation1 = beneficiar?.followers?.filter(follower => follower.observationId === 1)[0];
//     const observation2 = beneficiar?.followers?.filter(follower => follower.observationId === 2)[0];
//     const observation3 = beneficiar?.followers?.filter(follower => follower.observationId === 3)[0];
//     const observation4 = beneficiar?.followers?.filter(follower => follower.observationId === 4)[0];
//     return {
//       region: beneficiar?.region || "-",
//       fullName: beneficiar?.fullName || "-",
//       fin: beneficiar?.fin || "-",
//       groupCode: beneficiar?.groupCode || "-",
//       address: beneficiar?.address || "-",
//       contactNumbers: beneficiar?.contactNumbers || "-",
//       equipmentDeliveryDate: beneficiar?.equipmentDeliveryDate ? formatDate(beneficiar?.equipmentDeliveryDate) : "-",
//       business: beneficiar?.business || "-",
//       udys: beneficiar?.udys ? "Bəli" : "Xeyr",
//       idp: beneficiar?.idp ? "Bəli" : "Xeyr",
//       gender: beneficiar?.gender?.toLowerCase() === "m" ? "Kişi" : "Qadın",
//       assestmentDate: beneficiar?.assestmentDate ? formatDate(beneficiar?.assestmentDate) : "-",
//       disabled: beneficiar?.disabled ? "Bəli" : "Xeyr",
//       specialServices: beneficiar?.specialServices || "-",
//       trainingType: beneficiar?.trainingType || "-",
//       observation1: `${observation1?.ignoredText ? ` (${observation1?.ignoredText}) ` : ""}${observation1?.observationDate ? `${formatDate(observation1?.observationDate)}` : ""}${observation1?.followerName ? ` - ${observation1?.followerName}` : ""}`,
//       observation2: `${observation2?.ignoredText ? ` (${observation2?.ignoredText}) ` : ""}${observation2?.observationDate ? `${formatDate(observation2?.observationDate)}` : ""}${observation2?.followerName ? ` - ${observation2?.followerName}` : ""}`,
//       observation3: `${observation3?.ignoredText ? ` (${observation3?.ignoredText}) ` : ""}${observation3?.observationDate ? `${formatDate(observation3?.observationDate)}` : ""}${observation3?.followerName ? ` - ${observation3?.followerName}` : ""}`,
//       observation4: `${observation4?.ignoredText ? ` (${observation4?.ignoredText}) ` : ""}${observation4?.observationDate ? `${formatDate(observation4?.observationDate)}` : ""}${observation4?.followerName ? ` - ${observation4?.followerName}` : ""}`,
//     }
//   });

//   const heading = [
//     [
//       "Rayon",
//       "Ad Soyad",
//       "FIN",
//       "Qrup Kodu",
//       "Ünvan",
//       "Əlaqə nömrələri",
//       "Aktivlərin verilmə tarixi",
//       "Zərf paketi",
//       "ÜDSY",
//       "Məcburi köçkün",
//       "Cins",
//       "Qiymətləndirmə tarixi",
//       "Əlillik",
//       "Xüsusi xidmətlər",
//       "Şirkət təlimləri",
//       "Müşahidə səfəri 1",
//       "Müşahidə səfəri 2",
//       "Müşahidə səfəri 3",
//       "Müşahidə səfəri 4",
//     ],
//   ];

//   const columnsWidth = [
//     { width: 12 },
//     { width: 36 },
//     { width: 10 },
//     { width: 14 },
//     { width: 40 },
//     { width: 25 },
//     { width: 21 },
//     { width: 25 },
//     { width: 10 },
//     { width: 15 },
//     { width: 11 },
//     { width: 20 },
//     { width: 15 },
//     { width: 25 },
//     { width: 19 },
//     { width: 30 },
//     { width: 30 },
//     { width: 30 },
//     { width: 30 },
//   ]
//   const fileName = `Benefisiarlar_${Date.now()}`
//   const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
//   const fileExtention = '.xlsx'
//   const ws = XLSX.utils.json_to_sheet([]);
//   XLSX.utils.sheet_add_aoa(ws, heading)


//   ws['!rows'] = [{ hpx: 25 }]
//   ws['!cols'] = columnsWidth;

//   if (ws["A1"]) ws["A1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center",
//         wrapText: '1'
//       },
//       color: {
//         rgb: "495057"
//       },
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["B1"]) ws["B1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["C1"]) ws["C1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["D1"]) ws["D1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["E1"]) ws["E1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["F1"]) ws["F1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["G1"]) ws["G1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["H1"]) ws["H1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["I1"]) ws["I1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["J1"]) ws["J1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["K1"]) ws["K1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["L1"]) ws["L1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["M1"]) ws["M1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["N1"]) ws["N1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["O1"]) ws["O1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["P1"]) ws["P1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["Q1"]) ws["Q1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["R1"]) ws["R1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };
//   if (ws["S1"]) ws["S1"].s = {
//     font: {
//       sz: 12,
//       bold: true,
//       alignment: {
//         vertical: "center",
//         horizontal: "center"
//       },
//       color: {
//         rgb: "495057"
//       }
//     },
//     fill: {
//       type: 'pattern',
//       pattern: "solid",
//       bgColor: { rgb: "E7DEEB" },
//       fgColor: { rgb: "E7DEEB" }
//     }
//   };

//   XLSX.utils.sheet_add_json(ws, excelData, { origin: 'A2', skipHeader: true });
//   const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
//   const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//   const data = new Blob([excelBuffer], { type: fileType });
//   FileSaver.saveAs(data, fileName + fileExtention);
// }

