import {
  REQUEST_BENEFICIARS,
  REQUEST_BENEFICIARS_FINISHED,
  SAVE_BENEFICIAR_FILTER,
  REQUEST_FOLLOWERS,
  REQUEST_FOLLOWERS_FINISHED,
  REQUEST_POST_ATTACH_FOLLOWERS,
  REQUEST_POST_ATTACH_FOLLOWERS_FINISHED,
  REQUST_BUSINESS_OPTIONS,
  REQUST_BUSINESS_OPTIONS_FINISHED,
  REQUEST_POST_BENEFICIAR,
  REQUEST_POST_BENEFICIAR_FINISHED,
  REQUEST_POST_EXPORT_BENEFICIARIES,
  REQUEST_POST_EXPORT_BENEFICIARIES_FINISHED,
  REQUEST_POST_IMPORT_BENEFICIARIES,
  REQUEST_POST_IMPORT_BENEFICIARIES_FINISHED,
  REQUEST_GET_EXPORT_BENEFICIARIES_DATA_FINISHED,
  REQUEST_GET_EXPORT_BENEFICIARIES_DATA,
  REQUEST_DELETE_BENEFICIARS,
  REQUEST_DELETE_BENEFICIARS_FINISHED,
  REQUEST_GET_EXPORT_BENEFICIARIES_ANSWER_DATA,
  REQUEST_GET_EXPORT_BENEFICIARIES_ANSWER_DATA_FINISHED,
  REQUEST_PUT_CHANGE_OBSERVATION_DATE_FINISHED,
  REQUEST_PUT_CHANGE_OBSERVATION_DATE,
  REQUEST_DELETE_OBSERVATION,
  REQUEST_DELETE_OBSERVATION_FINISHED,
  REQUEST_EXPORT_MISSING_EQUIPMENTS_FINSHED,
  REQUEST_EXPORT_MISSING_EQUIPMENTS
} from "./actionTypes"

export const requestBeneficiars = (payload) => {
  return {
    type: REQUEST_BENEFICIARS,
    payload
  }
}

export const requestDeleteBeneficiar = (payload) => {
  return {
    type: REQUEST_DELETE_BENEFICIARS,
    payload
  }
}

export const requestDeleteBeneficiarFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_DELETE_BENEFICIARS_FINISHED,
    payload,
    error,
    meta
  }
}

export const saveBeneficiarsFilter = (payload, error = false, meta = null) => {
  return {
    type: SAVE_BENEFICIAR_FILTER,
    payload,
    error,
    meta
  }
}

export const requestPostBeneficiar = (payload) => {
  return {
    type: REQUEST_POST_BENEFICIAR,
    payload
  }
}

export const requestPostBeneficiarFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_BENEFICIAR_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestBeneficiarsFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_BENEFICIARS_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestExportBeneficiaries = (payload) => {
  return {
    type: REQUEST_POST_EXPORT_BENEFICIARIES,
    payload
  }
}

export const requestExportBeneficiariesFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_EXPORT_BENEFICIARIES_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestExportBeneficiariesData = (payload,meta) => {
  return {
    type: REQUEST_GET_EXPORT_BENEFICIARIES_DATA,
    payload,
    meta
  }
}

export const requestExportBeneficiariesDataFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_GET_EXPORT_BENEFICIARIES_DATA_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestExportBeneficiariesAnserData = (payload) => {
  return {
    type: REQUEST_GET_EXPORT_BENEFICIARIES_ANSWER_DATA,
    payload
  }
}

export const requestExportBeneficiariesAnswerDataFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_GET_EXPORT_BENEFICIARIES_ANSWER_DATA_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestImportBeneficiaries = (payload) => {
  return {
    type: REQUEST_POST_IMPORT_BENEFICIARIES,
    payload
  }
}

export const requestImportBeneficiariesFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_IMPORT_BENEFICIARIES_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestFollowers = (payload) => {
  return {
    type: REQUEST_FOLLOWERS,
    payload
  }
}

export const requestFollowersFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_FOLLOWERS_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestPostAttachFollowers = (payload) => {
  return {
    type: REQUEST_POST_ATTACH_FOLLOWERS,
    payload
  }
}

export const requestPostAttachFollowersFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_ATTACH_FOLLOWERS_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestBusinessOptions = () => {
  return {
    type: REQUST_BUSINESS_OPTIONS
  }
}

export const requestBusinessOptionsFinished = (payload) => {
  return {
    type: REQUST_BUSINESS_OPTIONS_FINISHED,
    payload
  }
}

export const requestPutObservationDate = (payload, meta ) => {
  return {
    type: REQUEST_PUT_CHANGE_OBSERVATION_DATE,
    payload,
    meta
  }
}

export const requestPutObservationDateFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_PUT_CHANGE_OBSERVATION_DATE_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestDeleteObservation = (payload, meta ) => {
  return {
    type: REQUEST_DELETE_OBSERVATION,
    payload,
    meta
  }
}

export const requestDeleteObservationFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_DELETE_OBSERVATION_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestExportMissingEquipment = (payload, meta ) => {
  return {
    type: REQUEST_EXPORT_MISSING_EQUIPMENTS,
    payload,
    meta
  }
}

export const requestExportMissingEquipmentFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_EXPORT_MISSING_EQUIPMENTS_FINSHED,
    payload,
    error,
    meta
  }
}

