import React, { useMemo, useEffect, useState } from "react"
import {
  Button,
  Card,
  CardBody,
  Label,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
  Input,
  InputGroup,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { withTranslation } from "react-i18next"

import EnhancedTable from "components/Common/EnhancedTable"
import {
  selectBeneficiarQuestions,
  selectCompleteObservationPostResult,
  selectLoadingBeneficiarQuestions,
  selectLoadingCompleteObservation,
  selectQuestionsPostResult,
} from "selectors/FollowersSelectors"
import { requestObservationQuestions, requestPostCompleteObservation, requestPostEquipment, requestPostQuestionAnswer } from "store/followers/actions"

import { questionsQolumns } from "./questionsTable/columns"
import ObservationQuestionForm from "./Form"
import SkeletonLoading from "components/Common/SkeletonLoading"

document.title = "ObservationQuestions | Self Employment Project"

const ObservationQuestions = props => {
  const { data } = props
  const dispatch = useDispatch()
  const selectedQuestions = useSelector(selectBeneficiarQuestions);
  const user = useSelector(state => state.Auth);
  const answerPostResult = useSelector(selectQuestionsPostResult)
  const completeObservationPostResult = useSelector(selectCompleteObservationPostResult)
  const LoadingCompleteObservation = useSelector(selectLoadingCompleteObservation)
  const isLoading = useSelector(selectLoadingBeneficiarQuestions)
  const [modal, setModal] = useState(false)
  const [innerModal, setInnerModal] = useState(false)
  const [selectedData, setSelectedData] = useState(null)
  const [isEquipmentQuestions, setIsEquipmentQuestions] = useState(false);
  const [equipmentList, setEquipmentList] = useState([]);
  const [selectedEquipmentList, setSelectedEquipmentList] = useState([]);

  const [formMode, setFormMode] = useState("")

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })


  function handleAnswerQuestion(Data) {
    setModal(true)
    setSelectedData(Data)
    setFormMode(!Data.answerText ? "add" : "edit")
  }

  function handleToggleQuestionFormModal() {
    setModal(prevOpen => !prevOpen)
  }

  function completeObservation() {
    const payload = {
      beneficiaryId: data.BeneficiaryId ? data.BeneficiaryId : data.beneficiarId,
      observationId: data.ObservationId ? data.ObservationId : data.observationId,
      ...pagination
    }

    dispatch(requestPostCompleteObservation(payload))
  }

  function handleToggleCompleteObservationModal() {
    setInnerModal(prevOpen => !prevOpen);
  }

  const tableColumns = useMemo(
    () => [
      ...questionsQolumns,
      {
        header: "Action",
        accessorKey: "action",
        cell: cellProps => {
          if (user.role === "ROLE_ADMIN" && Object.keys(selectedQuestions).length > 0) {
            return (
              <div
                className="d-flex gap-3 justify-content-center"
                style={{ maxWidth: "150px" }}
              >
                <a
                  className="text-success"
                  href="#"
                  onClick={() => {
                    const questionData = cellProps.row.original
                    handleAnswerQuestion(questionData)
                  }}
                >
                  <i
                    id={`canAnswer${cellProps.row.original.id}`}
                    className="mdi mdi-pencil font-size-18"
                    style={{
                      fontSize: "24px",
                      cursor: "pointer",
                      padding: "15px",
                    }}
                  ></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={`canAnswer${cellProps.row.original.id}`}
                  >
                    Cavabla
                  </UncontrolledTooltip>
                </a>
              </div>
            )
          } else if (user.role === "ROLE_FOLLOWER" && Object.keys(selectedQuestions).length > 0) {
            if (!data.IsCompleted && data.IsObserved === true) {
              return (
                <div
                  className="d-flex justify-content-center text-center"
                >
                  <a
                    className="text-success"
                    href="#"
                    onClick={() => {
                      const questionData = cellProps.row.original
                      handleAnswerQuestion(questionData)
                    }}
                  >
                    <i
                      id={`answer${cellProps.row.original.id}`}
                      className="mdi mdi-pencil font-size-18"
                      style={{
                        fontSize: "24px",
                        cursor: "pointer",
                        padding: "15px",
                      }}
                    ></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={`answer${cellProps.row.original.id}`}
                    >
                      Cavabla
                    </UncontrolledTooltip>
                  </a>
                </div>
              )
            } else {
              return (
                <div
                  className="d-flex justify-content-center text-center"
                >
                  <i
                    id={`edittooltip${cellProps.row.original.id}`}
                    className="mdi mdi-pencil text-secondary font-size-18"
                    style={{
                      fontSize: "24px",
                      cursor: "pointer",
                      padding: "15px",
                    }}
                  ></i>
                  <UncontrolledTooltip placement="top" target={`edittooltip${cellProps.row.original.id}`}>
                    {data.IsObserved ? "Müşahidə səfəri tamamlanmışdır." : "Müşahidə edilməyib"}
                  </UncontrolledTooltip>
                </div>
              )
            }
          } else {
            return (
              <div
                className="d-flex justify-content-center text-center"
              >
                <i
                  id={`edittooltip${cellProps.row.original.id}`}
                  className="mdi mdi-pencil text-secondary font-size-18"
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    padding: "15px",
                  }}
                ></i>
                <UncontrolledTooltip placement="top" target={`edittooltip${cellProps.row.original.id}`}>
                  Redaktə etmə icazəsi yoxdur.
                </UncontrolledTooltip>
              </div>
            )
          }
        },
      },
    ],
    [selectedQuestions]
  )

  useEffect(() => {
    if (answerPostResult && answerPostResult?.success) setModal(false)
  }, [answerPostResult])

  useEffect(() => {
    if (completeObservationPostResult && completeObservationPostResult?.success) {
      setInnerModal(false)
    }
  }, [completeObservationPostResult])

  useEffect(() => {
    if (data)
      dispatch(
        requestObservationQuestions({
          ...data,
          isEquipment: isEquipmentQuestions,
          pageIndex: pagination.pageIndex + 1,
          pageSize: pagination.pageSize,
        })
      )
  }, [data, dispatch, pagination, isEquipmentQuestions])


  useEffect(() => {
    if (isEquipmentQuestions) setEquipmentList(selectedQuestions.questions)
    else { setEquipmentList(null) }
  }, [selectedQuestions])


  useEffect(() => {
    if (equipmentList && equipmentList.length > 0) {
      equipmentList.map((equipment) => {
        if (equipment.answerText?.toLowerCase() == "var") setSelectedEquipmentList(state => [...state, equipment.id])
      })
    }
  }, [equipmentList])

  const handleAddToList = (id) => {
    if (!selectedEquipmentList.includes(id)) setSelectedEquipmentList(state => [...state, id])
    else { setSelectedEquipmentList(state => state.filter(item => item !== id)) }
  }

  const handleAddEquipments = () => {
    const formatdetAnswer = {
      BeneficiaryId: data.BeneficiaryId,
      ObservationId: data.ObservationId,
      equipmentIds: selectedEquipmentList
    }

    dispatch(requestPostEquipment(formatdetAnswer, pagination));
  }

  return (
    <React.Fragment>
      <div className="page-content pt-2">
        <Container fluid>
          <Row className="my-2">
            <div className="text-end p-0" onClick={() => setIsEquipmentQuestions(!isEquipmentQuestions)}>
              <a className={`btn btn-outline-primary m-0 my-2`} >
                {isEquipmentQuestions ? "Suallar" : "Avadanlıqlar"}
              </a>
            </div>
          </Row>
          <Row>
            <CardBody>
              {!isEquipmentQuestions && selectedQuestions && Object.keys(selectedQuestions).length > 0 && <EnhancedTable
                onPaginationChange={setPagination}
                pagination={pagination}
                manualPagination
                DataCount={selectedQuestions.count}
                data={selectedQuestions.questions}
                columns={tableColumns}
                isLoading={isLoading}
              />}

              {isEquipmentQuestions && equipmentList && equipmentList.length > 0 && <div>
                <div>
                  <Row>
                    {!isLoading ? <div>
                      {equipmentList.map((equipment) => {
                        return (
                          <div key={equipment.id} className="border-bottom mb-3 pb-2">
                            <div
                              className="d-flex align-items-center"
                            >
                              <Input
                                className="m-0 me-2 p-2"
                                type="checkbox"
                                checked={selectedEquipmentList.includes(equipment.id)}
                                onChange={() => handleAddToList(equipment.id)}
                              />
                              <p className="font-size-18 p-0 m-0">{equipment.content}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div> : <Row>
                      <div className="my-2"><SkeletonLoading /></div>
                      <div className="my-2"><SkeletonLoading /></div>
                      <div className="my-2"><SkeletonLoading /></div>
                    </Row>}
                  </Row>

                  <Row className="mt-2">
                    {user.role === "ROLE_ADMIN" || user.role === "ROLE_FOLLOWER" && !data.IsCompleted && data.IsObserved && <div className="text-end">
                      <Button className='btn-success' onClick={handleAddEquipments}> Yaddaşa yaz </Button>
                    </div>}
                  </Row>

                </div>
              </div>}

            </CardBody>
          </Row>
          {user.role === "ROLE_FOLLOWER" && !data.IsCompleted && data.IsObserved && <div className="d-flex justify-content-end p-0 pt-5">
            <Button className='btn-success' onClick={handleToggleCompleteObservationModal}
            >
              Müşahidə səfərini tamamla
            </Button>
          </div>}
        </Container>
      </div>
      <Modal size="lg" isOpen={modal} toggle={handleToggleQuestionFormModal}>
        <ModalHeader toggle={handleToggleQuestionFormModal} tag="h4">
          Sualın cavablandırılması
        </ModalHeader>
        <ModalBody>
          <ObservationQuestionForm
            question={selectedData}
            data={data}
            type={formMode}
            pagination={pagination}
          />
        </ModalBody>
      </Modal>

      <Modal
        size="lg"
        isOpen={innerModal}
        toggle={handleToggleCompleteObservationModal}>
        <ModalHeader toggle={handleToggleCompleteObservationModal} tag="h4">
          Diqqət!
        </ModalHeader>
        <ModalBody>
          <Label className="font-size-18">Müşahidən səfərini tamamlamaq istediyinizə əminsinizmi?</Label>
          <Label className="text-danger ">Diqqət! Bu əməliyyatı daha sonra geri qaytarmaq mümkün olmayacaq.</Label>
          <div className="d-flex justify-content-end ">
            <Button className="btn-secondary me-3" onClick={handleToggleCompleteObservationModal}>Bağla</Button>
            <Button className="btn-success" disabled={LoadingCompleteObservation} onClick={completeObservation}>
              {LoadingCompleteObservation && <span className="spinner-border spinner-border-sm me-3" role="status" aria-hidden="true"></span>} {" "}
              Bəli
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(ObservationQuestions)
