export class Storage {
    static set(pairs, type = "localStorage") {
      if (pairs != null) {
        Object.keys(pairs).forEach(function saveToLocalStorage(pairKey) {
          window[type].setItem(pairKey, pairs[pairKey])
        })
      }
    }
  
    static get(keys) {
      if (keys != null) {
        if (Array.isArray(keys)) {
          const items = keys.reduce(function getItems(prevItems, currentKey) {
            const currentValue = currentKey
              ? localStorage.getItem(currentKey) ||
                sessionStorage.getItem(currentKey)
              : null
            if (currentValue == null) {
              return { ...prevItems }
            }
  
            return { ...prevItems, [currentKey]: currentValue }
          }, {})
          return items
        } else if (typeof keys === "string") {
          return localStorage.getItem(keys) || sessionStorage.getItem(keys)
        } else return null
      }
  
      return null
    }
  
    static removeAll(keys, type = "localStorage") {
      if (Array.isArray(keys) && keys != null) {
        keys.forEach(function removeFromStorage(key) {
          window[type].removeItem(key)
        })
      }
    }
  
    static getToken() {
      return localStorage.getItem("authToken") || sessionStorage.getItem("authToken");
    }
  
    static getRefreshToken() {
      return localStorage.getItem("refreshToken") || sessionStorage.getItem("refreshToken")
    }
  }
  