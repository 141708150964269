import React  from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Form,
  Input,
  Label,
  Button,
  InputGroup,
} from "reactstrap"
import { useFormik } from "formik"

const Filter = ({ Regions, setFilters }) => {
  const form = useFormik({
    initialValues: {
      FullName: '',
      ContractDate: '',
      ContractEndDate: '',
      RegionId: '',
      FromDate: "",
      ToDate: "",
    },
    onSubmit: values => {
      setFilters(values)
    },
  })

  const RegionDropdownItems = () => {
    return (
      Regions.map((role) => {
        return (
          <option key={role.name} value={role.id}>{role.name}</option>
        )
      })
    )
  }

  return (
    <React.Fragment>
      <Card className="">
        <CardBody className="">
          <Row>
            <Col>
              <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                <Form
                  className="filter form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    form.handleSubmit()
                    return false
                  }}
                >

                  <Row className="primary-menu ">
                    <Col className="col-6 col-xl-3">
                      <Label className="form-label w-100 text-center"> Ad Soyad </Label>
                      <Input
                        name="FullName"
                        className="form-control"
                        placeholder="Ad və Soyad"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.FullName || ""}
                        invalid={
                          form.touched.FullName && form.errors.FullName
                            ? true
                            : false
                        }
                      />
                    </Col>

                    <Col className="col-6 col-xl-3">
                      <Label className="form-label w-100 text-center"> Müqavilə başlama tarixi</Label>
                      <Input
                        name="ContractDate"
                        type="Date"
                        className="form-control"
                        placeholder=""
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.ContractDate || ""}
                        invalid={
                          form.touched.ContractDate && form.errors.ContractDate
                            ? true
                            : false
                        }
                      />
                    </Col>

                    <Col className="col-6 col-xl-3">
                      <Label className="form-label w-100 text-center"> Müqavilə bitmə tarixi</Label>
                      <Input
                        name="ContractEndDate"
                        type="Date"
                        className="form-control"
                        placeholder=""
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.ContractEndDate || ""}
                        invalid={
                          form.touched.ContractEndDate && form.errors.ContractEndDate
                            ? true
                            : false
                        }
                      />
                    </Col>

                    <Col className="col-6 col-xl-3">
                      <Label className="form-label w-100 text-center">Rayon</Label>
                      <Input
                        name="RegionId"
                        type="select"
                        className="form-control"
                        placeholder=""
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.RegionId || ""}
                        invalid={
                          form.touched.RegionId && form.errors.RegionId
                            ? true
                            : false
                        }
                      >
                        <option value="">Bütün rayonlar</option>
                        {RegionDropdownItems()}
                      </Input>
                    </Col>

                    <Col className="col-12 col-xl-6">
                      <Label className="form-label w-50 text-end">Səfərin tarixi</Label>
                      <InputGroup>
                        <Input
                          name="FromDate"
                          type="date"
                          className="form-control"
                          placeholder=""
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.FromDate || ""}
                          invalid={
                            form.touched.FromDate && form.errors.FromDate
                              ? true
                              : false
                          }
                        /><p className="m-2">-dən</p>
                        <Input
                          name="ToDate"
                          type="date"
                          className="form-control"
                          placeholder=""
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.ToDate || ""}
                          invalid={
                            form.touched.ToDate && form.errors.ToDate ? true : false
                          }
                        /><p className="m-2">-dək</p>
                      </InputGroup>
                    </Col>
                  </Row>
                  <div className="d-flex filter-icon-wrapper justify-content-end">
                    <Button className="bg-primary" type="submit"> Axtar</Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default Filter