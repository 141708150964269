import { useFormik } from "formik"
import formatDate from "helpers/format-date";
import React, { useState } from "react"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux"
import { Col, Form, FormFeedback, Input, Label, InputGroup, Row, Button } from "reactstrap"
import { selectLoadingPostUser } from "selectors/UsersSelectors";
import { requestPostFollowerContract } from "store/followers/actions";
import * as Yup from "yup"


export default function ContractForm({ follower = {}, pagination = {} }) {
  const [importedFile, setImportedFile] = useState(null);
  const [FileError, setFileError] = useState("");
  const isLoadingPostUser = useSelector(selectLoadingPostUser);

  const dispatch = useDispatch();

  function handleAddOrUpdate(contract) {
    const mode = follower.contractStart ? "edit" : "add";
    dispatch(requestPostFollowerContract(contract, mode, pagination))
  }

  const importInputHandler = (e) => {
    setFileError("");
    setImportedFile(null);
    if (e.target.files) {
      if (
        e.target.files[0]?.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        e.target.files[0]?.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        e.target.files[0]?.type === "application/msword" ||
        e.target.files[0]?.type === "application/vnd.ms-File" ||
        e.target.files[0]?.type === "application/pdf"
      ) {
        setImportedFile(e.target.files[0])
        setFileError(null);
      } else setFileError("Seçilən fayın tipi düzgün deyil.");
    } else setFileError("Fayl seçilməyib");
  }

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      UserId: follower.followerId,
      Salary: follower.salary || '',
      ContractFile: null,
      ContractStart: formatDate(follower.contractStart) || '',
      ContractEnd: formatDate(follower.contractEnd) || ''
    },
    validationSchema: Yup.object({
      Salary: Yup.number().required("Əmək haqqını daxil edin"),
      ContractStart: Yup.date().required("Müqavilə başlama tarixini daxil edin"),
      ContractEnd: Yup.date().required("Müqavilə bitmə tarixini daxil edin"),
    }),
    onSubmit: values => {
      const formData = new FormData();
      formData.append("ContractFile", importedFile);
      formData.append("UserId", values.UserId)
      formData.append("Salary", values.Salary)
      formData.append("ContractStart", values.ContractStart)
      formData.append("ContractEnd", values.ContractEnd)
      handleAddOrUpdate(formData)
    }
  })

  return (
    <Form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault()
        form.handleSubmit()
        return false
      }}
    >
      <Input hidden autoComplete="false" />
      <Row>

        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Müqavilə başlama tarixi</Label>
            <Input
              name="ContractStart"
              type="date"
              placeholder="Müqavilə başlama tarixi"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.ContractStart || ""}
              invalid={
                form.touched.ContractStart && form.errors.ContractStart
                  ? true
                  : false
              }
            />
            {form.touched.ContractStart && form.errors.ContractStart ? (
              <FormFeedback type="invalid">
                {form.errors.ContractStart}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Müqavilə bitmə tarixi</Label>
            <Input
              name="ContractEnd"
              type="date"
              placeholder="Müqavilə bitmə tarixi"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.ContractEnd || ""}
              invalid={
                form.touched.ContractEnd && form.errors.ContractEnd
                  ? true
                  : false
              }
            />
            {form.touched.ContractEnd && form.errors.ContractEnd ? (
              <FormFeedback type="invalid">
                {form.errors.ContractEnd}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label className="form-label">Əmək haqqı</Label>
            <Input
              name="Salary"
              type="text"
              placeholder="Əmək haqqını daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Salary || ""}
              invalid={
                form.touched.Salary && form.errors.Salary
                  ? true
                  : false
              }
            />
            {form.touched.Salary && form.errors.Salary ? (
              <FormFeedback type="invalid">
                {form.errors.Salary}
              </FormFeedback>
            ) : null}
          </div>
        </Col>

        <Col className="col-12 col-lg-6">
          <div className="mb-3">
            <Label>Yeni müqavilə</Label>
            <InputGroup>
              <Input
                type="file"
                name="importFile"
                onChange={importInputHandler}
              />
              <div className="text-center p-2">
                <b>
                  {importedFile ? `${Math.ceil(Number(importedFile.size) / 1000)} kb` : '0 kb'}
                </b>
              </div>
            </InputGroup>
            <p className="text-danger m-3">{FileError ? FileError : ''}</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            <Button
              type="submit"
              className="btn btn-success save-user"
              disabled={isLoadingPostUser}
            >
              {isLoadingPostUser && <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>} {" "}
              Yaddaşa yaz
            </Button>
          </div>
        </Col>
      </Row>
    </Form >
  )
}
