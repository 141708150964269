import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { FORGET_PASSWORD, RESTART_PASSWORD } from "./actionTypes"
import { postForgotPassword, postResetPassword } from "services"
import { toast } from "react-toastify"

function* forgetUser({ payload: { email, history } }) {
  try {
    const response = yield call(postForgotPassword, email)
    const { data } = response;
    if (data.success) {
      toast.success('Email uğurla göndərildi', {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  } catch (error) {

    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

function* ResetUser({ payload, router }) {
  try {
    const response = yield call(postResetPassword, payload)
    const { data } = response;

    if (data.success) {
      toast.success('Şifrə uğurla yeniləndi', {
        position: toast.POSITION.TOP_RIGHT
      });
      router.navigate("/login", { replace: true })
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  } catch (error) {

    toast.error(error, {
      position: toast.POSITION.TOP_RIGHT
    });
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser)
  yield takeEvery(RESTART_PASSWORD, ResetUser)
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)])
}

export default forgetPasswordSaga
