import { lazy, useCallback, useState } from "react"
import React from "react"
import ConfirmtionBody from "components/Common/ConfirmationModal/ConfirmtionBody"
import Confirmationfooter from "components/Common/ConfirmationModal/ConfirmationFooter"
// import ModalContainer from "components/Common/ModalContainer"
import { passPropsTo } from "helpers/component-helper"
import Loadable from "components/Common/Loadable"

const ModalContainer = Loadable(
  lazy(() => import(/* webpackChunkName: "ModalContainer" */ "components/Common/ModalContainer"))
);

export default function useModal(props = {}) {
  const [modals, setModals] = useState({})
  const [modalState, setModalState] = useState({})

  function generateModalId() {
    return Math.random().toString(36).substring(2, 9)
  }

  const confirm = ({
    title,
    confirmText,
    cancelText,
    onConfirm,
    type = "delete",
    actions,
  }) => {
    const content = <ConfirmtionBody type={type} text={title} />
    const modalId = generateModalId()
    const handleCloseModal = () => {
      closeModal(modalId)
    }
    const footer = (
      <Confirmationfooter
        type={type}
        actions={actions}
        confirmText={confirmText}
        cancelText={cancelText}
        onConfirm={() => onConfirm({ modalId, closeModal: handleCloseModal })}
      />
    )

    openModal({ content, footer, size: "sm" }, modalId)
  }

  const openModal = useCallback(
    (
      {
        header,
        content,
        footer,
        size,
        fullscreen,
        customContent = null,
        hasRef = false,
        initialState = {},
      },
      manualId
    ) => {
      const closeFromChild = () => closeModal(id)
      const id = manualId || generateModalId()
      const childProps = { closeModal: closeFromChild }

      setModals(prevModals => ({
        ...prevModals,
        [id]: {
          header: passPropsTo(header, childProps),
          content: passPropsTo(content, childProps),
          footer: passPropsTo(footer, childProps),
          customContent: passPropsTo(customContent, childProps),
          size,
          isFullscreen: fullscreen,
          isOpen: true,
          initialState,
          hasRef,
        },
      }))
      return id
    },
    []
  )

  function closeModal(id) {
    setModals(prevModals => ({
      ...prevModals,
      [id]: {
        ...prevModals[id],
        isOpen: false,
      },
    }))

    // Delay removal to allow for transition
    setTimeout(() => {
      setModals(prevModals => {
        const newModals = { ...prevModals }
        delete newModals[id]
        return newModals
      })

      setModalState(prevState => ({
        ...prevState,
        [id]: undefined,
      }))
    }, 300) // Adjust this delay to match your transition duration
  }

  const renderModals = useCallback(() => {
    return Object.keys(modals).map(id => {
      const modalOptions = modals[id];
      return (
        <ModalContainer
          key={id}
          toggle={() => closeModal(id)}
          modalProps={props}
          {...modalOptions}
        />
      )
    })
  }, [modals, props])

  return { openModal, renderModals, confirm, closeModal }
}
