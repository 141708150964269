import {
  REQUEST_LOGIN_USER,
  REQUEST_LOGIN_USER_FINISHED,
  REQUEST_LOGIN_USER_DELAYED,
  LOGOUT_USER,
  API_ERROR,
} from "./actionTypes"

export const requestLoginUser = (user, history) => {
  return {
    type: REQUEST_LOGIN_USER,
    payload: { user, history },
  }
}

export const requestLoginUserFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_LOGIN_USER_FINISHED,
    payload,
    error,
    meta
  }
}

export const loginUserDelayed = () => {
  return {
    type: REQUEST_LOGIN_USER_DELAYED,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const apiError = error => {
  return {
    type: API_ERROR,
    payload: error,
  }
}

