import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Error
import Error from "./error/reducer";

// Requesting
import Requesting from "./requesting/reducer";
import Beneficiars from "./beneficiars/reducer"
import QA from "./qa/reducer";
import Users from "./users/reducer";
import ObservationsCount from "./observationCount/reducer";
import CommonStats from "./commonStats/reducer";
import BeneficiarImages from "./beneficiar-images/reducer";
import Questions from "./questions/reducer";
import Followers from "./followers/reducer";

import Auth from "./auth/login/reducer";
import Business from "./businesses/reducer";
const rootReducer = combineReducers({
  Error,
  Requesting,
  Layout,
  Beneficiars,
  QA,
  Users,
  ObservationsCount,
  CommonStats,
  BeneficiarImages,
  Questions,
  Followers,
  Business,
  Auth
});

export default rootReducer;
