import React, { lazy } from "react"

// project import
import Loader from "./Loader"

function Loadable(Component) {
  return function SuspensedComponent(props) {
    return (
      <React.Suspense fallback={<Loader />}>
        <Component {...props} />
      </React.Suspense>
    )
  }
}

export function LoadLazy({ loader, delay = 500 }) {
  if (!loader || typeof loader !== 'function') {
    throw Error("[loader] is undefined in LoadLazy")
  }
  return Loadable(
    lazy(async () => {
      return Promise.all([
        loader(),
        new Promise(resolve => setTimeout(resolve, delay)),
      ]).then(([moduleExports]) => moduleExports)
    })
  )
}

export default Loadable
