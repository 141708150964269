import {
  REQUEST_CHANGE_PASSWORD,
  REQUEST_CHANGE_PASSWORD_FINISHED,
  API_ERROR,
} from "./actionTypes"




export const requestChangePassword = (user, history) => {
  return {
    type: REQUEST_CHANGE_PASSWORD,
    payload: { user, history },
  }
}

export const requestChangePasswordrFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_CHANGE_PASSWORD_FINISHED,
    payload,
    error,
    meta
  }
}

// export const apiError = error => {
//   return {
//     type: API_ERROR,
//     payload: error,
//   }
// }

