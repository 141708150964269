import {
  REQUEST_QUESTIONS,
  REQUEST_QUESTIONS_FINISHED,
  REQUEST_POST_QUESTION,
  REQUEST_POST_QUESTION_FINISHED,
  REQUEST_DELETE_QUESTION,
  REQUEST_DELETE_QUESTION_FINISHED,
  REQUEST_ANSWERTYPES,
  REQUEST_ANSWERTYPES_FINISHED
} from "./actionTypes"

export const requestQestions = (payload) => {
  return {
    type: REQUEST_QUESTIONS,
    payload
  }
}

export const requestQuestionsFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_QUESTIONS_FINISHED,
    payload,
    error,
    meta,
  }
}

export const requestPostQuestion = (payload) => {
  return {
    type: REQUEST_POST_QUESTION,
    payload
  }
}

export const requestPostQuestionsFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_QUESTION_FINISHED,
    payload,
    error,
    meta,
  }
}

export const requestDeleteQuestion = (payload) => {
  return { type: REQUEST_DELETE_QUESTION, payload }
}

export const requestDeleteQuestionFinished = (payload, error = false, meta = null) => {
  return { type: REQUEST_DELETE_QUESTION_FINISHED, payload, error, meta }
}

export const requestAnswerTypes = (payload) => {
  return {
    type: REQUEST_ANSWERTYPES,
    payload
  }
}

export const requestAnswerTypesFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_ANSWERTYPES_FINISHED,
    payload,
    error,
    meta,
  }
}