import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  CardHeader,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  h4,
  CardSubtitle,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { requestQestions } from "store/questions/actions"
import { useFormik } from "formik"


const Observations = [
  {
    id: 1,
    title: "Müşahidə səfəri 1"
  },
  {
    id: 2,
    title: "Müşahidə səfəri 2"
  },
  {
    id: 3,
    title: "Müşahidə səfəri 3"
  },
  {
    id: 4,
    title: "Müşahidə səfəri 4"
  }
]

const Filter = ({ setFilters }) => {
  const dispatch = useDispatch();

  const form = useFormik({
    initialValues: {
      question: '',
      observation: ''
    },
    onSubmit: values => {
      setFilters(values)
      // dispatch(requestQestions(values));
    },
  })

  const ObservationDropdownItems = () => {
    return (
      Observations.map((observation) => {
        return (
          <option key={observation.id} value={observation.id}>{observation.title}</option>
        )
      })
    )
  }

  return (
    <React.Fragment>
      <Card className="">
        <CardBody className="">
          <Row>
            <Col>
              <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                <Form
                  className="filter form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    form.handleSubmit()
                    return false
                  }}
                >

                  <Row className="primary-menu ">
                    <Col className="col-12 col-xl-6">
                      <Label className="form-label"> Sualın məzmunu </Label>
                      <Input
                        name="question"
                        className="form-control"
                        placeholder=""
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.question || ""}
                        invalid={
                          form.touched.question && form.errors.question
                            ? true
                            : false
                        }
                      />
                    </Col>


                    <Col className="col-12 col-xl-6">
                      <Label className="form-label"> Müşahidə səfəri </Label>
                      <Input
                        name="observation"
                        type="select"
                        className="form-control"
                        placeholder=""
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.observation || ""}
                        invalid={
                          form.touched.observation && form.errors.observation
                            ? true
                            : false
                        }
                      >
                        <option value="">Bütün Müşahidə səfərləri</option>
                        {ObservationDropdownItems()}
                      </Input>
                    </Col>
                  </Row>
                  <div className="d-flex filter-icon-wrapper justify-content-end">
                    <button type="submit" className="btn btn-primary">
                      Axtar</button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default Filter