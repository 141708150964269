import formatDate from "helpers/format-date"
import React from "react"
export const followerColumns = [
  {
    header: "Ad Soyad",
    accessorKey: "fullName",
  },
  {
    header: "Müqavilə başlama tarixi",
    accessorKey: "contractStart",
    cell: ({ row }) => {
      if (row.original.contractStart) {
        return formatDate(row.original.contractStart)
      }
    }
  },
  {
    header: "Müqavilə bitmə tarixi",
    accessorKey: "contractEnd",
    cell: ({ row }) => {
      if (row.original.contractEnd) {
        return formatDate(row.original.contractEnd)
      }
    }
  },
  {
    header: () => {
      return <p className="p-0 m-0">Əmək haqqı <span className="font-size-18">₼</span> </p>
    },
    accessorKey: "salary",
  },

  {
    header: "Müqavilənin özü",
    accessorKey: "contractPath",
    cell: ({ row }) => {
      const path = row.original.contractPath;
      if (path) {
        return <a
          className="btn btn-success p-0" href={path} download='follower.pdf'><i className="mdi mdi-file-document font-size-24"></i></a>
      }
    }
  },
  {
    header: "1 ci Müşahidə səfərləri",
    accessorKey: "firstObservationCount",
  },
  {
    header: "2 ci Müşahidə səfərləri",
    accessorKey: "secondObservationCount",
  },
  {
    header: "3 cü Müşahidə səfərləri",
    accessorKey: "thirdObservationCount",
  },
  {
    header: "4 cü Müşahidə səfərləri",
    accessorKey: "fourthObservationCount",
  },
  {
    header: "Toplam",
    accessorKey: "totalCount",
  },
  
]
