import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,

} from "@tanstack/react-table"
import React, { useCallback } from "react"
import { useState } from "react"
import { Button, Input, Table } from "reactstrap"
import { Filter } from "../filters"
import { useMemo } from "react"
import { Draggable, Droppable } from "react-beautiful-dnd"
import SkeletonLoading from "../SkeletonLoading"
import { useEffect } from "react"

export default function EnhancedTable({
  data = [],
  columns = [],
  isLoading = false,
  defaultColumn = {},
  pageStartFrom = 0,
  beneficiarSetter,
  DataCount,
  pagination = {},
  droppableId,
  followers,
  SelectBeneficiarObservationHandler,
  manualPagination,
  onPaginationChange
}) {
  const [expanded, setExpanded] = useState({})
  const tableData = useMemo(
    () => (isLoading ? Array(5).fill({}) : data),
    [isLoading, data]
  )

  const mapToLoading = useCallback((columns) => {
    return columns.map(column => {
      if (column.columns && Array.isArray(column.columns)) {
        return { ...column, columns: mapToLoading(column.columns) }
      } else {
        return { ...column, cell: <SkeletonLoading /> }
      }
    })
  }, [])

  const tableColumns = useMemo(
    () =>
      isLoading ? columns.map(column => {
        if (column.columns && Array.isArray(column.columns)) {
          return { ...column, columns: mapToLoading(column.columns) }
        } else {
          return { ...column, cell: <SkeletonLoading /> }
        }
      }, []) : columns,
    [isLoading, columns, mapToLoading]
  )

  const [columnFilters, setColumnFilters] = useState([])

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    defaultColumn: { ...defaultColumn },
    pageCount: Math.ceil(DataCount / Number(pagination.pageSize)) || 1,
    beneficiarSetter,
    state: {
      pagination,
      expanded,
      columnFilters,
      followers,
      SelectBeneficiarObservationHandler,
    },

    onExpandedChange: setExpanded,
    getSubRows: row => row.subItems,
    onExpandedChange: setExpanded,
    onPaginationChange, manualPagination,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getExpandedRowModel: getExpandedRowModel(),

    debugTable: false,
    enableExpanding: true,
    enablePinning: true,
  })

  return (
    <>
      <div className="table-responsive react-table">
        <Table className="project-list-table table-nowrap align-middle table-borderless">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr style={{ backgroundColor: "#e7deeb" }} key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{
                        textAlign: header.column.columnDef.meta?.align,
                        borderBottom: header.column.columnDef.meta?.border?.bottom,
                        left: 0,
                        width: 200,
                        position: header.column.columnDef.accessorKey === 'fullName' ? 'sticky' : '',
                        background: header.column.columnDef.accessorKey === "fullName" ? "#e7deeb" : ''
                      }} >
                      {header.isPlaceholder ? null : (
                        <div>
                          <h5 style={{ fontSize: "14px" }}>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </h5>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          {droppableId ? (
            <Droppable droppableId={droppableId}>
              {provided => (
                <tbody>
                  {table.getRowModel().rows.map(row => {
                    return (
                      <tr style={{ borderBottom: "1px solid gray" }} key={row.id}>
                        {row.getVisibleCells().map(cell => {
                          return (
                            <td
                              key={cell.id}
                              align={cell.column.columnDef.meta?.align}
                              style={{
                                borderRight: cell.column.columnDef.meta?.border?.right
                              }}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })}
                </tbody>
              )}
            </Droppable>
          ) : null}
          {!droppableId && (
            <tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <tr key={row.id} style={{
                    backgroundColor: `${row.parentId ? '#e2e4ea' : ''}`,
                    borderBottom: `${!row.parentId ? '1px solid #e2e4ea' : ''}`,
                  }}>
                    {row.getVisibleCells().map((cell,i) => {
                      return (
                        <td
                          key={cell.id}
                          align={cell.column.columnDef.meta?.cellAlign}
                          style={{
                            left: 0,
                            width: 200,
                            position: cell.column.columnDef.accessorKey === "fullName" ? 'sticky' : '',
                            background: cell.column.columnDef.accessorKey === "fullName" ? "#fff" : ''
                          }}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
              {table.getRowModel().rows.length === 0 && (
                <tr>
                  <td align="center" colSpan={table.getAllColumns().length}>
                    No data
                  </td>
                </tr>
              )}
            </tbody>
          )}
        </Table>
      </div>
      <div
        style={{ overflow: "auto", margin: '20px 0' }}
        className="mb-3 d-flex justify-content-between flex-wrap gap-3"
      >
        <div>
          <select
            className="form-select"
            value={table.getState().pagination.pageSize}
            onChange={e => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>


        </div>
        <b style={{ margin: 'auto' }}>{DataCount ? DataCount : data?.length} Nəticə tapıldı</b>
        <div className="d-flex gap-3 align-items-center">
          <Button
            color="primary"
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <i className="mdi mdi-chevron-double-left"></i>
          </Button>
          <Button
            color="primary"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <i className="mdi mdi-chevron-left"></i>
          </Button>
          <span className="flex items-center gap-1">
            <span>Page </span>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {" "}
              {table.getPageCount()}
              {/* {Math.ceil(DataCount ? DataCount : data?.length / Number(table.getState().pagination.pageSize))} */}
            </strong>
          </span>
          <Input
            type="number"
            min={1}
            style={{ width: 70 }}
            max={table.getPageCount()}
            value={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
          />
          <Button
            color="primary"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <i className="mdi mdi-chevron-right"></i>
          </Button>
          <Button
            color="primary"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <i className="mdi mdi-chevron-double-right"></i>
          </Button>
        </div>
      </div>
    </>
  )
}
