import {
  REQUEST_ATTACHED_BENEFICIARIES,
  REQUEST_ATTACHED_BENEFICIARIES_FINISHED,
  REQUEST_POST_ANSWER_QUESTION,
  REQUEST_POST_ANSWER_QUESTION_FINISHED,
  REQUEST_QUESTIONS_BY_FILTER,
  REQUEST_QUESTIONS_BY_FILTER_FINISHED,
  REQUEST_DETAILED_FOLLOWERS_FINISHED,
  REQUEST_DETAILED_FOLLOWERS,
  REQUEST_POST_FOLLOWER_CONTRACT,
  REQUEST_POST_FOLLOWER_CONTRACT_FINISHED,
  REQUEST_POST_COMPLETE_OBSERVATION_FINISHED,
  REQUEST_POST_COMPLETE_OBSERVATION,
  REQUEST_POST_EQUIPMENT_FINISH,
  REQUEST_POST_EQUIPMENT,
  REQUEST_EXPORT_FOLLOWER_EXCEL,
  REQUEST_EXPORT_FOLLOWER_EXCEL_FINISHED,
  REQUEST_EXPORT_ATTACHED_BENEFICIARIES_FINISHED,
  REQUEST_EXPORT_ATTACHED_BENEFICIARIES,
} from "./actionTypes"

export const requestAttachedBeneficiaries = (payload) => {
  return {
    type: REQUEST_ATTACHED_BENEFICIARIES,
    payload
  }
}

export const requestAttachedBeneficiariesFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_ATTACHED_BENEFICIARIES_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestExportAttachedBeneficiaries = (payload) => {
  return {
    type: REQUEST_EXPORT_ATTACHED_BENEFICIARIES,
    payload
  }
}

export const requestExportAttachedBeneficiariesFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_EXPORT_ATTACHED_BENEFICIARIES_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestDetailedFollowers = (payload) => {
  return {
    type: REQUEST_DETAILED_FOLLOWERS,
    payload
  }
}

export const requestDetailedFollowersFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_DETAILED_FOLLOWERS_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestPostFollowerContract = (payload, mode, pagination) => {
  return {
    type: REQUEST_POST_FOLLOWER_CONTRACT,
    payload,
    mode,
    pagination
  }
}
export const requestPostFollowerContractFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_FOLLOWER_CONTRACT_FINISHED,
    payload,
    error,
    meta
  }
}



export const requestObservationQuestions = (payload) => {
  return {
    type: REQUEST_QUESTIONS_BY_FILTER,
    payload
  }
}

export const requestObservationQuestionsFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_QUESTIONS_BY_FILTER_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestPostQuestionAnswer = (payload, mode, pagination) => {
  return {
    type: REQUEST_POST_ANSWER_QUESTION,
    payload,
    mode,
    pagination
  }
}

export const requestPostQuestionAnswerFinished = (payload) => {
  return {
    type: REQUEST_POST_ANSWER_QUESTION_FINISHED,
    payload
  }
}

export const requestPostCompleteObservation = (payload) => {
  return {
    type: REQUEST_POST_COMPLETE_OBSERVATION,
    payload
  }
}

export const requestPostCompleteObservationFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_COMPLETE_OBSERVATION_FINISHED,
    payload,
    error,
    meta
  }
}

export const requestPostEquipment = (payload, pagination) => {
  return {
    type: REQUEST_POST_EQUIPMENT,
    payload,
    pagination
  }
}

export const requestPostEquipmentFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_POST_EQUIPMENT_FINISH,
    payload,
    error,
    meta
  }
}

export const requestExportFollowerExcel = (payload) => {
  return {
    type: REQUEST_EXPORT_FOLLOWER_EXCEL,
    payload,
  }
}

export const requestExportFollowerExcelFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_EXPORT_FOLLOWER_EXCEL_FINISHED,
    payload,
    error,
    meta
  }
}