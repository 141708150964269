import {
  REQUEST_OBSERVATIONS_COUNT ,
  REQUEST_OBSERVATIONS_COUNT_FINISHED,
} from "./actionTypes"



export const requestObservationsCount = (payload) => {
  return {
    type: REQUEST_OBSERVATIONS_COUNT,
    payload
  }
}

export const requestObservationsCountFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_OBSERVATIONS_COUNT_FINISHED,
    payload,
    error,
    meta
  }
}


