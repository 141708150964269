import {
  REQUEST_USERS_FINISHED,
  REQUEST_POST_USER,
  REQUEST_POST_USER_FINISHED,
  REQUEST_DELETE_USER,
  REQUEST_DELETE_USER_FINISHED,
  REQUEST_REGIONS_FINISHED,
  REQUEST_CHANGE_PASSWORD_AS_ADMIN_FINISHED
} from "./actionTypes"

const initialState = {
  list: [],
  regions: []
}

const Users = (state = initialState, { type, payload, error, meta }) => {
  if (error) return state;

  switch (type) {
    case REQUEST_USERS_FINISHED:
      return { ...state, list: payload.list, count: payload.count }

    // case REQUEST_POST_USER:
    //   return { ...state, postResult: null }

    case REQUEST_POST_USER_FINISHED:
      return { ...state, postResult: payload }

    case REQUEST_DELETE_USER:
      return { ...state, postResult: null }

    case REQUEST_DELETE_USER_FINISHED:
      return { ...state, deleteResult: payload }

    case REQUEST_CHANGE_PASSWORD_AS_ADMIN_FINISHED:
      return { ...state, changePasswordResult: payload }

    case REQUEST_REGIONS_FINISHED:
      return { ...state, regions: payload }

    default:
      return state
  }
}

export default Users
