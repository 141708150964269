import { REQUEST_QA, REQUEST_QA_FINISHED } from "./actionTypes"

export const requestQA = (payload) => {
  return {
    type: REQUEST_QA,
    payload
  }
}

export const requestQAFinished = (payload, error = false, meta = null) => {
  return {
    type: REQUEST_QA_FINISHED,
    payload,
    error,
    meta,
  }
}
