export const REQUEST_QUESTIONS = "REQUEST_QUESTIONS"
export const REQUEST_QUESTIONS_FINISHED = "REQUEST_QUESTIONS_FINISHED"

export const REQUEST_POST_QUESTION = "REQUEST_POST_QUESTION"
export const REQUEST_POST_QUESTION_FINISHED = "REQUEST_POST_QUESTION_FINISHED"

export const REQUEST_DELETE_QUESTION = "REQUEST_DELETE_QUESTION"
export const REQUEST_DELETE_QUESTION_FINISHED = "REQUEST_DELETE_QUESTION_FINISHED"

export const REQUEST_ANSWERTYPES = "REQUEST_ANSWERTYPES"
export const REQUEST_ANSWERTYPES_FINISHED = "REQUEST_ANSWERTYPES_FINISHED"