import { useFormik } from "formik"
import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Col, Form, FormFeedback, Input, Label, Row } from "reactstrap"
import { requestPostQuestionAnswer } from "store/followers/actions"
import * as Yup from "yup"
import Select from 'react-select';


export default function ObservationQuestionForm({ question = {}, data = {}, type, pagination }) {
  const [isOtherAnswer, setIsOtherAnswer] = useState(false);
  const [otherAnswer, setOtherAnswer] = useState(null);
  const [multiAnswers, setMultiAnswers] = useState([]);
  const dispatch = useDispatch();

  const options = question.answers.map((answer) => {
    return {
      'label': answer.content,
      'value': answer.content
    }
  })

  useEffect(() => {
    if (question && question.answerType === "multi-dropdown" && question.answerText) {
      const answers = question?.answerText?.split(";")
      const options = answers.map((answer) => {
        return {
          'label': answer,
          'value': answer
        }
      })
      setMultiAnswers(options)
    }
  }, [question])



  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      BeneficiaryId: data.BeneficiaryId || data.beneficiarId,
      ObservationId: data.ObservationId || data.observationId,
      QuestionId: question.id,
      AnswerText: question.answerText || ""
    },
    validationSchema: Yup.object({

    }),
    onSubmit: values => {
      let answerText = "";
      if (otherAnswer) {
        answerText = otherAnswer
      } else if (question.answerType === "multi-dropdown") {
        if (multiAnswers.length > 0) {
          for (let i = 0; i < multiAnswers.length; i++) {
            answerText += `${multiAnswers[i].value};`
          }
        } else answerText = ""

      } else {
        answerText = String(values.AnswerText)
      }
      const formatdetAnswer = {
        BeneficiaryId: values.BeneficiaryId,
        ObservationId: values.ObservationId,
        QuestionId: values.QuestionId,
        AnswerText: String(answerText)
      }
      dispatch(requestPostQuestionAnswer(formatdetAnswer, type, pagination));
    }
  })

  useEffect(() => {
    if (form.values.AnswerText && typeof form.values.AnswerText === 'string' && form.values.AnswerText.toLocaleLowerCase() === 'digər') {
      setIsOtherAnswer(true);
    } else {
      setIsOtherAnswer(false);
      setOtherAnswer(null);
    }
  }, [form.values.AnswerText])







  const InputTypehandler = () => {
    switch (question.answerType) {
      case 'dropdown':
        return <div className="col-12 my-2">
          <Input
            name="AnswerText"
            type="select"
            placeholder="Enter Answer"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.AnswerText || ""}
            invalid={form.touched.AnswerText && form.errors.AnswerText ? true : false}
          >
            <option value=''>Cavab seçin</option>
            {question.answers.map((answer) => {
              return <option key={answer.content} value={answer.content}>{answer.content}</option>
            })}

          </Input>
          {form.touched.AnswerText && form.errors.AnswerText ? (
            <FormFeedback type="invalid">
              {form.errors.AnswerText}
            </FormFeedback>
          ) : null}
        </div>
      case 'multi-dropdown':
        return <div className="col-12 my-2">
          <Select
            isMulti
            name="AnswerText"
            options={options}
            className="basic-multi-select bg-light"
            classNamePrefix="select"
            styles={{
              control: (styles) => ({ ...styles, backgroundColor: '#fff', boxShadow: "none" }),
              option: (styles) => ({ ...styles, backgroundColor: '#fff', padding: '5px', fontSize: '15px' }),
            }}
            onChange={setMultiAnswers}
            value={multiAnswers}
          />
          {/* <Input
            name="AnswerText"
            type="select"
            multiple={true}
            placeholder="Enter Answer"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.AnswerText || ""}
            invalid={form.touched.AnswerText && form.errors.AnswerText ? true : false}
          >
            <option value=''>Cavab seçin</option>
            {question.answers.map((answer) => {
              return <option key={answer.content} value={answer.content}>{answer.content}</option>
            })}

          </Input> */}
          {form.touched.AnswerText && form.errors.AnswerText ? (
            <FormFeedback type="invalid">
              {form.errors.AnswerText}
            </FormFeedback>
          ) : null}
        </div>
      case 'bool':
        return <div>
          <Input
            name="AnswerText"
            type="select"
            placeholder="Enter Answer"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.AnswerText || ""}
            invalid={
              form.touched.AnswerText && form.errors.AnswerText
                ? true
                : false
            }
          >
            <option value=''>Cavab seçin</option>
            <option value="Bəli">Bəli</option>
            <option value="Xeyr">Xeyr</option>

          </Input>
          {form.touched.AnswerText && form.errors.AnswerText ? (
            <FormFeedback type="invalid">
              {form.errors.AnswerText}
            </FormFeedback>
          ) : null}
        </div>
      case '1-10':
        return <div>
          <Input
            name="AnswerText"
            type="select"
            placeholder="Enter Answer"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.AnswerText || ""}
            invalid={
              form.touched.AnswerText && form.errors.AnswerText
                ? true
                : false
            }
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>

          </Input>
          {form.touched.AnswerText && form.errors.AnswerText ? (
            <FormFeedback type="invalid">
              {form.errors.AnswerText}
            </FormFeedback>
          ) : null}
        </div>
      case 'text':
        return <div>
          <Input
            name="AnswerText"
            type="text"
            placeholder="Enter Answer"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.AnswerText || ""}
            invalid={
              form.touched.AnswerText && form.errors.AnswerText
                ? true
                : false
            }
          />
          {form.touched.AnswerText && form.errors.AnswerText ? (
            <FormFeedback type="invalid">
              {form.errors.AnswerText}
            </FormFeedback>
          ) : null}
        </div>
      case 'datetime':
        return <div>
          <Input
            name="AnswerText"
            type="date"
            placeholder="Enter Answer"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.AnswerText || ""}
            invalid={
              form.touched.AnswerText && form.errors.AnswerText
                ? true
                : false
            }
          />
          {form.touched.AnswerText && form.errors.AnswerText ? (
            <FormFeedback type="invalid">
              {form.errors.AnswerText}
            </FormFeedback>
          ) : null}
        </div>
      case 'number':
        return <div>
          <Input
            name="AnswerText"
            type="number"
            placeholder="Enter Answer"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.AnswerText}
            invalid={
              form.touched.AnswerText && form.errors.AnswerText
                ? true
                : false
            }
          />
          {form.touched.AnswerText && form.errors.AnswerText ? (
            <FormFeedback type="invalid">
              {form.errors.AnswerText}
            </FormFeedback>
          ) : null}
        </div>
      default: return <div>
        <Input
          name="AnswerText"
          type="text"
          placeholder="Enter Answer"
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          value={form.values.AnswerText || ""}
          invalid={
            form.touched.AnswerText && form.errors.AnswerText
              ? true
              : false
          }
        />
        {form.touched.AnswerText && form.errors.AnswerText ? (
          <FormFeedback type="invalid">
            {form.errors.AnswerText}
          </FormFeedback>
        ) : null}
      </div>
    }

  }

  return (
    <Form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault()
        form.handleSubmit()
        return false
      }}
    >
      <Input hidden autoComplete="false" />
      <Row>
        <Col className="col-lg-12 col-md-5">
          <div className="mb-3">
            <Label className="form-label font-size-18">{question.content}</Label>
            <Row className="mt-3">
              <Label>Cavab</Label>
              {InputTypehandler()}
              {isOtherAnswer && <div className="col-12 my-2">
                <Label>Diger cavab</Label>
                <Input

                  name="AnswerText"
                  type="text"
                  placeholder="Enter Answer"
                  onChange={e => setOtherAnswer(e.target.value)}
                  value={otherAnswer || ""}
                />
              </div>}
            </Row>

          </div>
        </Col>


      </Row>
      <Row>
        <Col>
          <div className="text-end">
            {/* disabled={isLoadingPostUser} */}
            <button type="submit" className="btn btn-success save-user">
              Confirm
            </button>
          </div>
        </Col>
      </Row>
    </Form >
  )
}
