import PropTypes from "prop-types"
import React from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { authProtectedRoutes, publicRoutes } from "./routes"
import Authmiddleware from "./routes/route"
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import { useSelector } from "react-redux"
import "./assets/scss/theme.scss"

const App = () => {
  const user = useSelector(state => state.Auth);

  const renderedPrivateRoots = authProtectedRoutes.filter(
    route => route.allowedRoles.includes(user.role)
  )
  
  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={route.id}
            exact={true}
          />
        ))
        }

        {renderedPrivateRoots.map((route) => {
          return (
            <React.Fragment key={route.id}>
              <Route
                path={route.path}
                element={
                  <Authmiddleware>
                    <VerticalLayout>{route.component}</VerticalLayout>
                  </Authmiddleware>
                }
                key={route.id}
                exact={true}
              />
              {route.subItems.map((subRoute) => {
                return (
                  <Route
                    path={subRoute.path}
                    element={
                      <Authmiddleware>
                        <VerticalLayout>{subRoute.component}</VerticalLayout>
                      </Authmiddleware>
                    }
                    key={subRoute.id}
                    exact={true}
                  />
                )
              })}
            </React.Fragment>
          )
        })}

        {
          user.isUserLoggedIn && <Route
            path="/"
            index
            element={<Navigate to={Object.keys(renderedPrivateRoots).length > 0 ? renderedPrivateRoots[0]?.path : publicRoutes[0]?.path} />}
          />
        }
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = { layout: PropTypes.any }

export default App
