import React, { useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { withTranslation } from "react-i18next"
import Filter from "./Filter"
import UserForm from "./ContractForm"
import EnhancedTable from "components/Common/EnhancedTable"
import { followerColumns } from "./Table/columns"
import { requestRegions } from "store/actions"
import { selectRegions } from "selectors/UsersSelectors"
import { selectContractPostResult, selectFollowerExportResult, selectLoadingDetailedFollowers } from "selectors/FollowersSelectors"
import { selectDetailedFollowers } from "selectors/FollowersSelectors"
import { useEffect } from "react"
import { requestDetailedFollowers, requestExportFollowerExcel } from "store/followers/actions"

//meta title

const Accounting = props => {
  document.title = "Mühasibatlıq | Self Employment Project";
  const isLoading = useSelector(selectLoadingDetailedFollowers);
  const exportResult = useSelector(selectFollowerExportResult);
  const selectedFollowers = useSelector(selectDetailedFollowers);
  const selectedRegions = useSelector(selectRegions);
  const postContractResult = useSelector(selectContractPostResult);
  const [filters, setFilters] = useState({});
  const [modal, setModal] = useState(false);
  const [isExportExcelLoading, setIsExportExcelLoading] = useState(false);

  const [selectedData, setSelectedData] = useState(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  })
  const dispatch = useDispatch()

  function handleEditFollower(userData) {
    setModal(true)
    setSelectedData(userData)
  }


  const handleToggleUserFormModal = () => {
    setModal(prevOpen => !prevOpen)
  }

  const handleExportFollowersExcel = () => {
    if (!isExportExcelLoading) {
      setIsExportExcelLoading(true);
      dispatch(
        requestExportFollowerExcel({
          ...filters,
          pageIndex: 1,
          pageSize: 1000,
        })
      )
    }
  }


  const tableColumns = useMemo(
    () => [
      ...followerColumns,
      {
        header: "Action",
        accessorKey: "action",
        cell: cellProps => {
          return (
            <div className="d-flex justify-content-center">
              <div
                className="text-success"
                // onClick={() => beneficiarSetter(row.original.beneficiarId)}
                onClick={() => handleEditFollower(cellProps.row.original)}
              >
                <i
                  id="edittooltip"
                  className="mdi mdi-pencil font-size-18"
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    padding: "15px",
                  }}
                ></i>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </div>
            </div>
          )
        },
      },
    ],
    []
  )

  useEffect(() => {
    if (!Object.keys(selectedRegions).length > 0) dispatch(requestRegions())
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      requestDetailedFollowers({
        ...filters,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      })
    )
  }, [dispatch, filters, pagination]);

  useEffect(() => {
    if (postContractResult && postContractResult?.success) setModal(false)
  }, [postContractResult])

  useEffect(() => {
    if (exportResult && exportResult.success) setIsExportExcelLoading(false)
  }, [exportResult])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="MÜHASİBATLIQ"
            breadcrumbItem="MÜHASİBATLIQ"
          />
          <Row>
            <Col>
              <Filter Regions={selectedRegions} setFilters={setFilters} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row className="justify-content-between my-2">
                    <Col className="col-7 d-flex">
                      <div className="me-3">
                        <button
                          className={`btn me-3 ${isExportExcelLoading ? "btn-secondary" : "btn-success"}`}
                          onClick={handleExportFollowersExcel}
                          disabled={isExportExcelLoading}
                        >{isExportExcelLoading ? (
                          <span
                            className="spinner-border spinner-border-sm me-3  p-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : <i className="mdi mdi-download me-2 font-size-18"></i>}
                          {" "}
                          Export
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <EnhancedTable
                    onPaginationChange={setPagination}
                    pagination={pagination}
                    manualPagination
                    data={selectedFollowers.followerDetails}
                    DataCount={selectedFollowers.count}
                    columns={tableColumns}
                    isLoading={isLoading}
                  />
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>
      <Modal size="lg" isOpen={modal} toggle={handleToggleUserFormModal} centered>
        <ModalHeader toggle={handleToggleUserFormModal} tag="h4">
          Müqavilə detalları
        </ModalHeader>
        <ModalBody>
          <UserForm
            follower={selectedData}
            pagination={pagination}
          />
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(Accounting)
