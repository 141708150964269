import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, InputGroup } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { resetForgotPassword, userResetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import LogoLightEkvita from "assets/images/Logo_ss.png"
import { useState } from "react";

const ResetPassword = props => {
  document.title = "Reset Password | Self Employment Project";
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const token = props.router.location.search.split("?token=")[1];
  const form = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Şifrə doldurulmayıb").matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/, "Şifrə az 8 karakterə, ən az bir böyük və bir kiçik hərfə , ən az bir rəqəmə sahib olmalıdır və yalnız ingiliz əlifbası istifadə olunmalıdır"),
    }),
    onSubmit: (values) => {
      const payload = {
        token: token,
        password: values.password
      }
      dispatch(userResetPassword({ payload: payload, router: props.router }));
    }
  });


  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5>Şifrənin yenilənməsi</h5>
                        <p>Yeni şifrə təyin edin, daha sonra Giriş səhifəsinə dönərək hesabınıza daxil olun.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">

                  <div>
                    <div className="avatar-md profile-user-wid mb-4 w-50 logo-dark-element">
                      <span className="avatar-title rounded-2 overflow-hidden">
                        <img style={{ width: "100%", objectFit: 'cover' }} src={LogoLightEkvita} />
                      </span>
                    </div>
                  </div>
                  <div className="p-2">
                    {/* {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null} */}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        form.handleSubmit();
                        return false;
                      }}
                    >
                      <Col className="mb-3">
                        <Label className="form-label">Yeni şifrə</Label>
                        <InputGroup>
                          <Input
                            name="password"
                            type={`${showPassword ? 'text' : 'password'}`}
                            placeholder="Şifrəni daxil edin"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.password || ""}
                            autoComplete='new-password'
                            invalid={
                              form.touched.password && form.errors.password
                                ? true
                                : false
                            }
                          />
                          <a className="p-2" onClick={() => setShowPassword(!showPassword)}>
                            <i className={`${showPassword ? 'mdi mdi-eye' : 'mdi mdi-eye-off'} font-size-18`}></i>
                          </a>
                          {form.touched.password && form.errors.password ? (
                            <FormFeedback type="invalid">
                              {form.errors.password}
                            </FormFeedback>
                          ) : null}
                        </InputGroup>
                      </Col>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md"
                            style={{ marginRight: '34px' }}
                            type="submit"
                          >
                            Göndər
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>

                  <Link to="/login" className="font-weight-medium text-primary">
                    Giriş
                  </Link>{" "}
                  səhifəsinə geri dön
                </p>
                {/* <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ResetPassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ResetPassword);
