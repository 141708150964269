import { REQUEST_BUSINESSES_FINISHED, REQUEST_POST_BUSINESS_EQUIPMENT_FINISHED } from "./actionTypes";



const initialState = {
  list: []
}

const Business = (state = initialState, { type, payload, error, meta }) => {
  if (error) return state;

  switch (type) {
    case REQUEST_BUSINESSES_FINISHED:
      return { ...state, list: payload, postResult: null }
    case REQUEST_POST_BUSINESS_EQUIPMENT_FINISHED:
      return { ...state, postResult: payload }
    default:
      return state
  }
}

export default Business