export const REQUEST_USERS = "REQUEST_USERS"
export const REQUEST_USERS_FINISHED = "REQUEST_USERS_FINISHED"

export const REQUEST_POST_USER = "REQUEST_POST_USER"
export const REQUEST_POST_USER_FINISHED = "REQUEST_POST_USER_FINISHED"

export const REQUEST_DELETE_USER = "REQUEST_DELETE_USER"
export const REQUEST_DELETE_USER_FINISHED = "REQUEST_DELETE_USER_FINISHED"


export const REQUEST_CHANGE_PASSWORD_AS_ADMIN = "REQUEST_CHANGE_PASSWORD_AS_ADMIN"
export const REQUEST_CHANGE_PASSWORD_AS_ADMIN_FINISHED = "REQUEST_CHANGE_PASSWORD_AS_ADMIN_FINISHED"

export const REQUEST_REGIONS = "REQUEST_REGIONS"
export const REQUEST_REGIONS_FINISHED = "REQUEST_REGIONS_FINISHED"
