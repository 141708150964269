import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  CardBody,
  Card,
  Form,
  Input,
  Label,
  Button,
  FormFeedback,
  UncontrolledTooltip,
  InputGroup,
} from "reactstrap"
import "./Filter.scss"

import { useFormik } from "formik"
import { useSelector } from "react-redux"
import Filter from "./Filter"
import filterInitialValues from "./filterInitialValues"
import { useDispatch } from "react-redux"
import { requestBusinessOptions } from "store/actions"
import { selectRegions } from "selectors/UsersSelectors"
import Select from 'react-select';
import { useRef } from "react"
import moment from "moment"


const Monitoring = [
  {
    id: 1,
    title: "Müşahidə səfəri 1",
  },
  {
    id: 2,
    title: "Müşahidə səfəri 2",
  },
  {
    id: 3,
    title: "Müşahidə səfəri 3",
  },
  {
    id: 4,
    title: "Müşahidə səfəri 4",
  },

]

const secondaryFilters = [
  {
    name: "Business",
    key: "business",
  },
  {
    name: "Əmlakın verilmə tarixi",
    key: "equipmentDeliveredDate",
  },
  {
    name: "Veterandır",
    key: "isVeteran",
    bool: true,
  },
  {
    name: "VÖEN varmı",
    key: "hasVoen",
    bool: true,
  },
  {
    name: "Əlildir",
    key: "isDisabled",
    bool: true,
  },
  {
    name: "Şəhid ailəsidir",
    key: "isMemberOfMartyrFamily",
    bool: true,
  },
  {
    name: "Avadanlıq quraşdırılan",
    key: "isEquipmentConfigured",
    bool: true,
  },
  {
    name: "Avadanlıq quraşdırılmayan",
    key: "isEquipmentNotConfigured",
    bool: true,
  },
  {
    name: "Peşə təlimi olan",
    key: "JoinProfessionTrainings",
    bool: true,
  },
  {
    name: "Mentorluq təlimi olan",
    key: "JoinMentor",
    bool: true,
  },
  {
    name: "tarixindən",
    key: "FromDate",
    nameSecond: true,
  },
  {
    name: "tarixinə",
    key: "ToDate",
    nameSecond: true,
  },
  {
    name: "Məcburi köçgün",
    key: "IDP",
    bool: true,
  },
  {
    name: "Sosial yardım alan",
    key: "UDSY",
    bool: true,
  },
  {
    name: "Avadanlığı satan",
    key: "IsEquipmentValid",
    bool: true,
  },
  {
    name: 'Cins',
    key: 'Gender'
  },
]

const ignoredObservation = [
  {
    value: 1,
    label: "Görüşdən imtina etdi",
  },
  {
    value: 2,
    label: "Nömrə yanlışdır",
  },
  {
    value: 3,
    label: "Zənglərə cavab vermir",
  },

]

const BeneficiarFilter = props => {
  const { onFilterSubmit, isGeneral, isForReport } = props;
  const dispatch = useDispatch();

  const [activeSecondaryFields, setActiveSecondaryFields] = useState([]);
  const [isCalendarDropdownActive, setIsCalendarDropdownActive] = useState(false);
  const [newEquipmentDeliveryDate, setnewEquipmentDeliveryDate] = useState(null);
  const selectedFollowers = useSelector(state => state.Beneficiars.followers);
  const selectedBusinesses = useSelector(state => state.Beneficiars.businesses);
  const selectedRegions = useSelector(selectRegions);
  const selectRef = useRef();

  const form = useFormik({
    initialValues: {
      fullName: "",
      fin: "",
      pageNumber: "",
      Regions: "",
      followerId: "",
      observationId: "",
      nonObservationId: "",
      isDisabled: false,
      isMemberOfMartyrFamily: false,
      isVeteran: false,
      isEquipmentConfigured: false,
      isEquipmentNotConfigured: false,
      IsEquipmentValid: false,
      equipmentDeliveredFromDate: "",
      equipmentDeliveredToDate: "",
      hasVoen: false,
      FromDate: "",
      ToDate: "",
      BusinessId: "",
      JoinMentor: false,
      IDP: "",
      UDSY: "",
      Gender: "",
      IgnoredId: []
    },
    onSubmit: values => {
      onFilterSubmit(values);
    },
  });
  


  const RegionDropdownItems = () => {
    return (
      <>
        <option value="">Bütün rayonlar</option>
        {selectedRegions.map(region => {
          return (
            <option key={region.id} value={region.id}>
              {region.name}
            </option>
          )
        })}
      </>
    )
  }

  const BusinessesDropdownItems = () => {
    return (
      <>
        <option value="">Bütün zərflər</option>
        {selectedBusinesses && selectedBusinesses.map(business => {
          return (
            <option key={business.id} value={business.id}>
              {business.name}
            </option>
          )
        })}
      </>
    )
  }

  const EmplooyeDropdownItems = () => {
    return (
      <>
        <option value="">Bütün müşahidəçilər</option>
        {selectedFollowers && selectedFollowers.map(follower => {
          return (
            <option key={follower.followerId} value={follower.followerId} onClick={form.handleChange}>
              {follower.fullName}
            </option>
          )
        })}
      </>
    )
  }

  const MonitoringDropdownItems = () => {
    return (
      <>
        <option value="">Bütün müşahidə səfərləri</option>

        {Monitoring.map(Monitoring => {
          return (
            <option key={Monitoring.id} value={Monitoring.id} onClick={form.handleChange}>
              {Monitoring.title}
            </option>
          )
        })}
      </>
    )
  };

  const handleFilterChange = (name, value) => event => {
    if (value !== undefined) {
      form.setFieldValue(name, value)
    } else {
      form.handleChange(event)
    }

    if (!activeSecondaryFields.includes(name)) {
      setActiveSecondaryFields([...activeSecondaryFields, name])
    }
    else {
      if (value != undefined && name !== "Gender") {
        setActiveSecondaryFields(
          activeSecondaryFields.filter(field => field !== name)
        )
      } else if (!value && name === "Gender") {
        setActiveSecondaryFields(
          activeSecondaryFields.filter(field => field !== name)
        )
      }
    }
  };

  const visibleSecondaryFilters = activeSecondaryFields.map(field =>
    secondaryFilters.find(filter => filter.key === field)
  );

  const removeFilter = (key) => {
    setActiveSecondaryFields(prev => prev.filter(filter => filter !== key));
    form.setFieldValue(key, filterInitialValues[key])

  };

  const handleFilterRefresh = () => {
    selectRef.current.setValue(null)
    form.resetForm();
    setActiveSecondaryFields([]);
    onFilterSubmit([]);
  };

  useEffect(() => {
    if (!Object.keys(selectedBusinesses).length > 0) dispatch(requestBusinessOptions())
  }, [dispatch, selectedBusinesses]);


  const handleEquipmentDeliveryDate = (months) => {
    setIsCalendarDropdownActive(false);
    const dateTo = new Date();
    const newToDate = dateTo.setMonth(dateTo.getMonth() - months);
    form.setFieldValue("equipmentDeliveredToDate", moment(newToDate).format("YYYY-MM-DD"));

    if (months === 6) {
      const newFromDate = dateTo.setMonth(dateTo.getMonth() - months, dateTo.getDate() - 1);
      form.setFieldValue("equipmentDeliveredFromDate", moment(newFromDate).format("YYYY-MM-DD"));
    } else if (months === 12) {
      form.setFieldValue("equipmentDeliveredFromDate", null);
    }
  }

  return (
    <React.Fragment>
      <Card className="">
        <CardBody className="">
          <Row>
            <Col>
              <div className="steps d-flex flex-wrap flex-sm-nowrap justify-content-between padding-top-2x padding-bottom-1x">
                <Form
                  className="filter form-horizontal"
                  onSubmit={e => {
                    e.preventDefault()
                    form.handleSubmit()
                    return false
                  }}
                >

                  <Row className="primary-menu ">
                    <Row className=" m-0 p-0 ">
                      <div className="d-flex justify-content-end m-0" style={{ cursor: 'pointer' }} >
                        <div className=" m-0 p-0" onClick={handleFilterRefresh} >
                          <i className="mdi mdi-refresh font-size-24 text-primary" id="refreshFilter"></i>
                        </div>
                      </div>
                      <UncontrolledTooltip target="refreshFilter">
                        Filtri təmizlə
                      </UncontrolledTooltip>
                    </Row>
                    {/* Ad Soyad */}
                    <Col className={`col-6 col-xl-2`}>
                      <Label className="form-label w-100 text-center">Ad Soyad</Label>
                      <Input
                        name="fullName"
                        className="form-control"
                        placeholder="Ad və soyad"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.fullName || ""}
                        invalid={
                          form.touched.fullName && form.errors.fullName
                            ? true
                            : false
                        }
                      />
                    </Col>

                    {/* FIN */}
                    <Col className={`col-6 col-xl-2`}>
                      <Label className="form-label w-100 text-center">FIN Kod</Label>
                      <Input
                        name="fin"
                        className="form-control"
                        placeholder="FIN kod"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.fin || ""}
                        invalid={
                          form.touched.fin && form.errors.fin
                            ? true
                            : false
                        }
                      />
                    </Col>

                    {/* Rayonlar */}
                    <Col className={`col-6 col-xl-2`}>
                      <Label className="form-label w-100 text-center">Rayon</Label>
                      <Input
                        name="Regions"
                        type="select"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.Regions || ""}
                        invalid={
                          form.touched.Regions &&
                            form.errors.Regions
                            ? true
                            : false
                        }

                      >
                        {RegionDropdownItems()}
                      </Input>
                    </Col>

                    {/* Bizneslər */}
                    <Col className={`col-6 col-xl-2`}>
                      <Label className="form-label w-100 text-center">Zərf</Label>
                      <Input
                        name="BusinessId"
                        type="select"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.BusinessId || ""}
                        invalid={
                          form.touched.BusinessId &&
                            form.errors.BusinessId
                            ? true
                            : false
                        }
                      >
                        {BusinessesDropdownItems()}
                      </Input>
                    </Col>

                    {/* Aktiv verilən Tarixdən */}
                    <Col className={`col-12 col-xl-4`}>
                      <Label className="form-label w-50 text-end">Aktivin verilmə tarixi</Label>
                      <InputGroup>
                        {isForReport && <div className="calendarDrWr">
                          <div className="calendarDrBtn" onClick={() => setIsCalendarDropdownActive(!isCalendarDropdownActive)}>
                            <i className="mdi mdi-dots-vertical font-size-18"></i>
                          </div>
                          <div className={`calendarDrMenu ${isCalendarDropdownActive ? "active" : ""}`}>
                            <p onClick={() => handleEquipmentDeliveryDate(6)}>6 ay</p>
                            <p onClick={() => handleEquipmentDeliveryDate(12)}>1 il</p>
                          </div>
                        </div>}
                        <Input
                          name="equipmentDeliveredFromDate"
                          type="date"
                          className="form-control"
                          placeholder=""
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.equipmentDeliveredFromDate || ""}
                          invalid={
                            form.touched.equipmentDeliveredFromDate &&
                              form.errors.equipmentDeliveredFromDate
                              ? true
                              : false
                          }
                        /> <p className="m-2">-dən</p>

                        <Input
                          name="equipmentDeliveredToDate"
                          type="date"
                          className="form-control"
                          placeholder=""
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.equipmentDeliveredToDate || ""}
                          invalid={
                            form.touched.equipmentDeliveredToDate &&
                              form.errors.equipmentDeliveredToDate
                              ? true
                              : false
                          }
                        /> <p className="m-2">-dək</p>
                      </InputGroup>
                    </Col>

                    {/* Müşahidəçilər */}
                    {isGeneral && !isForReport && <Col className="col-6 col-xl-2">
                      <Label className="form-label w-100 text-center">Müşahidəçilər</Label>
                      <Input
                        name="followerId"
                        type="select"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.followerId || ""}
                        invalid={
                          form.touched.followerId &&
                            form.errors.followerId
                            ? true
                            : false
                        }
                      >
                        {EmplooyeDropdownItems()}
                      </Input>
                    </Col>}

                    {/* IgnoredId */}
                    {isGeneral && <Col className="col-6 col-xl-2">
                      <Label className="form-label w-100 text-center">Səfər baş tutmadı</Label>
                      <Select
                        ref={selectRef}
                        isMulti
                        type="select"
                        name="IgnoredId"
                        isClearable={true}
                        isSearchable={false}
                        placeholder="Seçim edilməyib..."
                        onChange={(option) => {
                          let newList = [];
                          if (option) {
                            option.map((option) => {
                              newList.push(option?.value)
                            })
                            form.setFieldValue("IgnoredId", newList);
                            form.setFieldValue("nonObservationId", "")
                          } else {
                            form.setFieldValue("IgnoredId", null);
                          }

                        }}

                        onBlur={form.handleBlur}
                        value={form.values.IgnoredId ? ignoredObservation.find(option => option.value === form.values.IgnoredId) : null}
                        options={ignoredObservation}
                        className="basic-multi-select bg-light"
                        classNamePrefix="select"
                        invalid={
                          form.touched.IgnoredId &&
                            form.errors.IgnoredId
                            ? true
                            : false
                        }
                      />
                    </Col>}

                    {/* Bitməmiş səfər */}
                    {isGeneral && !isForReport && <Col className={`col-6 ${isForReport ? "col-xl-3" : "col-xl-2"}`}>
                      <Label className="form-label w-100 text-center">
                        Bitməmiş səfər
                      </Label>
                      <Input
                        name="nonObservationId"
                        type="select"
                        disabled={form.values.IgnoredId.length > 0 ? true : false}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.nonObservationId || ""}
                        invalid={
                          form.touched.nonObservationId &&
                            form.errors.nonObservationId
                            ? true
                            : false
                        }
                      >
                        {MonitoringDropdownItems()}
                      </Input>
                    </Col>}

                    {/* Bitmiş səfər */}
                    {<Col className={`col-6 col-xl-2`}>
                      <Label className="form-label w-100 text-center">
                        Müşahidə səfəri
                      </Label>
                      <Input
                        name="observationId"
                        type="select"
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                        value={form.values.observationId || ""}
                        invalid={
                          form.touched.observationId &&
                            form.errors.observationId
                            ? true
                            : false
                        }
                      >
                        {MonitoringDropdownItems()}
                      </Input>
                    </Col>}

                    {/* Tarixdən */}
                    <Col className="col-12 col-xl-4">
                      <Label className="form-label w-50 text-end">Səfərin tarixi</Label>

                      <InputGroup>
                        <Input
                          name="FromDate"
                          type="date"
                          className="form-control"
                          placeholder=""
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.FromDate || ""}
                          invalid={
                            form.touched.FromDate && form.errors.FromDate
                              ? true
                              : false
                          }
                        /><p className="m-2">-dən</p>
                        <Input
                          name="ToDate"
                          type="date"
                          className="form-control"
                          placeholder=""
                          onChange={form.handleChange}
                          onBlur={form.handleBlur}
                          value={form.values.ToDate || ""}
                          invalid={
                            form.touched.ToDate && form.errors.ToDate ? true : false
                          }
                        /><p className="m-2">-dək</p>
                      </InputGroup>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col>
                      <Filter
                        onChange={handleFilterChange}
                        removeFilter={removeFilter}
                        filters={visibleSecondaryFilters}
                        form={form}
                        isGeneral={isGeneral}
                      />
                    </Col>
                  </Row>
                  {/* {isFilterExpanded && <div className="seperator"></div>} */}

                  <div className="d-flex filter-icon-wrapper justify-content-end">
                    <Button type="submit" className="bg-primary">
                      Axtar
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
export default BeneficiarFilter
