import useModal from "hooks/useModal"
import React from "react"
import { Card, CardBody, UncontrolledTooltip } from "reactstrap"
import ViewEquipments from "./ViewEquipments"

export default function BusinessCard({ data = {} }) {
  const { openModal, renderModals } = useModal()
  const handleViewEquipments = () => {
    openModal({
      header: `Avadanlıqlar - ${data.businessName}`,
      content: (
        <ViewEquipments
          businessId={data.businessId}
          equipments={data.equipments}
        />
      ),
    })
  }

  console.log()

  return (
    <>
      <Card className="hoverable" onClick={handleViewEquipments} style={{ cursor: "pointer" }}>
        <CardBody className="p-3">
          <div className="d-flex flex-column justify-content-center">
            <h5 className="my-2 text-truncate font-size-15" id={`businnesName${data?.businessId}`}>{data.businessName}</h5>
            {data?.businessName?.length > 25 && <UncontrolledTooltip target={`businnesName${data?.businessId}`} placement="top">
              {data.businessName}
            </UncontrolledTooltip>}
            <p className="text-muted text-truncate mb-2 ">{`Avadanlıqlar (${data?.equipments?.length})`}</p>
          </div>
        </CardBody>
      </Card>

      {renderModals()}
    </>

  )
}
