export const REQUEST_ATTACHED_BENEFICIARIES = "REQUEST_ATTACHED_BENEFICIARIES"
export const REQUEST_ATTACHED_BENEFICIARIES_FINISHED = "REQUEST_ATTACHED_BENEFICIARIES_FINISHED"

export const REQUEST_EXPORT_ATTACHED_BENEFICIARIES = "REQUEST_EXPORT_ATTACHED_BENEFICIARIES"
export const REQUEST_EXPORT_ATTACHED_BENEFICIARIES_FINISHED = "REQUEST_EXPORT_ATTACHED_BENEFICIARIES_FINISHED"

export const REQUEST_QUESTIONS_BY_FILTER = "REQUEST_QUESTIONS_BY_FILTER"
export const REQUEST_QUESTIONS_BY_FILTER_FINISHED = "REQUEST_QUESTIONS_BY_FILTER_FINISHED"

export const REQUEST_POST_ANSWER_QUESTION = "REQUEST_POST_ANSWER_QUESTION"
export const REQUEST_POST_ANSWER_QUESTION_FINISHED = "REQUEST_POST_ANSWER_QUESTION_FINISHED"

export const REQUEST_DETAILED_FOLLOWERS = "REQUEST_DETAILED_FOLLOWERS"
export const REQUEST_DETAILED_FOLLOWERS_FINISHED = "REQUEST_DETAILED_FOLLOWERS_FINISHED"

export const REQUEST_POST_FOLLOWER_CONTRACT = "REQUEST_POST_FOLLOWER_CONTRACT"
export const REQUEST_POST_FOLLOWER_CONTRACT_FINISHED = "REQUEST_POST_FOLLOWER_CONTRACT_FINISHED"

export const REQUEST_POST_COMPLETE_OBSERVATION = "REQUEST_POST_COMPLETE_OBSERVATION"
export const REQUEST_POST_COMPLETE_OBSERVATION_FINISHED = "REQUEST_POST_COMPLETE_OBSERVATION_FINISHED"

export const REQUEST_POST_EQUIPMENT = "REQUEST_POST_EQUIPMENT"
export const REQUEST_POST_EQUIPMENT_FINISH = "REQUEST_POST_EQUIPMENT_FINISH"

export const REQUEST_EXPORT_FOLLOWER_EXCEL = "REQUEST_EXPORT_FOLLOWER_EXCEL"
export const REQUEST_EXPORT_FOLLOWER_EXCEL_FINISHED = "REQUEST_EXPORT_FOLLOWER_EXCEL_FINISHED"