import EnhancedTable from "components/Common/EnhancedTable"
import React, { useMemo } from "react"
import { useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  UncontrolledTooltip,
} from "reactstrap"
import { beneficiarColumns } from "./columns"
import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"

import {
  requestBeneficiars,
  requestDeleteBeneficiar,
  requestPostBeneficiar,
  saveBeneficiarsFilter,
} from "store/beneficiars/actions"
import { selectBeneficiarisPostResult, selectBeneficiaryDeleteResult, selectLoadingBeneficiaris } from "selectors/BeneficiarisSelectors"
import DeleteModal from "components/Common/DeleteModal"
import BeneficiarForm from "../BeneficiarForm"
import { selectRegions } from "selectors/UsersSelectors"

export default function BeneficiarTable({
  beneficiarActionHandler,
  followers,
  isExportLoading
}) {
  const dispatch = useDispatch()
  const [selectedData, setSelectedData] = useState(null);
  const [deleteData, setDeleteData] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false)
  const [formMode, setFormMode] = useState("");
  const [modal, setModal] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10, })

  const columns = beneficiarColumns

  const data = useSelector(state => state.Beneficiars.list)
  const filter = useSelector(state => state.Beneficiars.filters)
  const selectedBusinesses = useSelector(state => state.Beneficiars.businesses);
  const postBeneficiarResult = useSelector(selectBeneficiarisPostResult);
  const beneficiarDeleteResult = useSelector(selectBeneficiaryDeleteResult);
  const selectedRegions = useSelector(selectRegions);
  const isLoading = useSelector(selectLoadingBeneficiaris);

  const ActionHandler = ({ beneficiar = {}, mode = "" }) => {
    if (mode === "add" || mode === "edit") {
      setSelectedData(beneficiar);
      setFormMode(mode);
      setModal(true);
    }
    else if (mode === "delete") {
      setDeleteData(beneficiar);
      setDeleteModal(true);
    }
  }

  const handleDeleteBeneficiar = () => {
    const payload = {
      deleteData: deleteData,
      filter: {
        filter,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      }
    }
    dispatch(requestDeleteBeneficiar(payload));
  }

  const handleAddOrUpdate = (beneficiar) => {
    const payload = {
      beneficiar: beneficiar,
      filter: {
        filter,
        pageIndex: pagination.pageIndex + 1,
        pageSize: pagination.pageSize,
      }
    }
    dispatch(requestPostBeneficiar(payload))
  }

  useEffect(() => {
    if (postBeneficiarResult && postBeneficiarResult?.success) setModal(false);
  }, [postBeneficiarResult]);

  useEffect(() => {
    if (beneficiarDeleteResult && beneficiarDeleteResult?.success) setDeleteModal(false);
  }, [beneficiarDeleteResult]);

  useEffect(() => {
    if (!isFirstLoad) {
      dispatch(
        requestBeneficiars({
          filter,
          pageIndex: pagination.pageIndex + 1,
          pageSize: pagination.pageSize,
        })
      )
    } else {
      setIsFirstLoad(false), () => { dispatch(saveBeneficiarsFilter({})) }
    }
  }, [filter, pagination, isFirstLoad])

  const tableColumns = useMemo(
    () => [
      {
        id: "gallery",
        header: "Şəkillər",
        accessorKey: "images",
        cell: ({ row }) => {
          if (row.original.isPhotoValid) {
            return (
              <i
                onClick={() =>
                  beneficiarActionHandler({
                    beneficiar: row.original,
                    mode: "viewGallery",
                  })
                }
                className="mdi mdi-folder-image text-primary"
                style={{ fontSize: "24px", cursor: "pointer", padding: "15px" }}
              ></i>
            )
          } else {
            return (
              <i
                className="mdi mdi-folder text-secondary"
                style={{ fontSize: "24px", padding: "15px" }}
              ></i>
            )
          }
        },
      },
      ...columns,
      {
        header: "Action",
        accessorKey: "action",
        cell: ({ row }) => {
          return (
            <div className="d-flex justify-content-center">
              <div
                className="text-success"
                onClick={() =>
                  ActionHandler({
                    beneficiar: row.original,
                    mode: "edit",
                  })
                }
              >
                <i
                  id="edittooltip"
                  className="mdi mdi-pencil font-size-18"
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    padding: "15px",
                  }}
                ></i>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </div>
              <div
                className="text-danger"
                onClick={() =>
                  ActionHandler({
                    beneficiar: row.original.beneficiarId,
                    mode: "delete",
                  })
                }
              >
                <i
                  id="deletetooltip"
                  className="mdi mdi-delete font-size-18"
                  style={{
                    fontSize: "24px",
                    cursor: "pointer",
                    padding: "15px",
                  }}
                ></i>
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  delete
                </UncontrolledTooltip>
              </div>
            </div>
          )
        },
      },
    ], [])

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row className="justify-content-between my-2">
            <Col className="col-7 d-flex">
              <div className="me-3">
                <a
                  className={`btn me-3 ${isExportLoading ? "btn-secondary" : "btn-success"}`}
                  onClick={() =>
                    beneficiarActionHandler({ beneficiar: {}, mode: "export" })
                  }
                >{isExportLoading ? (
                  <span
                    className="spinner-border spinner-border-sm me-3  p-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                ) : <i className="mdi mdi-download me-2 font-size-18"></i>}
                  {" "}
                  Export
                </a>
              </div>

              <div>
                <a
                  className="btn btn-success"
                  onClick={() =>
                    beneficiarActionHandler({ beneficiar: {}, mode: "import" })
                  }
                >
                  <i className="mdi mdi-upload me-2 font-size-18"></i>
                  Import
                </a>
              </div>
            </Col>

            <Col className="col-5 text-end">
              <a
                className="btn btn-primary"
                onClick={() =>
                  ActionHandler({ beneficiar: {}, mode: "add" })
                }
              >
                Yeni Benefsiar
              </a>
            </Col>
          </Row>
          <EnhancedTable
            onPaginationChange={setPagination}
            pagination={pagination}
            manualPagination
            data={data.beneficiaries}
            columns={tableColumns}
            isLoading={isLoading}
            DataCount={data.count}
            followers={followers}
          />

          <Modal
            size="lg"
            isOpen={modal}
            toggle={() => setModal(prevOpen => !prevOpen)}
          >
            <ModalHeader toggle={() => setModal(prevOpen => !prevOpen)} tag="h4">
              {formMode === "edit" ? "Benefisiarın redakə edilməsi" : "Yeni Benefisiar"}
            </ModalHeader>
            <ModalBody>
              <BeneficiarForm handleAddOrUpdate={handleAddOrUpdate} beneficiar={selectedData} businesses={selectedBusinesses} regions={selectedRegions} mode={formMode} />
            </ModalBody>
          </Modal>

          <DeleteModal
            show={deleteModal}
            text={{
              title: `Silmək istədiyinizə əminsiniz ?`,
            }}
            onDeleteClick={handleDeleteBeneficiar}
            onCloseClick={() => { setDeleteModal(false); setDeleteData(null) }}
          />


        </CardBody>
      </Card>
    </React.Fragment>
  )
}
