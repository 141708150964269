import { useFormik } from "formik"
import React from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { Form, Input, Row, Col, Label, FormFeedback } from "reactstrap"
import { REQUEST_POST_BUSINESS_EQUIPMENT } from "store/businesses/actionTypes"
import { requestPostBusinessEquipment } from "store/businesses/actions"
import { object, string } from "yup"

export default function CreateEquipment({
  businessId,
  equipment = {equipmentId: 0},
  closeModal,
}) {
  const dispatch = useDispatch()
  const isPostLoading = useSelector(state => state.Requesting[REQUEST_POST_BUSINESS_EQUIPMENT])
  const form = useFormik({
    initialValues: {
      name: equipment.equipmentName ?? "",
    },
    validationSchema: object().shape({
      name: string().required("Avadanlıq adı daxil edilməlidir"),
    }),
    onSubmit: values => {
      dispatch(
        requestPostBusinessEquipment(
          { businessId, equipmentId: equipment.equipmentId, ...values, active: 1 },
          { onSuccess: closeModal }
        )
      )
    },
  })

  return (
    <Form
      autoComplete="off"
      onSubmit={e => {
        e.preventDefault()
        form.handleSubmit()
        return false
      }}
    >
      <Input hidden autoComplete="false" />
      <Row>
        <Col className="col-lg-12 col-md-5">
          <div className="mb-3">
            <Label className="form-label"></Label>
            <Input
              name="name"
              type="text"
              placeholder="Avadanlıq adını daxil edin"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.name || ""}
              invalid={form.touched.name && form.errors.name ? true : false}
            />
            {form.touched.name && form.errors.name ? (
              <FormFeedback type="invalid">{form.errors.name}</FormFeedback>
            ) : null}
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-end">
            {/* disabled={isLoadingPostUser} */}
            <button
              disabled={!form.values.name || !form.dirty || isPostLoading}
              type="submit"
              className="btn btn-success save-user"
            >
              {isPostLoading && (
                <span
                  className="spinner-border spinner-border-sm me-3"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}{" "}
              Təsdiqlə
            </button>
          </div>
        </Col>
      </Row>
    </Form>
  )
}
