import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { REQUEST_CHANGE_PASSWORD } from "./actionTypes";
import { requestChangePasswordrFinished } from "./actions";
import { changePassword } from "services";
import { Storage } from "helpers/storage-helper"
import { toast } from "react-toastify";

async function StorageCleaner() {
  Storage.removeAll(["authToken", "refreshToken", "isFirstLogin", "authUser", "Auth"]);
  return true;
}
async function storageHandler(history) {
  await StorageCleaner().then(() => {
    history('/login', { replace: true });
  });
}

function* changeUserPassword({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(changePassword, user);
      const { data } = response;
      if (data.success) {
        yield put(requestChangePasswordrFinished(data, false, { toast: { type: "success" }, refresh: true }))
        storageHandler(history)
      }
      else {
        yield put(requestChangePasswordrFinished(data.message, true))
      }
    }
  } catch (error) {
    console.error(error)
    yield put(requestChangePasswordrFinished(error?.message, true))
  }
}


function* changePasswordSaga() {
  yield takeEvery(REQUEST_CHANGE_PASSWORD, changeUserPassword)
}

export default changePasswordSaga;
