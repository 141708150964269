export function formatQuestionAndAnswers(questionAnswer) {
  return {
    id: questionAnswer.id,
    content: questionAnswer.content,
    additionalNote: questionAnswer.additionalNote,
    observationId: questionAnswer.observationId,
    answerTypeId: questionAnswer.answerTypeOutputModel.answerTypeId,
    name: questionAnswer.answerTypeOutputModel.name,
    subItems: questionAnswer.answerTypeOutputModel.subItems.map((subItem => {
      return {
        id: subItem.id,
        additionalNote: subItem.additionalNote,
        observationId: subItem.observationId,
        content: subItem.content,
        answers: subItem.answerTypeOutputModel.answers,
        answerTypeId: subItem.answerTypeOutputModel.answerTypeId,
        name: subItem.answerTypeOutputModel.name
      };
    })),
    answers: questionAnswer.answerTypeOutputModel.answers,
  }
}

export function fotmatObservationQuestions(observation) {
  return {
    canAnswer: observation.canAnswer,
    count: observation.count,
    questions: observation.questions.map((question) => {
      return {
        id: question.id,
        content: question.content,
        additionalNote: question.additionalNote,
        answerText: question.answerText,
        answerType: question.answerType,
        isEditable: question.isEditable,
        answers: question.answers,
        subItems: question.childQuestion
      }
    })
  }
}