import classNames from "classnames"
import React from "react"
import { useSelector } from "react-redux"
import { Button } from "reactstrap"

export default function ConfirmationFooter({
  confirmText,
  cancelText,
  onConfirm,
  closeModal,
  type,
  actions = [],
}) {
  const isLoading = useSelector(state => {
    return actions.some(action => state.Requesting[action])
  })

  console.log("actions")

  // const isLoading = false;

  const confirmClassname = classNames("btn flex-grow-1 outline", {
    ["btn-danger"]: type === "delete",
    ["btn-primary"]: type != "delete",
  })

  return (
    <div className="hstack gap-2 justify-content-center mb-0 flex-grow-1">
      <Button
        outline
        type="button"
        className=" flex-grow-1"
        onClick={closeModal}
      >
        {cancelText || "Cancel"}
      </Button>
      <button
        disabled={isLoading}
        type="button"
        className={confirmClassname}
        onClick={onConfirm}
      >
        {isLoading && (
          <span
            className="spinner-border spinner-border-sm me-3"
            role="status"
            aria-hidden="true"
          ></span>
        )}{" "}
        {confirmText || "Confirm"}
      </button>
    </div>
  )
}
