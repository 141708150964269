import { createSelector } from "reselect"
import {
  REQUEST_BENEFICIARS,
  REQUEST_POST_BENEFICIAR,
  REQUEST_POST_ATTACH_FOLLOWERS,
  REQUEST_PUT_CHANGE_OBSERVATION_DATE,
  REQUEST_DELETE_OBSERVATION
} from "store/beneficiars/actionTypes";

export const selectBeneficiaris = state => state.Beneficiars.list;

export const selectBeneficiarisState = state => state.Beneficiars;
export const selectFollowers = state => state.Beneficiars.followers;
export const selectBeneficiarFilter = state => state.Beneficiars.filters;
export const selectCompanyTraining = state => state.Beneficiars.filters.companyTraining;

export const selectBeneficiarisPostResult = createSelector(selectBeneficiarisState, (Beneficiars) => Beneficiars.postResult);
export const selectBeneficiarExportResult = createSelector(selectBeneficiarisState, (Beneficiars) => Beneficiars.exportResult)
export const selectExportMissingEquipmentResult = createSelector(selectBeneficiarisState, (Beneficiars) => Beneficiars.exportMissingEquipmentResult);
export const selectBeneficiaryDeleteResult = createSelector(selectBeneficiarisState, (Beneficiars) => Beneficiars.deleteResult);
export const selectBeneficiarNewDateResult = createSelector(selectBeneficiarisState, (Beneficiars) => Beneficiars.putObservationNewDateResult)
export const selectObservationDeleteResult = createSelector(selectBeneficiarisState, (Beneficiars) => Beneficiars.deleteObservationResult);
export const selectBeneficiarisImportResult = createSelector(selectBeneficiarisState, (Beneficiars) => Beneficiars.postImportResult);
export const selectFollowersPostResult = createSelector(selectBeneficiarisState, (Beneficiars) => Beneficiars.postResult);



export const selectLoadingPostAttachFollower = state => { return state.Requesting[REQUEST_POST_ATTACH_FOLLOWERS] };
export const selectLoadingPostObservationDate = state => { return state.Requesting[REQUEST_PUT_CHANGE_OBSERVATION_DATE] };
export const selectLoadingDeleteObservation = state => { return state.Requesting[REQUEST_DELETE_OBSERVATION] };
export const selectLoadingBeneficiaris = state => { return state.Requesting[REQUEST_BENEFICIARS] };
export const selectLoadingPostBeneficiar = state => state.Requesting[REQUEST_POST_BENEFICIAR];


const selectPageState = createSelector(
  selectBeneficiaris,
  selectLoadingBeneficiaris,
  (Beneficiars, isLoadingBeneficiaris) => ({
    Beneficiars,
    isLoadingBeneficiaris,
  })
)

export default selectPageState;