import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import {
  REQUEST_QUESTIONS,
  REQUEST_POST_QUESTION_FINISHED,
  REQUEST_DELETE_QUESTION,
  REQUEST_DELETE_QUESTION_FINISHED,
  REQUEST_ANSWERTYPES,
  REQUEST_POST_QUESTION

} from "./actionTypes"
import {
  requestQuestionsFinished,
  requestAnswerTypesFinished,
  requestPostQuestionsFinished,
  requestDeleteQuestionFinished
} from "./actions"
import {
  getQuestions,
  getAnswerTypes,
  postQuestion,
  deleteQuestion
} from "services"
import { formatQuestionAndAnswers } from "helpers/format-helper"

// const fireBaseBackend = getFirebaseBackend();

function* fetchQuestions({ error, payload }) {
  if (error) return
  try {
    const response = yield call(getQuestions, payload)

    if (response.status) {
      const { data } = response
      const questionData = data.data
      const formattedQuestions = questionData.questions.map(formatQuestionAndAnswers);
      yield put(requestQuestionsFinished({list: formattedQuestions, count: questionData.count}))
      return
    }
    yield put(requestQuestionsFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestQuestionsFinished(error, true))
  }
}

function* postSingleQuestion({ payload }) {
  try {
    const response = yield call(postQuestion, payload)
    if (response.data.success) {
      yield put(
        requestPostQuestionsFinished(response.data, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }

    yield put(requestPostQuestionsFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestPostQuestionsFinished(error, true))
  }
}

function* deleteSingleQuestion({  payload }) {
  try {
    const response = yield call(deleteQuestion, payload);
    if (response.data.success) {
      yield put(
        requestDeleteQuestionFinished(response.data, false, { toast: { type: "success" } })
      )
      return
    }

    yield put(requestDeleteQuestionFinished(response.data, true))
  } catch (error) {
    console.error(error);
    yield put(requestDeleteQuestionFinished(error, true))
  }
}

function* fetchAnswerTypes({ error }) {
  if (error) return
  try {
    const response = yield call(getAnswerTypes)

    if (response.status) {
      const { data } = response
      yield put(requestAnswerTypesFinished(data))
      return
    }

    yield put(requestAnswerTypesFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestAnswerTypesFinished(error, true))
  }
}



function* QuestionsSaga() {
  yield takeEvery([REQUEST_QUESTIONS, REQUEST_POST_QUESTION_FINISHED,REQUEST_DELETE_QUESTION_FINISHED], fetchQuestions);
  yield takeEvery([REQUEST_ANSWERTYPES], fetchAnswerTypes);
  yield takeEvery([REQUEST_POST_QUESTION], postSingleQuestion);
  yield takeEvery([REQUEST_DELETE_QUESTION],deleteSingleQuestion);
}

export default QuestionsSaga
