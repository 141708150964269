import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import BusinessCard from "./BusinessCard"
import { useDispatch } from "react-redux"
import { requestBusinesses } from "store/businesses/actions"
import { useSelector } from "react-redux"
import { selectBusinesses } from "selectors/BusinessSelector"
import { requestExportMissingEquipment } from "store/actions"
import { selectExportMissingEquipmentResult } from "selectors/BeneficiarisSelectors"

export default function Equipments() {
  document.title = "Avadanlıqlar | Self Employment Project"

  const dispatch = useDispatch()
  const bussineses = useSelector(selectBusinesses);
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [viewMode, setViewMode] = useState("module");

  const exportResult = useSelector(selectExportMissingEquipmentResult);

  useEffect(() => {
    if (!Object.keys(bussineses.list).length > 0) dispatch(requestBusinesses())
  }, [])

  const handleExportMissingEquipments = () => {
    setIsExportLoading(true);
    dispatch(requestExportMissingEquipment());
  }

  useEffect(() => {
    if (exportResult && exportResult.success) {
      setTimeout(() => {
        setIsExportLoading(false)
      }, 300);
    }
  }, [exportResult])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Idarə paneli"} breadcrumbItem={"Zərf və Avadanlıqlar"} />
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between">
                <div>
                  <a
                    className={`btn me-3 ${isExportLoading ? "btn-secondary" : "btn-success"}`}
                    onClick={handleExportMissingEquipments}
                  >
                    {isExportLoading ? (
                      <span
                        className="spinner-border spinner-border-sm p-2 me-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : <i className="mdi mdi-download me-2 font-size-18"></i>}
                    {" "}
                    Avadanlığı çatışmayan benefisiarlar
                  </a>
                </div>

                <div className="d-none d-xl-flex mt-1 ">
                  <div
                    className="d-flex justify-content-between"
                    role="button"
                    onClick={() => setViewMode("module")}
                  >
                    <i className={`mdi mdi-view-module font-size-24 me-2 ${viewMode === "module" ? "text-success" : "text-secondary"}`}></i></div>
                  <div
                    className="d-flex justify-content-between"
                    role="button"
                    onClick={() => setViewMode("stream")}
                  >
                    <i className={`mdi mdi-view-stream font-size-24 ${viewMode === "stream" ? "text-success" : "text-secondary"}`}></i></div>
                </div>
              </div>
            </CardBody>
          </Card>

          <Row>
            {bussineses &&
              Array.isArray(bussineses.list) &&
              bussineses.list.map(business => {
                return (
                  <Col key={business.businessId} xs={12} sm={4} xl={viewMode === "module" ? 2 : 3}>
                    <BusinessCard data={business} />
                  </Col>
                )
              })}
          </Row>
        </Container>
      </div>
    </React.Fragment >
  )
}
