import React, { useEffect, useState } from "react"
import { Button, Card, CardBody, Col, Collapse, Container, Label, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

//redux
import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { requestFollowers, saveBeneficiarsFilter } from "store/beneficiars/actions"
import { requestBeneficiarImages } from "store/beneficiar-images/actions"
import BeneficiarGalleryModal from "../Dashboard/BeneficiarGallery"
import Monitoring from "../Dashboard/Monitoring"
import BeneficiarFilter from "../Dashboard/BeneficiarFilter"
import BeneficiarTable from "./BeneficiarTable/BeneficiarTable"
import { requestRegions } from "store/actions"
import { selectRegions } from "selectors/UsersSelectors"
import { selectCompanyTraining } from "selectors/BeneficiarisSelectors"

ChartJS.register(ArcElement, Tooltip);

const Reports = props => {
  const dispatch = useDispatch();
  //meta title
  document.title = "Reportlar | Self Employment Project"
  const selectedBeneficiarImages = useSelector(state => state.BeneficiarImages.list);
  const followers = useSelector(state => state.Beneficiars.followers);
  const [GalleryMode, setGalleryMode] = useState("");
  const [selectedBeneficiar, setSelectedBeneficiar] = useState(null);
  const companyTraining = useSelector(selectCompanyTraining);
  const selectedRegions = useSelector(selectRegions)

  const handleViewGallery = () => {
    setGalleryMode(prevMode => prevMode === "view" ? "" : "view");
    setSelectedBeneficiar(null)
  }

  const onFilterSubmit = (filter) => {
    dispatch(saveBeneficiarsFilter({ ...filter, companyTraining }));
  }

  useEffect(() => {
    if (companyTraining !== null) dispatch(saveBeneficiarsFilter({ companyTraining }));
  }, [companyTraining]);

  useEffect(() => {
    if (!Object.keys(followers).length > 0) dispatch(requestFollowers())
    if (!Object.keys(selectedRegions).length > 0) dispatch(requestRegions())
  }, [dispatch])

  useEffect(() => {
    if (selectedBeneficiar) {
      dispatch(requestBeneficiarImages(selectedBeneficiar))
      setGalleryMode("view")
    }
  }, [selectedBeneficiar, dispatch])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <Monitoring />
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <CardBody>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                    <b className="m-1 w-100">Axtarış bazasını seç: </b>
                    <div className="m-1">
                      <Button
                        name="All"
                        className="btn"
                        color="primary"
                        outline={companyTraining !== "ALL" ? true : false}
                        onClick={(e) => {
                          e.preventDefault()
                          dispatch(saveBeneficiarsFilter({ companyTraining: "ALL" }))
                        }}
                        active={companyTraining === "ALL" ? true : false}
                      >
                        HAMISI
                      </Button>
                    </div>
                    <div className="m-1">
                      <Button
                        name="LİQ"
                        className="btn"
                        color="primary"
                        outline={companyTraining !== "LİQ" ? true : false}
                        onClick={(e) => {
                          e.preventDefault()
                          dispatch(saveBeneficiarsFilter({ companyTraining: companyTraining !== "LİQ" ? "LİQ" : "ALL" }))
                        }}
                        active={companyTraining === "LİQ" ? true : false}
                      >
                        LİQ təlimləri üzrə
                      </Button>
                    </div>
                    <div className="m-1">
                      <Button
                        name="Konsultant"
                        className="btn"
                        color="primary"
                        outline={companyTraining !== "Konsultant" ? true : false}
                        onClick={(e) => {
                          e.preventDefault()
                          dispatch(saveBeneficiarsFilter({ companyTraining: companyTraining !== "Konsultant" ? "Konsultant" : "ALL" }))
                        }}
                        active={companyTraining === "Konsultant" ? true : false}
                      >
                        KONSORSİUM təlimləri üzrə
                      </Button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <BeneficiarFilter isGeneral={true} isForReport={true} onFilterSubmit={onFilterSubmit} />
            </Col>
          </Row>
          <Row>
            <Col>
              <BeneficiarTable beneficiarSetter={setSelectedBeneficiar} followers={followers} />
            </Col>
          </Row>

        </Container>
        <BeneficiarGalleryModal
          toggle={handleViewGallery}
          isOpen={GalleryMode === "view"}
          images={selectedBeneficiarImages}
          isGeneral={false}
        />
      </div>

    </React.Fragment>
  )
}

export default withTranslation()(Reports)
