import { createSelector } from "reselect"
import { REQUEST_QUESTIONS, REQUEST_POST_QUESTION } from "store/questions/actionTypes";

export const selectQuestions = state => state.Questions;
export const selectAnswersTypes = state => state.Questions.answerTypes;
export const selectLoadingQuestions = state => { return state.Requesting[REQUEST_QUESTIONS] };
export const selectLoadingPostQuestion = state => { return state.Requesting[REQUEST_POST_QUESTION] };


export const selectQuestionsState = state => state.Questions;
export const selectQuestionsPostResult = createSelector(selectQuestionsState, (questions) => questions.postResult);
export const selectQuestionsDeleteResult = createSelector(selectQuestionsState, (questions) => questions.deleteResult)

const selectPageState = createSelector(
  selectQuestions,
  selectLoadingQuestions,
  selectLoadingPostQuestion,
  (questions, isLoadingQuestions, isLoadingPostQuestions) => ({
    questions,
    isLoadingQuestions,
    isLoadingPostQuestions
  })
)

export default selectPageState;