// import ToastStatusEnum from '../constants/ToastStatusEnum';
import { toast } from "react-toastify";
// import * as ToastsAction from "../store/toasts/actions";

function notify(message, state, options) {
    toast[state](message, {
        position: toast.POSITION.TOP_RIGHT,
        ...options,
    });
}

export default function toastMiddleware() {
  return (store) => (next) => (action) => {
    const isError = action.error;
    const hasToast = action.meta && action.meta.toast;
    if (isError) {
        notify(action.meta?.toast?.message || action.payload.message, "error");

    } else if (hasToast) {
        const { toast } = action.meta;
        notify(toast?.message || action.payload.message, toast.type);
    }

    next(action);
  };
}
