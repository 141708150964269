import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { REQUEST_BENEFICIAR_IMAGES_IMAGES, REQUEST_DELETE_BENEFICIAR_IMAGE, REQUEST_DELETE_BENEFICIAR_IMAGE_FINISHED, REQUEST_POST_BENEFICIAR_IMAGES, REQUEST_POST_BENEFICIAR_IMAGES_FINISHED } from "./actionTypes";
import { requestBeneficiarImagesFinished, requestDeleteBeneficiarImageFinished, requestPostBeneficiarImagesFinished } from "./actions";
import { deleteBeneficiariarImage, getBeneficiariarImages, postBeneficiarImages } from "services";
import { toast } from "react-toastify";

// const fireBaseBackend = getFirebaseBackend();

function* fetchBeneficiarImages({ error, payload }) {
  if (error) return;
  try {
    const response = yield call(getBeneficiariarImages, payload.BeneficiaryId ? payload.BeneficiaryId : payload);

    if (response.status) {
      const { data } = response;
      const beneficiarImages = data.data
      yield put(requestBeneficiarImagesFinished(beneficiarImages))
      return
    }

    // success false
    yield put(requestBeneficiarImagesFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestBeneficiarImagesFinished(error, true))
  }
}

function* deleteSingleBeneficiarImage({ error, payload }) {
  if (error) return;
  try {
    const response = yield call(deleteBeneficiariarImage, payload);
    const { data } = response
    if (data.success) {
      const formattedResponse = {
        success: response.data.success,
        message: response.data.message,
        BeneficiaryId: payload.beneficiarId,
      }
      yield put(requestDeleteBeneficiarImageFinished(formattedResponse));
      toast.success('Şəkil uğurla silindi', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    toast.error(data.message, {
      position: toast.POSITION.TOP_RIGHT
    });
    yield put(requestDeleteBeneficiarImageFinished(response, true))
  } catch (error) {
    console.error(error)
    yield put(requestDeleteBeneficiarImageFinished(error, true))
  }
}

function* postBeneficiarImagesSaga({ payload }) {
  try {
    const response = yield call(postBeneficiarImages, payload)
    if (response.data.success) {
      const formattedResponse = {
        ...response.data,
        BeneficiaryId: payload?.get("BeneficiarId"),
      }
      yield put(
        requestPostBeneficiarImagesFinished(formattedResponse, false, { toast: { type: "success" }, refresh: true })
      )
      return
    }

    yield put(requestPostBeneficiarImagesFinished(response.data, true))
  } catch (error) {
    console.error(error)
    yield put(requestPostBeneficiarImagesFinished(error, true))
  }
}


function* BeneficiarImagesSaga() {
  yield takeEvery([
    REQUEST_BENEFICIAR_IMAGES_IMAGES,
    REQUEST_DELETE_BENEFICIAR_IMAGE_FINISHED,
    REQUEST_POST_BENEFICIAR_IMAGES_FINISHED
  ], fetchBeneficiarImages);
  yield takeEvery([REQUEST_DELETE_BENEFICIAR_IMAGE], deleteSingleBeneficiarImage);
  yield takeEvery([REQUEST_POST_BENEFICIAR_IMAGES], postBeneficiarImagesSaga)
}


export default BeneficiarImagesSaga;
