import {
  REQUEST_QUESTIONS_FINISHED,
  REQUEST_ANSWERTYPES_FINISHED,
  REQUEST_POST_QUESTION_FINISHED,
  REQUEST_POST_QUESTION,
  REQUEST_DELETE_QUESTION,
  REQUEST_DELETE_QUESTION_FINISHED
} from "./actionTypes"

const initialState = {
  list: [],
  answerTypes: []
}

const Questions = (state = initialState, { type, payload, error, meta }) => {
  if (error) return state

  switch (type) {
    case REQUEST_QUESTIONS_FINISHED:
      return { ...state, list: payload.list, count: payload.count }
    case REQUEST_POST_QUESTION:
      return { ...state, postResult: null }
    case REQUEST_POST_QUESTION_FINISHED:
      return { ...state, postResult: payload }
    case REQUEST_DELETE_QUESTION:
      return { ...state, postResult: null }
    case REQUEST_DELETE_QUESTION_FINISHED:
      return { ...state, deleteResult: payload }
    case REQUEST_ANSWERTYPES_FINISHED:
      return { ...state, answerTypes: payload }
    default:
      return state
  }
}

export default Questions
